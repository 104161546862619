export let rewards = {
    sp: {
        answered: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000, 50000, 100000],
        },
        answered_correctly: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000, 50000, 100000],
        },
        G_answered: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        G_answered_correctly: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        H_answered: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        H_answered_correctly: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        E_answered: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        E_answered_correctly: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        A_answered: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        A_answered_correctly: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        S_answered: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        S_answered_correctly: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        SP_answered: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        SP_answered_correctly: {
            values: [10, 50, 100, 200, 500, 1000, 5000, 10000],
        },
        points: {
            values: [50, 100, 250, 500, 1000, 2000, 3000, 5000, 8000, 100000, 15000, 20000, 25000, 30000, 40000, 50000, 65000, 80000, 100000, 125000, 150000, 200000, 250000, 300000],
        },
    },
    mp: {
        total_games_completed: {
            values: [1, 2, 3, 5, 10, 20, 30, 40, 50]
        }
    }
}
