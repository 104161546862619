export const c = {
    CAT_GEOG: "G",
    CAT_ENT: "E",
    CAT_HIST: "H",
    CAT_ART: "A",
    CAT_SCI: "S",
    CAT_SPO: "SP",
    DIFFICULTY_EASY: "E",
    DIFFICULTY_MEDIUM: "M",
    DIFFICULTY_HARD: "H",
}