
const {c} = require('./consts');

export let art_questions = [
     
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "How many Harry Potter books are there?",
            a1: "8",
            a2: "7",
            a3: "5",
            a4: "6",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who was the author of the 1954 novel, 'Lord of the Flies'?",
            a1: "F. Scott Fitzgerald",
            a2: "Hunter Fox",
            a3: "William Golding",
            a4: "Stephen King",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the three headed dog in Harry Potter and the Sorcerer's Stone?",
            a1: "Fluffy",
            a2: "Spot",
            a3: "Poofy",
            a4: "Spike",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote 'A Tale of Two Cities'?",
            a1: "Roald Dahl",
            a2: "Charles Darwin",
            a3: "Mark Twain",
            a4: "Charles Dickens",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote 'Harry Potter'?",
            a1: "J.K. Rowling",
            a2: "Daniel Radcliffe",
            a3: "J.R.R. Tolkien",
            a4: "Terry Pratchett",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What's Harry Potter's dad's name?",
            a1: "Frank Potter",
            a2: "James Potter",
            a3: "Hairy Potter Sr",
            a4: "Joey Potter",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "The Harry Potter series of books, combined, are over 1,000,000 words in length?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which is NOT a book in the Harry Potter Series?",
            a1: "The Prisoner of Azkaban",
            a2: "The Chamber of Secrets",
            a3: "The House Elf",
            a4: "The Deathly Hallows",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who is Batman?",
            a1: "Bruce Wayne",
            a2: "Barry Allen",
            a3: "Tony Stark",
            a4: "Clark Kent",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who is the creator of the comic series 'The Walking Dead'?",
            a1: "Stan Lee",
            a2: "Malcolm Wheeler-Nicholson",
            a3: "Robert Kirkman",
            a4: "Robert Crumb",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the alter-ego of the DC comics character 'Superman'?",
            a1: "Arthur Curry",
            a2: "Clark Kent",
            a3: "Bruce Wayne",
            a4: "John Jones",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Galileo was the citizen of which country?",
            a1: "Portugal",
            a2: "Greece",
            a3: "Italy",
            a4: "Spain",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which is the main substance used to make a crayon?",
            a1: "Rubber",
            a2: "Lead",
            a3: "Cheese",
            a4: "Wax",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which period has a French name that translates to 'rebirth' in English?",
            a1: "Prehistoric",
            a2: "The Renaissance",
            a3: "Middle Ages",
            a4: "The Enlightenment",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who painted the 'Girl Before a Mirror' and 'The Weeping Woman'?",
            a1: "Claude Monet",
            a2: "Pablo Picasso",
            a3: "Edvard Munch",
            a4: "Gustav Klimt",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which artist is most famous for their close-up perspective paintings of flowers?",
            a1: "Claude Monet",
            a2: "Henri Matisse",
            a3: "Georgia O'Keeffe",
            a4: "Leonardo da Vinci",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which artist died poor but became incredibly rich after his death?",
            a1: "Leonardo da Vinci",
            a2: "Claude Monet",
            a3: "Vincent Van Gogh",
            a4: "Henri Matisse",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which famous abstract artist was blamed for stealing the 'Mona Lisa'?",
            a1: "Pablo Picasso",
            a2: "Vincent van Gogh",
            a3: "Andy Warhol",
            a4: "Leonardo da Vinci",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "This famous painting by Leonardo Da Vinci was used for target practice by Napoleon's soldiers?",
            a1: "Lady with an Ermine",
            a2: "The Last Supper",
            a3: "Salvator Mundi",
            a4: "Virgin of the Rocks",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which artist is famous for Pop Art?",
            a1: "Claude Monet",
            a2: "Joan Miró",
            a3: "Johannes Vermeer",
            a4: "Andy Warhol",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is Pablo Picasso's style of artwork known as?",
            a1: "Surrealism",
            a2: "Abstract",
            a3: "Classical",
            a4: "Neoclassical",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What are the three primary colors?",
            a1: "Red, green, and blue",
            a2: "Blue, green and yellow",
            a3: "Red, yellow, and blue",
            a4: "Red, yellow, and green",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What do you call a combination of magazine cut-outs, pictures, cloth, and other objects?",
            a1: "A collage",
            a2: "An abstraction",
            a3: "A mixout",
            a4: "A collection",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In which country is the famous Sphinx statue located?",
            a1: "Egypt",
            a2: "Algeria",
            a3: "Libya",
            a4: "Sudan",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which famous painter cut off a part of his ear?",
            a1: "Claude Monet",
            a2: "Jackson Pollock",
            a3: "Georgia O'Keeffe",
            a4: "Vincent Van Gogh",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the true art term for colour?",
            a1: "Hue",
            a2: "Shade",
            a3: "Tone",
            a4: "Saturation",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Black and white are not colors, but…?",
            a1: "Tones",
            a2: "Shades",
            a3: "Hues",
            a4: "Saturations",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What do you call a point with no end?",
            a1: "A line",
            a2: "A flux",
            a3: "A cirlce",
            a4: "A bow",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "The Art Deco movement focuses specifically on which element of art?",
            a1: "Abstraction",
            a2: "Reality",
            a3: "Divinity",
            a4: "Decorative",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who painted the ceiling of the Sistine Chapel?",
            a1: "Vincent van Gogh",
            a2: "Michelangelo",
            a3: "Claude Monet",
            a4: "Leonardo da Vinci",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In which city can you find the British Museum?",
            a1: "London",
            a2: "Birmingham",
            a3: "Leeds",
            a4: "Manchester",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which country is Claude Monet from?",
            a1: "Netherlands",
            a2: "Spain",
            a3: "France",
            a4: "Brussels",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which famous street artist is from Bristol?",
            a1: "Swoon",
            a2: "Banksy",
            a3: "JR",
            a4: "Shepard Fairey",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Surrealism started just after WWI?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "During which century did Rembrandt live?",
            a1: "15th",
            a2: "17th",
            a3: "16th",
            a4: "18th",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which author wrote the 'Winnie-the-Pooh' books?",
            a1: "Roald Dahl",
            a2: "A. A. Milne",
            a3: "Jacqueline Wilson",
            a4: "Beverly Cleary",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of Shakespeare's plays is the longest?",
            a1: "A Midsummer Night's Dream",
            a2: "Julius Caesar",
            a3: "Hamlet",
            a4: "Tempest",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the most common letter in the English alphabet?",
            a1: "I",
            a2: "A",
            a3: "O",
            a4: "E",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "A book by Barack Obama is titled 'The Audacity of' what?",
            a1: "Love",
            a2: "Peace",
            a3: "Courage",
            a4: "Hope",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who is the writer of “Merchant of Venice”?",
            a1: "J. D. Salinger",
            a2: "Oscar Wilde",
            a3: "William Shakespeare",
            a4: "John Steinbeck",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the fourth book in the Harry Potter series?",
            a1: "Harry Potter and the Prisoner of Azkaban",
            a2: "Harry Potter and the Deathly Hallows",
            a3: "Harry Potter and the Cursed Child",
            a4: "Harry Potter and the Goblet of Fire",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "The book 'Da Vinci Code' was written by whom?",
            a1: "Leo Tolstoy",
            a2: "J. K. Rowling",
            a3: "Lewis Carroll",
            a4: "Dan Brown",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which original Avenger was not in the first few movies?",
            a1: "Hulk",
            a2: "The Wasp",
            a3: "Iron Man",
            a4: "Loki",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What was Superman's birth name?",
            a1: "Kal-El",
            a2: "Beat-El",
            a3: "Leat-El",
            a4: "Fal-El",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of Batman's butler?",
            a1: "Theodore",
            a2: "Alfred",
            a3: "Henry",
            a4: "George",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Aquaman is from which city under the sea?",
            a1: "Dwarka",
            a2: "Atlantis",
            a3: "Alexandria",
            a4: "Pavlopetri",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What does DC stand for?",
            a1: "Dark Company",
            a2: "Death Company",
            a3: "Detectice Comics",
            a4: "Dry Cell",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who killed Macbeth?",
            a1: "King duncan",
            a2: "Macduff",
            a3: "Hamlet",
            a4: "Othello",
            a: 2,
            seen: false
        },
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is a primary color?",
            an: "A color that can't be made by mixing other colors together",
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "English artist Andy Brown created a portrait of Queen Elizabeth II using what?",
            a1: "Gum",
            a2: "Bones",
            a3: "Tea Bags",
            a4: "Socks",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "How many paintings did Vincent Van Gogh sell during his lifetime?",
            a1: "1000",
            a2: "1",
            a3: "100",
            a4: "10",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In the art style 'Op Art', what does 'Op' stand for?",
            a1: "Operation",
            a2: "Opal",
            a3: "Opaque",
            a4: "Optical",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the second Harry Potter book called?",
            a1: "The Philosopher's Stone",
            a2: "Order of the Phoenix",
            a3: "The Goblet of Fire",
            a4: "The Chamber of Secrets",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In The Hobbit, what sort of creature is Smaug?",
            a1: "Elf",
            a2: "Unicorn",
            a3: "Dragon",
            a4: "Yeti",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which Dickens character says “Please, Sir, I want some more”?",
            a1: "Tiny Tim",
            a2: "Oliver Twist",
            a3: "Bill Sikes",
            a4: "Artful Dodger",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which author created Tom Sawyer and Huckleberry Finn?",
            a1: "Mark Twain",
            a2: "Jack London",
            a3: "Charles Dickens",
            a4: "Douglas Adams",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In which 18th century novel does the protagonist eventually marry Mr. Rochester?",
            a1: "Jane Eyre",
            a2: "A Little Princess",
            a3: "Agnes Grey",
            a4: "Northanger Abbey",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which prolific US author wrote It, The Shining and Misery?",
            a1: "Virginia Woolf",
            a2: "Leo Tolstoy",
            a3: "Stephen King",
            a4: "Jane Austen",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Piscine Molitor Patel and Richard Parker are the main characters in which novel?",
            a1: "Invisible Man",
            a2: "Jane Eyre",
            a3: "Life of Pi",
            a4: "Middlemarch",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who is Harry Potter's best female friend?",
            a1: "Charlotte",
            a2: "Isabelle",
            a3: "Hermione",
            a4: "Olivia",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which author wrote about a boy named Charlie visiting a chocolate factory after winning a golden ticket?",
            a1: "J. R. R. Tolkien",
            a2: "Charles Dickens",
            a3: "J. K. Rowling",
            a4: "Roald Dahl",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which detective series focuses on two brothers, Frank and Joe, solving mysteries?",
            a1: "The Steel Boys",
            a2: "The Rubber Boys",
            a3: "The Marching Boys",
            a4: "The Hardy Boys",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What was the name of the Darling's daughter in the Peter Pan?",
            a1: "Violet",
            a2: "Lottie",
            a3: "Wendy",
            a4: "Ida",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of Tom Sawyer's best friend in The Adventures of Tom Sawyer?",
            a1: "Huckleberry Finn",
            a2: "Joe Harper",
            a3: "Aunt Polly",
            a4: "Becky Thatcher",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote Charlotte's Web, The Trumpet of the Swan, and Stuart Little?",
            a1: "Jane Austen",
            a2: "Virginia Woolf",
            a3: "E. B. White",
            a4: "Roald Dahl",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote Matilda, The Witches, and The BFG, all of which were made into movies?",
            a1: "Oscar Wilde",
            a2: "Roald Dahl",
            a3: "Ernest Hemingway",
            a4: "Mark Twain",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the Chronicles of Narnia series?",
            a1: "Dan Brown",
            a2: "E. B. White",
            a3: "C. S. Lewis",
            a4: "Herman Melville",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What children's novel features the characters Tweedledee and Tweedledum?",
            a1: "Alice Through the Looking Glass",
            a2: "Little Women",
            a3: "The Phantom Tollbooth",
            a4: "Marilda",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Moby Dick?",
            a1: "Margaret Leech",
            a2: "Randy Shilts",
            a3: "Herman Melville",
            a4: "David Sedaris",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Hamlet?",
            a1: "George R. R. Martin",
            a2: "Taylor Branch",
            a3: "William Shakespeare",
            a4: "Lynne Truss",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Wuthering Heights?",
            a1: "Emily Brontë",
            a2: "Chuck Palahniuk",
            a3: "E. B. White",
            a4: "Randy Shilts",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book The Catcher in the Rye?",
            a1: "E. B. White",
            a2: "Studs Terkel",
            a3: "J. D. Salinger",
            a4: "Zora Neale Hurston",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Pride and Prejudice?",
            a1: "Lynne Truss",
            a2: "Taylor Branch",
            a3: "Margaret Mead",
            a4: "Maggie Nelson",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book  Pride and Prejudice?",
            a1: "Studs Terkel",
            a2: "Jane Austen",
            a3: "David Sedaris",
            a4: "Margaret Mead",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Great Expectations?",
            a1: "Maggie Nelson",
            a2: "Charles Dickens",
            a3: "Jared Diamond",
            a4: "E. B. White",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book To Kill a Mockingbird?",
            a1: "Carlo Levi",
            a2: "Lynne Truss",
            a3: "Taylor Branch",
            a4: "Harper Lee",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book David Copperfield?",
            a1: "Cornelius Tacitus",
            a2: "Richard Hofstadter",
            a3: "Charles Dickens",
            a4: "David Sedaris",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Les Misérables?",
            a1: "George R. R. Martin",
            a2: "Isaiah Berlin",
            a3: "Rainer Maria Rilke",
            a4: "Victor Hugo",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Frankenstein?",
            a1: "Mary Shelley",
            a2: "Jared Diamond",
            a3: "Alvin Toffler",
            a4: "Emily Brontë",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book The Old Man and the Sea?",
            a1: "Dale Carnegie",
            a2: "Carlo Levi",
            a3: "Ernest Hemingway",
            a4: "John Locke",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book The Great Gatsby?",
            a1: "Martin Heidegger",
            a2: "F. Scott Fitzgerald",
            a3: "Richard Hofstadter",
            a4: "Dale Carnegie",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Brave New World?",
            a1: "Cornelius Tacitus",
            a2: "Aldous Huxley",
            a3: "James Baldwin",
            a4: "Russell Kirk",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Dracula?",
            a1: "Bram Stoker",
            a2: "Issac Newton",
            a3: "Carlo Levi",
            a4: "Adam Smith",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Vanity Fair?",
            a1: "Herman Melville",
            a2: "William Makepeace Thackeray",
            a3: "Dale Carnegie",
            a4: "Thomas Harri",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book The Waves?",
            a1: "Virginia Woolf",
            a2: "John Locke",
            a3: "Chuck Palahniuk",
            a4: "Jared Diamond",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Heart of Darkness?",
            a1: "Joseph Conrad",
            a2: "Ted Hughes",
            a3: "Martin Heidegger",
            a4: "Alvin Toffler",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Brick Lane?",
            a1: "Monica Ali",
            a2: "Isaiah Berlin",
            a3: "Richard Hofstadter",
            a4: "John Locke",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book A Room With a View?",
            a1: "Joseph Conrad",
            a2: "James Baldwin",
            a3: "E. M. Forster",
            a4: "Issac Newton",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Never Let Me Go?",
            a1: "Eugene Genovese",
            a2: "Dale Carnegie",
            a3: "Kazuo Ishiguro",
            a4: "Herman Melville",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Gulliver's Travels?",
            a1: "Jonathan Swift",
            a2: "Sigmund Freud",
            a3: "Thomas Harri",
            a4: "Ted Hughes",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Animal Farm?",
            a1: "Russell Kirk",
            a2: "Michael Pollan",
            a3: "Oliver Sacks",
            a4: "George Orwell",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book A Clockwork Orange?",
            a1: "Dale Carnegie",
            a2: "Issac Newton",
            a3: "Adam Smith",
            a4: "Anthony Burgess",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book The Diary of a Young Girl?",
            a1: "Jean Paul Sartre",
            a2: "Emily Brontë",
            a3: "Anne Frank",
            a4: "Dale Carnegie",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book On the Origin of Species?",
            a1: "Charles Darwin",
            a2: "Jean Paul Sartre",
            a3: "Oliver Sacks",
            a4: "Nicolaus Copernicus",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Relativity?",
            a1: "Albert Einstein",
            a2: "Stephen Hawking",
            a3: "Nicolaus Copernicus",
            a4: "Barack Obama",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book The Gulag Archipelago?",
            a1: "Eugene Genovese",
            a2: "Aleksandr Solzhenitsyn",
            a3: "Friedrich Nietzsche",
            a4: "Herman Melville",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book The Art of War?",
            a1: "C. S. Lewis",
            a2: "Frank McCourt",
            a3: "Sun Zi",
            a4: "Cornelius Tacitus",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Discourse on Method?",
            a1: "Will James",
            a2: "Rene Descartes",
            a3: "Lionel Trilling",
            a4: "Douglas Southall Freeman",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book A Brief History of Time?",
            a1: "Michael Pollan",
            a2: "Richard Dawkins",
            a3: "Stephen Hawking",
            a4: "Sigmund Freud",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Beyond Good and Evil?",
            a1: "Plato",
            a2: "Richard Dawkins",
            a3: "Aleksandr Solzhenitsyn",
            a4: "Friedrich Nietzsche",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Life on the Mississippi?",
            a1: "Mark Twain",
            a2: "Thomas Harri",
            a3: "Barack Obama",
            a4: "Chuck Palahniuk",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book The Silence of the Lambs?",
            a1: "Thomas Harris",
            a2: "Russell Kirk",
            a3: "Dee Alexander Brown",
            a4: "Emily Brontë",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the A Game of Thrones series?",
            a1: "Russell Kirk",
            a2: "Eugene Genovese",
            a3: "Joseph Conrad",
            a4: "George R. R. Martin",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the book Mein Kampf?",
            a1: "George Orwell",
            a2: "Adolf Hitler",
            a3: "David Sedaris",
            a4: "Martin Heidegger",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What was the name of Sherlock Holmes arch enemy?",
            a1: "Professor Mateo",
            a2: "Professor Brady",
            a3: "Professor Troy",
            a4: "Professor Moriarty",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the villain who commands a gang of boy pickpockets in Charles Dicken's 'Oliver Twist'?",
            a1: "Mr. Bumble",
            a2: "Nancy",
            a3: "Grimwig",
            a4: "Monks",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What was the surname of the family who took in Paddington Bear?",
            a1: "Black",
            a2: "Blue",
            a3: "White",
            a4: "Brown",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the dead court jester in Shakespeare's 'Hamlet'?",
            a1: "Claudius",
            a2: "Yorick",
            a3: "Ophelia",
            a4: "Gertrude",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "How many lines does a Haiku poem have?",
            a1: "Four",
            a2: "Six",
            a3: "Three",
            a4: "Five",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which leading English poet of World War One was killed in action one week before the end of the war?",
            a1: "John Keats",
            a2: "John Milton",
            a3: "William Wordsworth",
            a4: "Wilfred Owen",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Holden Caulfield is the antihero of which 1945 classic book?",
            a1: "To Kill A Mockingbird",
            a2: "Catcher in the Rye",
            a3: "Lord Of The Flies",
            a4: "Of Mice and Men",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In William Shakespeare's 'A Midsummer Night's Dream', what is the name of the King of the Fairies?",
            a1: "Priam",
            a2: "Marcus Antonius,",
            a3: "Saturninus",
            a4: "Oberon",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which former Prime Minister of Great Britain was awarded the Nobel Prize in Literature in 1953?",
            a1: "Neville Chamberlain",
            a2: "Winston Churchill",
            a3: "Margaret Thatcher",
            a4: "Stanley Baldwin",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What was Mark Twain's sequel to 'The Adventures of Tom Sawyer'?",
            a1: "The City of Ember",
            a2: "The Adventures of Huckleberry Finn",
            a3: "The Mysterious Stranger",
            a4: "A Walk in the Woods",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Snowball and Napoleon are pigs who appear in which George Orwell novel?",
            a1: "Animal Farm",
            a2: "Burmese Days",
            a3: "1984",
            a4: "Down and Out in Paris",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In 'The Merchant of Venice', what is the name of the merchant?",
            a1: "Leontes",
            a2: "Henry",
            a3: "Lear",
            a4: "Antonio",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the mischievous jester in 'A Midsummer Night's Dream', who is also known as Robin Goodfellow?",
            a1: "Richard",
            a2: "Puck",
            a3: "Cymbeline",
            a4: "Gear",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Complete this opening line from Shakespeare's Sonnet 18: 'Shall I compare thee to ...'?",
            a1: "A winter's night",
            a2: "A spring break",
            a3: "A summer's day",
            a4: "An autumn fall",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What are the surnames of the title characters in “Romeo and Juliet”?",
            a1: "Falstall and Caliban",
            a2: "Montague and Capulet",
            a3: "Shylock and Iago",
            a4: "Macbeth and Duncan",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of Shakespeare's plays is the last that he wrote alone?",
            a1: "As you Like It",
            a2: "The Tempest",
            a3: "Julius Caesar",
            a4: "The Merchant of Venice",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In “King Lear”, what are the names of Lear’s three daughters?",
            a1: "xxxxxxxxxxxxxxxxxxxxxxx",
            a2: "Goneril, Regan and Cordelia",
            a3: "xxxxxxxxxxxxxxxxxxxxxxx",
            a4: "xxxxxxxxxxxxxxxxxxxxxxx",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which Shakespeare character speaks the famous line “A horse, a horse, my kingdom for a horse”?",
            a1: "Richard III",
            a2: "Bassania",
            a3: "Puck",
            a4: "Hermia",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "'If music be the food of love, play on' is the opening line of which play?",
            a1: "Twelfth Night",
            a2: "King Lear",
            a3: "The Merchant of Venice",
            a4: "Henry VIII",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of Shakespeare's plays contains the most words?",
            a1: "Hamlet",
            a2: "Romeo and Juliet",
            a3: "The Comedy of Errors",
            a4: "Cymbeline",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "The drunken peddlar Christopher Sly appears in which Shakespeare play?",
            a1: "Henry VIII",
            a2: "Much Ado About Nothing",
            a3: "The Taming of the Shrew",
            a4: "Pericles, Prince of Tyre",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "According to the famous line in “Romeo and Juliet”, what by any other name would smell as sweet?",
            a1: "xxxxxxxxxxxxxxxxxxxxxxx",
            a2: "xxxxxxxxxxxxxxxxxxxxxxx",
            a3: "xxxxxxxxxxxxxxxxxxxxxxx",
            a4: "A rose",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In which famous London church would you find 'Poet's Corner'?",
            a1: "Southwark Cathedral",
            a2: "Holy Trinity",
            a3: "St. Paul's Cathedral",
            a4: "Westminster Abbey",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote the Greek epic poem 'Odyssey'?",
            a1: "Homer",
            a2: "Bart",
            a3: "Lisa",
            a4: "Marge",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "How many brothers does Harry Potter's close friend, Ron Weasley have?",
            a1: "Three",
            a2: "One",
            a3: "Five",
            a4: "None",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Who is the Hogwarts professor that can transform themselves into a tabby cat?",
            a1: "Horace Slughorn",
            a2: "Filius Flitwick",
            a3: "Minerva McGonagall",
            a4: "Sybill Trelawney",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "On what street do Petunia and Vernon Dursley live?",
            a1: "Mildred Close",
            a2: "Privet Drive",
            a3: "Mulberry Avenue",
            a4: "Keepers Lea",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "When Harry plays the game of quidditch for the Gryffindor house team, what position is he selected to play?",
            a1: "Seeker",
            a2: "Keeper",
            a3: "Beater",
            a4: "Chaser",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What is the number of staircases that exist within the Hogwarts school of wizardry?",
            a1: "442",
            a2: "42",
            a3: "242",
            a4: "342",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In the Harry Potter series, what type of animal was Neville Longbottom's pet named Trevor?",
            a1: "Cat",
            a2: "Mouse",
            a3: "Owl",
            a4: "Toad",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In the Harry Potter series, how many players are on a quidditch team?",
            a1: "Eight",
            a2: "Five",
            a3: "Six",
            a4: "Seven",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In the Harry Potter series, which of these is not a Hogwarts house?",
            a1: "Slytherin",
            a2: "Gryffindor",
            a3: "Badgeren",
            a4: "Ravenclaw",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "What language does Harry Potter use to talk to snakes?",
            a1: "Parseltongue",
            a2: "Bordertongue",
            a3: "Siritongue",
            a4: "Chieftongue",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In The Lord of the Rings, what is the name of the mountain where the Master Ring was made?",
            a1: "Mount Hell",
            a2: "Mount Doom",
            a3: "Mount Larva",
            a4: "Mount Fire",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "In The Lord of the Rings, What is the title of the second book?",
            a1: "The Two Towers",
            a2: "The Four Towers",
            a3: "The Twin Towers",
            a4: "The Octal Towers",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of these books was not wrtten by Stephen King?",
            a1: "Money",
            a2: "It",
            a3: "Misery",
            a4: "Doctor Sleep",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of these books was wrtten by Charles Dickens?",
            a1: "Oliver Twist",
            a2: "Under the Volcano",
            a3: "The Big Sleep",
            a4: "Vanity Fair",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of these books was wrtten by Mark Twain?",
            a1: "Adventures of Huckleberry Finn",
            a2: "A Clockwork Orange",
            a3: "Mrs Dalloway",
            a4: "Atonement",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of these books was not wrtten by Virginia Woolf?",
            a1: "Mrs Dalloway",
            a2: "The Waves",
            a3: "Nostromo",
            a4: "Orlando",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of these books was wrtten by Jane Austen?",
            a1: "Lord of the Flies",
            a2: "Persuasion",
            a3: "Invisible Man",
            a4: "Wuthering Heights",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of these books was not wrtten by Roald Dahl?",
            a1: "The BFG",
            a2: "The Call of the Wild",
            a3: "Fantastic Mr. Fox",
            a4: "Matilda",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of these books was not wrtten by Ernest Hemingway?",
            a1: "For Whom the Bell Tolls",
            a2: "A Moveable Feast",
            a3: "The Sun Also Rises",
            a4: "Lord of the Flies",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of these books was not wrtten by William Shakespeare?",
            a1: "Hamlet",
            a2: "Beloved",
            a3: "King Lear",
            a4: "Othello",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which of these books was not wrtten by Leo Tolstoy?",
            a1: "War and Peace",
            a2: "Resurrection",
            a3: "Anna Karenina",
            a4: "Invisible Man",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "The Cheshire Cat is a fictional cat in which book?",
            a1: "Alice's Adventures in Wonderland",
            a2: "The Magic Finger",
            a3: "The Twits",
            a4: "Charlie and the Chocolate Factory",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_EASY,
            q: "Which famous Victorian author wrote 'A Christmas Carol'?",
            a1: "Virginia Woolf",
            a2: "Mark Twain",
            a3: "Charles Dickens",
            a4: "Roald Dahl",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who wrote the 1967 horror novel 'Rosemary's Baby'?",
            a1: "Stephen King",
            a2: "Ira Levin",
            a3: "Robert Bloch",
            a4: "Mary Shelley",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "'Green Eggs And Ham' is a book by which author?",
            a1: "Dr. Seuss",
            a2: "Roald Dahl",
            a3: "A.A. Milne",
            a4: "Beatrix Potter",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the name of the Mysterious Island, in Jules Verne's 'The Mysterious Island'?",
            a1: "Neptune Island",
            a2: "Prometheus Island",
            a3: "Vulcania Island",
            a4: "Lincoln Island",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who wrote the children's story 'The Little Match Girl'?",
            a1: "Charles Dickens",
            a2: "Hans Christian Andersen",
            a3: "Lewis Carroll",
            a4: "Oscar Wilde",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "By what name was the author Eric Blair better known?",
            a1: "Aldous Huxley",
            a2: "Ray Bradbury",
            a3: "Ernest Hemingway",
            a4: "George Orwell",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "The book 1984 was published in 1949?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Under what pseudonym did Stephen King publish five novels between 1977 and 1984?",
            a1: "Mark Twain",
            a2: "Lewis Carroll",
            a3: "Richard Bachman",
            a4: "J. D. Robb",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What book series published by Jim Butcher follows a wizard in modern day Chicago?",
            a1: "The Cinder Spires",
            a2: "The Dresden Files",
            a3: "A Hat in Time",
            a4: "My Life as a Teenage Wizard",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of Eragon's dragon in 'Eragon'?",
            a1: "Thorn",
            a2: "Arya",
            a3: "Glaedr",
            a4: "Saphira",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following is not a work authored by Fyodor Dostoevsky?",
            a1: "Crime and Punishment",
            a2: "Anna Karenina",
            a3: "Notes from the Underground",
            a4: "The Brothers Karamazov",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which Russian author wrote the epic novel War and Peace?",
            a1: "Vladimir Nabokov",
            a2: "Leo Tolstoy",
            a3: "Alexander Pushkin",
            a4: "Fyodor Dostoyevsky",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the fourth book of the Old Testament?",
            a1: "Leviticus",
            a2: "Genesis",
            a3: "Numbers",
            a4: "Exodus",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "The 'Berenstein Bears' is the correct spelling of the educational children's book series' name?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "J.K. Rowling completed 'Harry Potter and the Deathly Hallows' in which hotel in Edinburgh, Scotland?",
            a1: "The Balmoral",
            a2: "The Dunstane Hotel",
            a3: "Sheraton Grand Hotel & Spa",
            a4: "Hotel Novotel",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In the 'Harry Potter' novels, what must a Hogwarts student do to enter the Ravenclaw Common Room?",
            a1: "Speak a password",
            a2: "Answer a riddle",
            a3: "Knock in sequence",
            a4: "Rhythmically tap barrels with a wand",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In 'Little Women', which of the March sisters married Laurie?",
            a1: "Amy",
            a2: "Jo",
            a3: "Meg",
            a4: "Beth",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In Alice in Wonderland, what is the name of Alice's kitten?",
            a1: "Heath",
            a2: "Oscar",
            a3: "Dinah",
            a4: "Smokey",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What position does Harry Potter play in Quidditch?",
            a1: "Keeper",
            a2: "Seeker",
            a3: "Chaser",
            a4: "Beater",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of these book series is by James Patterson?",
            a1: "The Legend of Xanth",
            a2: "Harry Potter",
            a3: "Maximum Ride",
            a4: "The Bartemaeus Trilogy",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In The Lies of Locke Lamora, what title is Locke known by in the criminal world?",
            a1: "The Thorn of Emberlain",
            a2: "The Thorn of the Marrows",
            a3: "The Rose of the Marrows",
            a4: "The Thorn of Camorr",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In the 'The Hobbit', who kills Smaug?",
            a1: "Gandalf the Grey",
            a2: "Bilbo Baggins",
            a3: "Bard",
            a4: "Frodo",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In Michael Crichton's novel 'Jurassic Park', John Hammond meets his demise at the claws of which dinosaur?",
            a1: "Dilophosaurus",
            a2: "Velociraptor",
            a3: "Tyrannosaurus Rex",
            a4: "Procompsognathus",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the make and model of the tour vehicles in 'Jurassic Park' (1990)?",
            a1: "1989 Jeep Wrangler YJ Sahar",
            a2: "1989 Ford Explorer XLT",
            a3: "1989 Toyota Land Cruiser",
            a4: "Mercedes M-Class",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Originally, the character Charlie from Charlie and the Chocolate Factory was going to be black?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following authors was not born in England?",
            a1: "Graham Greene",
            a2: "Arthur C Clarke",
            a3: "Arthur Conan Doyle",
            a4: "H G Wells",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "By what nickname is Jack Dawkins known in the Charles Dickens novel, 'Oliver Twist'?",
            a1: "Mr. Fang",
            a2: "The Artful Dodger",
            a3: "Bull’s-eye",
            a4: "Fagin",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which American author was also a budding travel writer and wrote of his adventures with his dog Charley?",
            a1: "John Steinbeck",
            a2: "William Faulkner",
            a3: "Ernest Hemingway",
            a4: "F. Scott Fitzgerald",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of Sherlock Holmes's brother?",
            a1: "Mederi Holmes",
            a2: "Herbie Hancock Holmes",
            a3: "Martin Holmes",
            a4: "Mycroft Holmes",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "How many books are in the Chronicles of Narnia series?",
            a1: "7",
            a2: "5",
            a3: "8",
            a4: "6",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the pen name of novelist, Mary Ann Evans?",
            a1: "George Eliot",
            a2: "George Orwell",
            a3: "George Saunders",
            a4: "George Bernard Shaw",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In Terry Pratchett's Discworld novel 'Wyrd Sisters', which of these are not one of the three main witches?",
            a1: "Magrat Garlick",
            a2: "Nanny Ogg",
            a3: "Granny Weatherwax",
            a4: "Winny Hathersham",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In the novel 'Lord of the Rings', how many rings of power were given to the race of man?",
            a1: "5",
            a2: "11",
            a3: "9",
            a4: "13",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "The book 'Fahrenheit 451' was written by whom?",
            a1: "Wolfgang Amadeus Mozart",
            a2: "Stephen King",
            a3: "Ray Bradbury",
            a4: "R. L. Stine",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "George Orwell wrote this book, which is often considered a statement on government oversight?",
            a1: "To Kill a Mockingbird",
            a2: "The Old Man and the Sea",
            a3: "Catcher and the Rye",
            a4: "1984",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which famous book is sub-titled 'The Modern Prometheus'?",
            a1: "Frankenstein",
            a2: "The Legend of Sleepy Hollow",
            a3: "Dracula",
            a4: "The Strange Case of Dr. Jekyll and Mr. Hyde",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In the Lord of the Rings, who is the father of the dwarf Gimli?",
            a1: "Thorin Oakenshield",
            a2: "Bombur",
            a3: "Dwalin",
            a4: "Gloin",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "The novel 'Of Mice And Men' was written by what author?",
            a1: "John Steinbeck",
            a2: "Mark Twain",
            a3: "George Orwell",
            a4: "Harper Lee",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In the year 1818, novelist Mary Shelly is credited with writing a fiction novel and creating this infamous character?",
            a1: "Dracula",
            a2: "The Thing",
            a3: "The Invisible Man",
            a4: "Frankenstein's monster",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "According to The Hitchhiker's Guide to the Galaxy book, the answer to life, the universe and everything else is..?",
            a1: "Chocolate",
            a2: "42",
            a3: "Loving everyone around you",
            a4: "Death",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following was the author of 'Username Evie'?",
            a1: "Zoe Sugg",
            a2: "Joe Weller",
            a3: "Alfie Deyes",
            a4: "Joe Sugg",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which novel by John Grisham was conceived on a road trip to Florida while thinking about stolen books with his wife?",
            a1: "Camino Island",
            a2: "Gray Mountain",
            a3: "Rogue Lawyer",
            a4: "The Litigators",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who is the author of the series 'Malazan Book of the Fallen'?",
            a1: "George R. R. Martin",
            a2: "J. R. R. Tolkien",
            a3: "Ian Cameron Esslemont",
            a4: "Steven Erikson",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who wrote the 'A Song of Ice And Fire' fantasy novel series?",
            a1: "George Lucas",
            a2: "George Eliot",
            a3: "George Orwell",
            a4: "George R. R. Martin",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "The title of Adolf Hitler's autobiography 'Mein Kampf' is what when translated to English?",
            a1: "My Sadness",
            a2: "My Desire",
            a3: "My Hatred",
            a4: "My Struggle",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "The book 'The Little Prince' was written by..?",
            a1: "F. Scott Fitzgerald",
            a2: "Miguel de Cervantes Saavedra",
            a3: "Jane Austen",
            a4: "Antoine de Saint-Exupéry",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the last message of the Dolphins in 'The Hitchhiker's Guide to the Galaxy'?",
            a1: "'So long, and thanks for all the fish.'",
            a2: "'Land of the brave.'",
            a3: "'The answer is 42.'",
            a4: "'Goodbye cruel world!'",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of the gang that Ponyboy is a part of in the book, The Outsiders?",
            a1: "The Outsiders",
            a2: "The Greasers",
            a3: "The Mafia",
            a4: "The Socs",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "Stephen Chbosky wrote the book 'The Perks of Being A Wallflower'?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "In Marvel Comics, Taurus is the founder and leader of which criminal organization?",
            a1: "The Union",
            a2: "Scorpio",
            a3: "Zodiac",
            a4: "Tiger Mafia",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the name of the first Robin in the Batman comics?",
            a1: "Jason Todd",
            a2: "Dick Grayson",
            a3: "Tim Drake",
            a4: "Bruce Wayne",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the Harry Potter universe, what is Cornelius Fudge's middle name?",
            a1: "James",
            a2: "Harold",
            a3: "Christopher",
            a4: "Oswald",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the Magic: The Gathering universe,  the Antiquities, Ice Age, and Alliances expansions take place on which continent?",
            a1: "Terisiare",
            a2: "Aerona",
            a3: "Jamuraa",
            a4: "Shiv",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the novel 1984, written by George Orwell, what is the name of the totalitarian regime that controls Oceania?",
            a1: "INGSOC",
            a2: "Earth Alliance",
            a3: "Neo-Bolshevism",
            a4: "Obliteration of the Self",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Who wrote the young adult novel 'The Fault in Our Stars'?",
            a1: "John Green",
            a2: "Stephen Chbosky",
            a3: "Stephenie Meyer",
            a4: "Suzanne Collins",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "'Elementary, my dear Watson' is a phrase that is never truly said within the Conan Doyle books of Sherlock Holmes?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "The novel 'Jane Eyre' was written by what author?",
            a1: "Jane Austen",
            a2: "Emily Brontë",
            a3: "Charlotte Brontë",
            a4: "Louisa May Alcott",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In Margaret Atwood's 'The Handmaid's Tale', what is Offred's real name is implied to be?",
            a1: "April",
            a2: "May",
            a3: "August",
            a4: "June",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What is Hermione Granger's middle name?",
            a1: "Emma",
            a2: "Jane",
            a3: "Jo",
            a4: "Jean",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Abel Magwitch is a character from which Charles Dickens novel?",
            a1: "Oliver Twist",
            a2: "Nicholas Nickleby",
            a3: "Great Expectations",
            a4: "The Pickwick Papers",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In Romance of the Three Kingdoms, who was not a member of the Peach Garden Oath?",
            a1: "Zhao Yun",
            a2: "Guan Yu",
            a3: "Zhang Fei",
            a4: "Liu Bei",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In The Lies Of Locke Lamora, what does 'Lamora' mean in Throne Therin?",
            a1: "Justice",
            a2: "Chaos",
            a3: "Shadow",
            a4: "Thievery",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Which of these does Charlie NOT read in The Perks of Being a Wallflower?",
            a1: "The Grapes of Wrath",
            a2: "Peter Pan",
            a3: "The Great Gatsby",
            a4: "Hamlet",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Which author co-wrote 'The Communist Manifesto' alongside Karl Marx?",
            a1: "Robert Owen",
            a2: "Paul Lafargue",
            a3: "Friedrich Engels",
            a4: "Alexander Kerensky",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the Beatrix Potter books, what type of animal is Tommy Brock?",
            a1: "Rabbit",
            a2: "Fox",
            a3: "Frog",
            a4: "Badger",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Who wrote the novel 'Moby-Dick'?",
            a1: "William Golding",
            a2: "William Shakespeare",
            a3: "J. R. R. Tolkien",
            a4: "Herman Melville",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What was the first ever entry written for the SCP Foundation collaborative writing project?",
            a1: "SCP-001",
            a2: "SCP-173",
            a3: "SCP-1459",
            a4: "SCP-999",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the book 'The Martian', how long was Mark Watney trapped on Mars (in Sols)?",
            a1: "549 Days",
            a2: "324 Days",
            a3: "765 Days",
            a4: "401 Days",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What is the name of the protagonist of J.D. Salinger's novel Catcher in the Rye?",
            a1: "Holden Caulfield",
            a2: "Fletcher Christian",
            a3: "Randall Flagg",
            a4: "Jay Gatsby",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In which classic novel is there a character named Homer Simpson?",
            a1: "The Day of the Locust",
            a2: "A Separate Peace",
            a3: "Of Mice and Men",
            a4: "Catch-22",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "'Green Eggs and Ham' consists of only 50 different words?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What was the name of Captain Nemo's submarine in '20,000 Leagues Under the Sea'?",
            a1: "The Poseidon",
            a2: "The Atlantis",
            a3: "The Nautilus",
            a4: "The Neptune",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the Harry Potter universe, who does Draco Malfoy end up marrying?",
            a1: "Pansy Parkinson",
            a2: "Hermione Granger",
            a3: "Millicent Bulstrode",
            a4: "Astoria Greengrass",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Where does the book 'The Silence of the Lambs' get its title from?",
            a1: "The villain's favourite meal",
            a2: "The relation it has with killing the innocents",
            a3: "The main character's trauma in childhood",
            a4: "The voice of innocent people being shut by the powerful",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the book series 'Odd Thomas', Danny Jessup has what genetic disease?",
            a1: "Cystic Fibrosis",
            a2: "Spinocerebellar ataxia",
            a3: "Adrenoleukodystrophy",
            a4: "Osteogenesis Imperfecta",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the Magic: The Gathering universe, the Fallen Empires expansion takes place on which continent?",
            a1: "Otaria",
            a2: "Sarpadia",
            a3: "Shiv",
            a4: "Terisiare",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the Magic: The Gathering universe, which plane does the Homelands expansion take place in?",
            a1: "Ulgrotha",
            a2: "Bant",
            a3: "Tazeem",
            a4: "Llanowar",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What's the second book in George R. R. Martin's 'A Song of Ice and Fire' series?",
            a1: "A Feast for Crows",
            a2: "A Dance with Dragons",
            a3: "A Clash of Kings",
            a4: "A Storm of Swords",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Which fictional English county was the setting for Thomas Hardy's novels?",
            a1: "Fulchester",
            a2: "Barsetshire",
            a3: "Ambridge",
            a4: "Wessex",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In which classic novel by Mark Twain did a beggar and Prince of Wales switch clothes, and learn about social class inequality?",
            a1: "Wealthy Boy and the Schmuck",
            a2: "The Prince and the Pauper",
            a3: "Hamlet",
            a4: "A Modern Twain Story",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What was Sir Handel's original name in 'The Railway Series' and it's animated counterpart 'Thomas and Friends?'?",
            a1: "Falcon",
            a2: "Kyte",
            a3: "Eagle",
            a4: "Swallow",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Shub-Niggurath is a creature that was created by J. R. R. Tolkien in his novel 'The Lord of The Rings'?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What is the name of the town in which Lily and James Potter are buried?",
            a1: "Hogsmeade",
            a2: "Little Hangleton",
            a3: "Godric's Hollow",
            a4: "Ottery St. Catchpole",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Who wrote the novel 'Fear And Loathing In Las Vegas'?",
            a1: "William S. Burroughs",
            a2: "Hunter S. Thompson",
            a3: "Henry Miller",
            a4: "F. Scott Fitzgerald",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Harry Potter was born on July 31st, 1980?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Which author and poet famously wrote the line, 'The female of the species is more deadly than the male'?",
            a1: "William Wordsworth",
            a2: "Rudyard Kipling",
            a3: "Edgar Allan Poe",
            a4: "William Shakespeare",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Which famous spy novelist wrote the childrens' story 'Chitty-Chitty-Bang-Bang'?",
            a1: "Ian Fleming",
            a2: "Graham Greene",
            a3: "John Buchan",
            a4: "Joseph Conrad",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the 'Harry Potter' series, what is Headmaster Dumbledore's full name?",
            a1: "Albus James Lunae Otto Dumbledore",
            a2: "Albus Percival Wulfric Brian Dumbledore",
            a3: "Albus Valum Jetta Mobius Dumbledore",
            a4: "Albus Valencium Horatio Kul Dumbledore",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What is the title of the first Sherlock Holmes book by Arthur Conan Doyle?",
            a1: "The Doings of Raffles Haw",
            a2: "A Study in Scarlet",
            a3: "The Sign of the Four",
            a4: "A Case of Identity",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following is NOT a work done by Shakespeare?",
            a1: "Cymbeline",
            a2: "Trial of Temperance",
            a3: "Titus Andronicus",
            a4: "Measure For Measure",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What is Ron Weasley's middle name?",
            a1: "Arthur",
            a2: "Dominic",
            a3: "Bilius",
            a4: "John",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the Batman comics, by what other name is the villain Dr. Jonathan Crane known?",
            a1: "Scarecrow",
            a2: "Calendar Man",
            a3: "Clayface",
            a4: "Bane",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the Homestuck Series, what is the alternate name for the Kingdom of Lights?",
            a1: "Golden City",
            a2: "Yellow Moon",
            a3: "Prospit",
            a4: "No Name",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "The 'Pepe' meme originated from a comic drawn by Matt Furie called 'Boy's Club'?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "This Marvel superhero is often called 'The man without fear'?",
            a1: "Daredevil",
            a2: "Hulk",
            a3: "Wolverine",
            a4: "Thor",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What year was the first San Diego Comic-Con?",
            a1: "1985",
            a2: "2000",
            a3: "1990",
            a4: "1970",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "When was the Garfield comic first published?",
            a1: "1988",
            a2: "1978",
            a3: "1982",
            a4: "1973",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "What is the real hair colour of the mainstream comic book version (Earth-616) of Daredevil?",
            a1: "Brown",
            a2: "Auburn",
            a3: "Blonde",
            a4: "Black",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In what year did the first 'The Walking Dead' comic come out?",
            a1: "2003",
            a2: "2006",
            a3: "1999",
            a4: "2001",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the 'Archie' comics, who was Jughead's first girlfriend?",
            a1: "Ethel",
            a2: "Debbi",
            a3: "Joani",
            a4: "Margret",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ART,
            d: c.DIFFICULTY_HARD,
            q: "In the webcomic 'Ava's Demon', what sin is 'Nevy Nervine' based off of?",
            a1: "Sloth",
            a2: "Wrath",
            a3: "Lust",
            a4: "Envy",
            a: 4,
            seen: false
        }
]
    