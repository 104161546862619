
const {c} = require('./consts');

export let science_questions = [
     
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the largest animal currently on Earth?",
            a1: "Giraffe",
            a2: "Blue Whale",
            a3: "Colossal Squid",
            a4: "Orca",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which element has the chemical symbol 'Fe'?",
            a1: "Gold",
            a2: "Silver",
            a3: "Iron",
            a4: "Tin",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "This element, when overcome with extreme heat and pressure, creates diamonds?",
            a1: "Hydrogen",
            a2: "Carbon",
            a3: "Oxygen",
            a4: "Nitrogen",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the powerhouse of the cell?",
            a1: "Nucleus",
            a2: "Mitochondria",
            a3: "Ribosome",
            a4: "Redbull",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the standard SI unit for temperature?",
            a1: "Rankine",
            a2: "Kelvin",
            a3: "Fahrenheit",
            a4: "Celsius",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following blood vessels carries deoxygenated blood?",
            a1: "Pulmonary Artery",
            a2: "Pulmonary Vein",
            a3: "Aorta",
            a4: "Coronary Artery",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Who is the chemical element Curium named after?",
            a1: "Curious George",
            a2: "The Curiosity Rover",
            a3: "Marie & Pierre Curie",
            a4: "Stephen Curry",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What term is best associated with Sigmund Freud?",
            a1: "Theory of Gravity",
            a2: "Dialectical Behavior Therapy",
            a3: "Psychoanalysis",
            a4: "Cognitive-Behavioral Therapy",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "The medical term for the belly button is which of the following?",
            a1: "Umbilicus",
            a2: "Nevus",
            a3: "Nares",
            a4: "Paxillus",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "How many chromosomes are in your body cells?",
            a1: "24",
            a2: "21",
            a3: "23",
            a4: "22",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "An exothermic reaction is a chemical reaction that releases energy by radiating electricity?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "An Astronomical Unit is the distance between Earth and the Moon?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Sugar contains fat?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the unit of electrical resistance?",
            a1: "Tesla",
            a2: "Mho",
            a3: "Joule",
            a4: "Ohm",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the speed of light in a vacuum?",
            a1: "299,792,458 m/s",
            a2: "50,461 m/s",
            a3: "308,215,043 m/s",
            a4: "751,665,014,151 m/s",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which noble gas has the lowest atomic number?",
            a1: "Argon",
            a2: "Krypton",
            a3: "Neon",
            a4: "Helium",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Not including false teeth; A human has two sets of teeth in their lifetime?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Dry ice is the solid form of what substance?",
            a1: "Nitrogen",
            a2: "Carbon dioxide",
            a3: "Ammonia",
            a4: "Oxygen",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Who discovered the Law of Gravity?",
            a1: "Charles Darwin",
            a2: "Sir Isaac Newton",
            a3: "Galileo Galilei",
            a4: "Albert Einstein",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Igneous rocks are formed by excessive heat and pressure?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the colour of unoxidized blood?",
            a1: "Blue",
            a2: "Red",
            a3: "Purple",
            a4: "Green",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What does the letter 'S' stand for in 'NASA'?",
            a1: "Society",
            a2: "Science",
            a3: "Space",
            a4: "Star",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which is the longest bone in the human body?",
            a1: "Ulna",
            a2: "Femur",
            a3: "Fibula",
            a4: "Scapula",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which type of rock is created by intense heat AND pressure?",
            a1: "Metamorphic",
            a2: "Diamond",
            a3: "Sedimentary",
            a4: "Igneous",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "How many planets are in our Solar System?",
            a1: "Nine",
            a2: "Seven",
            a3: "Ten",
            a4: "Eight",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the hottest planet in the Solar System?",
            a1: "Jupiter",
            a2: "Mars",
            a3: "Venus",
            a4: "Mercury",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the primary addictive substance found in tobacco?",
            a1: "Glaucine",
            a2: "Nicotine",
            a3: "Cathinone",
            a4: "Ephedrine",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Alzheimer's disease primarily affects which part of the human body?",
            a1: "Heart",
            a2: "Lungs",
            a3: "Brain",
            a4: "Skin",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What does DNA stand for?",
            a1: "Deoxyribonucleic Acid",
            a2: "Detoxic Acid",
            a3: "Deoxyribogenetic Atoms",
            a4: "Deoxyribogenetic Acid",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "The medical condition osteoporosis affects which part of the body?",
            a1: "Heart",
            a2: "Skin",
            a3: "Brain",
            a4: "Bones",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "An atom contains a nucleus?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "The element involved in making human blood red is which of the following?",
            a1: "Iridium",
            a2: "Iron",
            a3: "Copper",
            a4: "Cobalt",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Human cells typically have how many copies of each gene?",
            a1: "3",
            a2: "2",
            a3: "1",
            a4: "4",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which Apollo mission was the first one to land on the Moon?",
            a1: "Apollo 13",
            a2: "Apollo 11",
            a3: "Apollo 9",
            a4: "Apollo 10",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the 'powerhouse' of the Eukaryotic animal cell?",
            a1: "Chloroplast",
            a2: "Nucleus",
            a3: "Mitochondria",
            a4: "Endoplasmic Reticulum",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the chemical makeup of water?",
            a1: "H",
            a2: "H20",
            a3: "C12H6O2",
            a4: "CO2",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "How many bones are in the human body?",
            a1: "209",
            a2: "206",
            a3: "200",
            a4: "203",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "The asteroid belt is located between which two planets?",
            a1: "Earth and Mars",
            a2: "Mars and Jupiter",
            a3: "Mercury and Venus",
            a4: "Jupiter and Saturn",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "An average human can go two weeks without water?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Water always boils at 100°C, 212°F, 373.15K, no matter where you are?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the official name of the star located closest to the North Celestial Pole?",
            a1: "Gamma Cephei",
            a2: "Iota Cephei",
            a3: "Eridanus",
            a4: "Polaris",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which of these Elements is a metalloid?",
            a1: "Bromine",
            a2: "Tin",
            a3: "Rubidium",
            a4: "Antimony",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following bones is not in the leg?",
            a1: "Tibia",
            a2: "Radius",
            a3: "Patella",
            a4: "Fibula",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which of these bones is hardest to break?",
            a1: "Femur",
            a2: "Humerus",
            a3: "Tibia",
            a4: "Cranium",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "How many planets make up our Solar System?",
            a1: "7",
            a2: "8",
            a3: "9",
            a4: "6",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which element has the highest melting point?",
            a1: "Carbon",
            a2: "Tungsten",
            a3: "Platinum",
            a4: "Osmium",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What name is given to all baby marsupials?",
            a1: "Joey",
            a2: "Pup",
            a3: "Cub",
            a4: "Calf",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the first element on the periodic table?",
            a1: "Hydrogen",
            a2: "Oxygen",
            a3: "Helium",
            a4: "Lithium",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which gas forms about 78% of the Earth’s atmosphere?",
            a1: "Carbon Dioxide",
            a2: "Oxygen",
            a3: "Argon",
            a4: "Nitrogen",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which is the most abundant element in the universe?",
            a1: "Helium",
            a2: "Hydrogen",
            a3: "Lithium",
            a4: "Oxygen",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the standard atomic weight of a Plutonium nucleus?",
            a1: "481",
            a2: "94",
            a3: "244",
            a4: "128",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What does LASER stand for?",
            a1: "Lite analysing by stereo ecorazer",
            a2: "Light amplifier by standby energy of radio",
            a3: "Life antimatter by standing entry of range",
            a4: "Light amplification by stimulated emission of radiation",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "A plant that has a life cycle for more than a year is known as an annual?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the elemental symbol for mercury?",
            a1: "Me",
            a2: "Hy",
            a3: "Mc",
            a4: "Hg",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What was the name of the first artificial Earth satellite, launched by the Soviet Union in 1957?",
            a1: "Zenit-2",
            a2: "Sputnik 1",
            a3: "Voskhod 3KV",
            a4: "Soyuz 7K-OK",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "The biggest distinction between a eukaryotic cell and a prokaryotic cell is:?",
            a1: "The overall size",
            a2: "The presence or absence of a nucleus",
            a3: "The presence or absence of certain organelles",
            a4: "The mode of reproduction",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "71% of the Earth's surface is made up of?",
            a1: "Deserts",
            a2: "Forests",
            a3: "Water",
            a4: "Continents",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What animal takes part in Schrödinger's most famous thought experiment?",
            a1: "Butterfly",
            a2: "Bat",
            a3: "Cat",
            a4: "Dog",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Celiac Disease is a disease that effects the heart, causing those effected to be unable to eat meat?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "The human heart has how many chambers?",
            a1: "2",
            a2: "6",
            a3: "3",
            a4: "4",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "In human biology, a circadium rhythm relates to a period of roughly how many hours?",
            a1: "32",
            a2: "16",
            a3: "8",
            a4: "24",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Salt is 100% composed of Sodium?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Sound can travel through a vacuum?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Rhinoplasty is a surgical procedure on what part of the human body?",
            a1: "Ears",
            a2: "Neck",
            a3: "Chin",
            a4: "Nose",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What lies at the center of our galaxy?",
            a1: "A supernova",
            a2: "A black hole",
            a3: "A wormhole",
            a4: "A quasar",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Psychology is the science of behavior and mind?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the telescope that was placed in orbit in the eighties?",
            an: "Hubble",
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the most important mineral for strong bones & teeth?",
            an: "Calcium",
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Who was the first person to prove that planets move in elliptical orbits?",
            an: "Kepler",
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the cord joining a mother & her unborn child?",
            an: "Umbilical cord",
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Describes A Person With No Skin Pigment?",
            an: "An Albino",
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What are the grinding teeth called?",
            an: "Molars",
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What phenomenon is caused by the gravitational attraction of the moon?",
            a1: "Hurricanes",
            a2: "Earthquakes",
            a3: "Tides",
            a4: "Tropical Storms",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is -459.7 F also know as?",
            a1: "Kelvin Zero",
            a2: "Froze Zone",
            a3: "Absolute Zero",
            a4: "Bloody Cold",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Who Was The First Animal In Space?",
            a1: "Laika The Dog",
            a2: "Harry The Hampster",
            a3: "Chloe The Cat",
            a4: "Rupert The Rabbit",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "A dog's __________ has over 200 scent receiving cells?",
            a1: "Ears",
            a2: "Tongue",
            a3: "Eyes",
            a4: "Nose",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Is The More Common Name For Sodium Chloride?",
            a1: "Ivory",
            a2: "Chlorine",
            a3: "Salt",
            a4: "Diamond",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "The remains of prehistoric organisms that have been preserved in rocks are called ________?",
            a1: "Diamonds",
            a2: "Fossils",
            a3: "Nuttels",
            a4: "Stones",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Is Measured In Amps?",
            a1: "Electric Current",
            a2: "Electric Pressure",
            a3: "Electric Storage",
            a4: "Electric Voltage",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the main component of air?",
            a1: "Nitrogen",
            a2: "Oxygen",
            a3: "Helium",
            a4: "Hydrogen",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Is The Positive Particle In An Atom Called?",
            a1: "Proton",
            a2: "Neutron",
            a3: "Electron",
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Water freezes at what degrees Celcius?",
            a1: "-20",
            a2: "0",
            a3: "10",
            a4: "-10",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the fastest growing species of grass?",
            a1: "Lawngrass",
            a2: "Bamboo",
            a3: "Bentgrass",
            a4: "Cat Grass",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the biological name for the shin bone?",
            a1: "Femur",
            a2: "fibula",
            a3: "Patella",
            a4: "Tibia",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which Is The Largest Species Of Ape?",
            a1: "Gorilla",
            a2: "Baboon",
            a3: "Mandrill",
            a4: "Gibbon",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is a female deer called?",
            a1: "Foe",
            a2: "Joe",
            a3: "Doe",
            a4: "Moe",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What hormone is produced by the adrenal glands?",
            a1: "Cortisol",
            a2: "Melatonin",
            a3: "Insulin",
            a4: "Adrenaline",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Is a Prosthetic?",
            a1: "A Fake Nose",
            a2: "An Artificial Body Part",
            a3: "A Fake Eyeball",
            a4: "A Fake Hairline",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "A __________ can remember a specific tone far better than can a human?",
            a1: "Shark",
            a2: "Whale",
            a3: "Otter",
            a4: "Dolphin",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What's the name for a curved line between any two points on a circle?",
            a1: "Semi",
            a2: "Arc",
            a3: "Halo",
            a4: "Pi",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "By What Abbreviated Term Is The Group Of Eight Industrialized Nations Known?",
            a1: "Giga 8",
            a2: "G8",
            a3: "Ground 8",
            a4: "Green 8",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which Animal Is The Symbol Of The World Wide Fund For Nature?",
            a1: "Zebra",
            a2: "Orca",
            a3: "Giant Panda",
            a4: "Chimpanzee",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Developed In The Us In 1930 By What Name Is Polyvinyl Chloride Better Known?",
            a1: "PVC",
            a2: "PVF",
            a3: "PLC",
            a4: "PVL",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "The blue whale is maintained by its blubber and can go up to half a year without __________?",
            a1: "Sleeping",
            a2: "Breeding",
            a3: "Drinking",
            a4: "Eating",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "In Which US National Park Is The Old Faithful Geyser?",
            a1: "Death Valley Park",
            a2: "Yosemite Park",
            a3: "Grand Canyon Park",
            a4: "Yellowstone National Park",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What protein makes blood red?",
            a1: "Integrin",
            a2: "Estrogen",
            a3: "Haemoglobin",
            a4: "Insulin",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "A mated pair of __________ can produce up to 15,000 babies in one year?",
            a1: "Rats",
            a2: "Bulls",
            a3: "Dogs",
            a4: "Snakes",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the meaning of the name of the constellation Delphinus?",
            a1: "Denzel",
            a2: "Dinosaur",
            a3: "Dracula",
            a4: "Dolphin",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Is The Name Of The Phenomenon In Which Light Bends When Passing Through a Lens?",
            a1: "Detraction",
            a2: "Defraction",
            a3: "Refraction",
            a4: "Reflection",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Is A Diamond Made Of?",
            a1: "Boron",
            a2: "Iodine",
            a3: "Calcium",
            a4: "Carbon",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What does the pancreas produce?",
            a1: "Insulin",
            a2: "Serine",
            a3: "Glutamine",
            a4: "Glycine",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Where Might You Find Your Temporal Lobe?",
            a1: "Heart",
            a2: "Kidneys",
            a3: "Lungs",
            a4: "Brain",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "There are over 1,600 known species of Starfishes in the world?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Whats the technical name for the skull?",
            a1: "Nut",
            a2: "Crown",
            a3: "Cranium",
            a4: "Noggin",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "To Which Family Does The Jay Belong?",
            a1: "Owl",
            a2: "Crow",
            a3: "Hawk",
            a4: "Bat",
            a: 2,
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Is An Exoskeleton?",
            an: "An animal's External Skeleton",
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What does LPG stands for?",
            a1: "Light Petroleum Gas",
            a2: "Liquid Petroleum Gas",
            a3: "Less Petroleum Gas",
            a4: "Lithium Petroleum Gas",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What has the chemical formula H2SO4?",
            a1: "Acetic Acid",
            a2: "Hydrochloric Acid",
            a3: "Sulphuric Acid",
            a4: "Nitric Acid",
            a: 3,
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the chemical formula for water?",
            an: "H2O",
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What chemical compound causes pain in muscles after exercise?",
            a1: "Lactic acid",
            a2: "Acetic Acid",
            a3: "Nitric Acid",
            a4: "Sulphuric Acid",
            a: 1,
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "By what chemical process do plants manufacture food?",
            an: "Photosynthesis",
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which mineral has the chemical formula FeS2?",
            a1: "Periodic acid",
            a2: "Iron Pyrites",
            a3: "Iodine heptafluoride",
            a4: "Trimethylindium",
            a: 2,
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "The chemical compound sodium chloride is often sprinkled on food before ingestion. What is it's common name?",
            an: "Salt",
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the chemical name for water?",
            an: "Hydrogen oxide",
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which Is The Only Chemical Element With A Three Letter Name?",
            an: "Tin",
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "A gas produced by the incomplete combustion of coal in a mine fire is very poisonous, what is its chemical name?",
            an: "Carbon monoxide",
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which element has the chemical symbol U?",
            a1: "Erbium",
            a2: "Lutetium",
            a3: "Uranium",
            a4: "Terbium",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Is The Chemical Notation For Hydrogen?",
            a1: "Hy",
            a2: "H",
            a3: "Hi",
            a4: "X",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the largest planet in our solar system?",
            a1: "Venus",
            a2: "Saturn",
            a3: "Jupiter",
            a4: "Mars",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What has a gravitational pull so strong that even light cannot escape it?",
            a1: "Supernova",
            a2: "Neutron Star",
            a3: "Black hole",
            a4: "Red SuperGiant",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What are the bones in the fingers called?",
            a1: "Humerus",
            a2: "Scapula",
            a3: "Phalanges",
            a4: "Sternum",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Where is the uvula located?",
            a1: "Chest",
            a2: "Groin",
            a3: "Throat",
            a4: "Nose",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which organ has four chambers?",
            a1: "Liver",
            a2: "Kidneys",
            a3: "Heart",
            a4: "Lungs",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "In which body part can you find the femur?",
            a1: "Hand",
            a2: "Arm",
            a3: "Leg",
            a4: "Foot",
            a: 3,
            seen: false
        },
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is your body's largest organ?",
            an: "Skin",
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What kind of cells are found in the brain?",
            a1: "Stem",
            a2: "Nerve",
            a3: "Cartillage",
            a4: "Neurons",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What percentage of our bodies is made up of water?",
            a1: "0-5%",
            a2: "40-45%",
            a3: "60-65%",
            a4: "20-25%",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Which element is said to keep bones strong?",
            a1: "Selenium",
            a2: "Calcium",
            a3: "Potassium",
            a4: "Magnesium",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Botany is the scientific study of what?",
            a1: "Grass",
            a2: "Trees",
            a3: "Mushrooms",
            a4: "Plants",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What vitamin does the sun's ultraviolet rays help the skin produce?",
            a1: "Vitamin K",
            a2: "Vitamin A",
            a3: "Vitamin D",
            a4: "Vitamin C",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Water has a pH level of around?",
            a1: "7",
            a2: "4",
            a3: "6",
            a4: "5",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the first element on the Periodic Table?",
            a1: "Hydrogen",
            a2: "Oxygen",
            a3: "Helium",
            a4: "Nitrogen",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the only metal that is a liquid at normal atmospheric pressure and temperature?",
            a1: "Iodine",
            a2: "Mercury",
            a3: "Zinc",
            a4: "Cadmium",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the most abundant element in the universe?",
            a1: "Helium",
            a2: "Hydrogen",
            a3: "Xenon",
            a4: "Krypton",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What particle in an atom has a positive charge?",
            a1: "Proton",
            a2: "Neutron",
            a3: "Electron",
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What part of the atom has no electric charge?",
            a1: "Proton",
            a2: "Neutron",
            a3: "Electron",
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the symbol for potassium?",
            a1: "K",
            a2: "Rg",
            a3: "Tb",
            a4: "Re",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What object did Ben Franklin attach to the end of a kite string to prove that lightning is electricity?",
            a1: "A bolt",
            a2: "A key",
            a3: "A whistle",
            a4: "A spanner",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "Who was the first woman to win a Nobel Prize (1903)?",
            a1: "Marie Curie",
            a2: "Rosalind Franklin",
            a3: "Barbara McClintock",
            a4: "Lise Meitner",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What Animal Can Change Its Colour?",
            a1: "A Chameleon",
            a2: "Tree frogs",
            a3: "Humming Bird",
            a4: "Sand Cats",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What two colors is a magpie?",
            a1: "Black and white",
            a2: "Brown & Grey",
            a3: "Grey & White",
            a4: "Black & Grey",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "This protein makes the blood red in color?",
            a1: "Hemoglobin",
            a2: "Myoglobin",
            a3: "Platelets",
            a4: "Iron",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "A male cow is called?",
            a1: "Dog",
            a2: "Ox",
            a3: "Monkey",
            a4: "Sheep",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is Earth’s only natural satellite?",
            a1: "Venus",
            a2: "Sun",
            a3: "Mars",
            a4: "Moon",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What three things do most plants need to grow? Water, Soil and _____?",
            a1: "Sunlight",
            a2: "Oxygen",
            a3: "Food",
            a4: "Bacteria",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What powder do flowers make?",
            a1: "Pollen",
            a2: "Bulking",
            a3: "Carbonate",
            a4: "Dusting",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What force stops a ball rolling on the ground forever?",
            a1: "Velocity",
            a2: "Tension",
            a3: "Gravity",
            a4: "Friction",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What process do plants use to make their own food?",
            a1: "Photosynthesis",
            a2: "Microbiology",
            a3: "Immunoassay",
            a4: "Listeria",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What does the atomic number of an element represent?",
            a1: "The number of protons in the nucleus",
            a2: "The number of electrons in the nucleus",
            a3: "The number of protons in the nucleus",
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_EASY,
            q: "What is the name for the process of a liquid becoming a gas?",
            a1: "Evaporation",
            a2: "Theranostics",
            a3: "Photosynthesis",
            a4: "Decomposition",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "The Neanderthals were a direct ancestor of modern humans?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "A person can get sunburned on a cloudy day?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "The humerus, paired radius, and ulna come together to form what joint?",
            a1: "Ankle",
            a2: "Sholder",
            a3: "Elbow",
            a4: "Knee",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "The 'Gympie Stinger' is the deadliest plant in the world?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which chemical element has the lowest boiling point?",
            a1: "Helium",
            a2: "Neon",
            a3: "Hydrogen",
            a4: "Nitrogen",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Steel is an alloy of Iron and Carbon?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which element has the atomic number of 7?",
            a1: "Oxygen",
            a2: "Hydrogen",
            a3: "Neon",
            a4: "Nitrogen",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Like with the Neanderthals, Homo sapiens sapiens also interbred with the Denisovans?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "A defibrillator is used to start up a heartbeat once a heart has stopped beating?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "The 'Tibia' is found in which part of the body?",
            a1: "Leg",
            a2: "Arm",
            a3: "Head",
            a4: "Hand",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What polymer is used to make CDs, safety goggles and riot shields?",
            a1: "Bakelite",
            a2: "Rubber",
            a3: "Polycarbonate",
            a4: "Nylon",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the atomic number of the element Strontium?",
            a1: "47",
            a2: "38",
            a3: "73",
            a4: "11",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "On which mission did the Space Shuttle Columbia break up upon re-entry?",
            a1: "STS-109",
            a2: "STS-107",
            a3: "STS-51-L",
            a4: "STS-61-C",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Where did the Great Storm of 1987 make landfall at, first?",
            a1: "Cornwall",
            a2: "The Midlands",
            a3: "Surrey",
            a4: "Wales",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which color cannot be produced in Roses, even through genetic alteration?",
            a1: "Orange",
            a2: "Blue",
            a3: "Black",
            a4: "Brown",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of these choices is not one of the phases of mitosis?",
            a1: "Diplophase",
            a2: "Anaphase",
            a3: "Metaphase",
            a4: "Telophase",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "When the Falcon Heavy was launched on it's test flight, what was the only part of the operation that failed?",
            a1: "Ignition and Liftoff",
            a2: "Center Core Landing",
            a3: "Side Booster Landing",
            a4: "Deployment of Starman",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Along with Oxygen, which element is primarily responsible for the sky appearing blue?",
            a1: "Helium",
            a2: "Hydrogen",
            a3: "Nitrogen",
            a4: "Carbon",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "The Doppler effect applies to light?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "All the following metal elements are liquids at or near room temperature except:?",
            a1: "Beryllium",
            a2: "Gallium",
            a3: "Mercury",
            a4: "Caesium",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which part of the body does glaucoma affect?",
            a1: "Blood",
            a2: "Eyes",
            a3: "Stomach",
            a4: "Throat",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Scientists accidentally killed the once known world's oldest living creature, a mollusc, known to be aged as 507 years old?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "After which Danish city is the 72th element on the periodic table named?",
            a1: "Copenhagen",
            a2: "Skagen",
            a3: "Herning",
            a4: "Odense",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What does the yellow diamond on the NFPA 704 fire diamond represent?",
            a1: "Health",
            a2: "Reactivity",
            a3: "Radioactivity",
            a4: "Flammability",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "About how old is Earth?",
            a1: "5.5 Billion Years",
            a2: "4.5 Billion Years",
            a3: "2.5 Billion Years",
            a4: "3.5 Billion Years",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the unit of electrical capacitance?",
            a1: "Gauss",
            a2: "Farad",
            a3: "Henry",
            a4: "Watt",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which desert is the only desert in the world where the 'Saguaro' cactus grows indigenously?",
            a1: "The Sonoran Desert",
            a2: "The Gobi Desert",
            a3: "The Yuma Desert",
            a4: "The Arabian Desert",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What are human nails made of?",
            a1: "Chitin",
            a2: "Keratin",
            a3: "Bone",
            a4: "Calcium",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What do you study if you are studying entomology?",
            a1: "Insects",
            a2: "Humans",
            a3: "the Brain",
            a4: "Fish",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the Linnean name of the domestic apple tree?",
            a1: "Appelus delectica",
            a2: "Malus pumila",
            a3: "Pomus domestica",
            a4: "Malus americana",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which portion of the Marijuana plant produces the psychoactive substance known as THC?",
            a1: "Female Flower",
            a2: "Male Flower",
            a3: "Root Ball",
            a4: "Leaves",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the study of the cells and tissues of plants and animals?",
            a1: "Biochemistry",
            a2: "Microbiology",
            a3: "Histology",
            a4: "Anatomy",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the molecular formula of the active component of chili peppers(Capsaicin)?",
            a1: "C6H4Cl2",
            a2: "C13H25NO4",
            a3: "C21H23NO3",
            a4: "C18H27NO3",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the first living creature in space?",
            a1: "Fruit Flies",
            a2: "Monkey",
            a3: "Mouse",
            a4: "Dog",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "To the nearest minute, how long does it take for light to travel from the Sun to the Earth?",
            a1: "12 Minutes",
            a2: "8 Minutes",
            a3: "6 Minutes",
            a4: "2 Minutes",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the chemical formula for ammonia?",
            a1: "CO2",
            a2: "NH3",
            a3: "CH4",
            a4: "NO3",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "In Chemistry, how many isomers does Butanol (C4H9OH) have?",
            a1: "6",
            a2: "4",
            a3: "3",
            a4: "5",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "A positron is an antiparticle of a what?",
            a1: "Photon",
            a2: "Proton",
            a3: "Electron",
            a4: "Neutron",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which planet did the 'Viking 1' spacecraft send surface images of, starting in 1976?",
            a1: "Jupiter",
            a2: "Mars",
            a3: "Saturn",
            a4: "Venus",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the half-life of Uranium-235?",
            a1: "1,260,900,000 years",
            a2: "4,300,400,000 years",
            a3: "Uranium-235 is a stable isotope",
            a4: "703,800,000 years",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following is NOT a passive electrical component?",
            a1: "Inductor",
            a2: "Transistor",
            a3: "Resistor",
            a4: "Capacitor",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is Hypernatremia?",
            a1: "Decrease in blood iron",
            a2: "Decrease in blood potassium",
            a3: "Increase in blood sodium",
            a4: "Increase in blood glucose",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "How old is the universe?",
            a1: "4.5 Billion Years",
            a2: "7.9 Billion Years",
            a3: "16.2 Billion Years",
            a4: "13.8 Billion Years",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of these stars is the largest?",
            a1: "VY Canis Majoris",
            a2: "RW Cephei",
            a3: "Betelgeuse",
            a4: "UY Scuti",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "The planet Mars has two moons orbiting it?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Gannymede is the largest moon of which planet?",
            a1: "Neptune",
            a2: "Mars",
            a3: "Jupiter",
            a4: "Uranus",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Au on the Periodic Table refers to which element?",
            a1: "Silver",
            a2: "Oxygen",
            a3: "Nickel",
            a4: "Gold",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "How many officially recognized dwarf planets in the solar system are named after Polynesian deities?",
            a1: "1",
            a2: "2",
            a3: "0",
            a4: "5",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Where did the dog breed 'Chihuahua' originate?",
            a1: "Mexico",
            a2: "Russia",
            a3: "Spain",
            a4: "France",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of these is a type of stretch/deep tendon reflex?",
            a1: "Gag reflex",
            a2: "Pupillary light reflex",
            a3: "Ankle jerk reflex",
            a4: "Scratch reflex",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following liquids is least viscous? Assume temperature is 25°C?",
            a1: "Water",
            a2: "Benzene",
            a3: "Mercury",
            a4: "Acetone",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which is the chemical name of H2O?",
            a1: "Manganese dioxide",
            a2: "Ammonium chloride",
            a3: "Anhydrous Sodium Carbonate",
            a4: "Dihydrogen Monoxide",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is the scientific name of the knee cap?",
            a1: "Femur",
            a2: "Patella",
            a3: "Scapula",
            a4: "Foramen Magnum",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "If you planted the seeds of Quercus robur what would grow?",
            a1: "Flowers",
            a2: "Trees",
            a3: "Grains",
            a4: "Vegtables",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is the scientific name of the red fox?",
            a1: "Vulpes Redus",
            a2: "Red Fox",
            a3: "Vulpes Vulpes",
            a4: "Vulpes Vulpie",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Folic acid is the synthetic form of which vitamin?",
            a1: "Vitamin C",
            a2: "Vitamin A",
            a3: "Vitamin D",
            a4: "Vitamin B",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What nucleotide pairs with guanine?",
            a1: "Thymine",
            a2: "Cytosine",
            a3: "Uracil",
            a4: "Adenine",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Botanically speaking, which of these fruits is NOT a berry?",
            a1: "Blueberry",
            a2: "Concord Grape",
            a3: "Strawberry",
            a4: "Banana",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Muscle fiber is constructed of bundles small long organelles called what?",
            a1: "Epimysium",
            a2: "Myofibrils",
            a3: "Myofiaments",
            a4: "Myocardium",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following is NOT a word used to describe an earthquake?",
            a1: "Foreshock",
            a2: "Drop-slide",
            a3: "Strike-slip",
            a4: "Temblor",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following elements is typically used in the doping of the semiconductor silicon?",
            a1: "Boron",
            a2: "Carbon",
            a3: "Iron",
            a4: "Oxygen",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following is a major muscle of the back?",
            a1: "Trapezoid",
            a2: "Trapezius",
            a3: "Triquetrum",
            a4: "Trapezium",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What causes the sound of a heartbeat?",
            a1: "Relaxation of the heart chambers",
            a2: "Blood exiting the heart",
            a3: "Contraction of the heart chambers",
            a4: "Closure of the heart valves",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "How many legs is it biologically impossible for a centipede to have?",
            a1: "26",
            a2: "50",
            a3: "74",
            a4: "100",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "An organic compound is considered an alcohol if it has what functional group?",
            a1: "Hydroxyl",
            a2: "Aldehyde",
            a3: "Carbonyl",
            a4: "Alkyl",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "All of the following human genetic haplogroup names are shared between Y-chromosome and mitochondrial DNA haplogroups EXCEPT:?",
            a1: "Haplogroup U",
            a2: "Haplogroup J",
            a3: "Haplogroup L",
            a4: "Haplogroup T",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "A comet's gaseous envelope (which creates the tail) is called what?",
            a1: "The backwash",
            a2: "The ablative",
            a3: "The coma",
            a4: "The wake",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is the molecular formula of Ozone?",
            a1: "N2O",
            a2: "O3",
            a3: "C6H2O6",
            a4: "SO4",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is the unit of electrical inductance?",
            a1: "Weber",
            a2: "Coulomb",
            a3: "Mho",
            a4: "Henry",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Burning which of these metals will produce a bright white flame?",
            a1: "Lithium",
            a2: "Copper",
            a3: "Magnesium",
            a4: "Lead",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "The Western Lowland Gorilla is scientifically know as?",
            a1: "Gorilla Gorilla Diehli",
            a2: "Gorilla Beringei Beringei",
            a3: "Gorilla Gorilla Gorilla",
            a4: "Gorilla Beringei Graueri",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following is considered classical conditioning?",
            a1: "Schrödinger's cat experiment",
            a2: "Harlow’s monkey experiments",
            a3: "Pavlov's dog experiments",
            a4: "Skinner box experiment",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "How many types of quarks are there in the standard model of physics?",
            a1: "3",
            a2: "2",
            a3: "4",
            a4: "6",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which horizon in a soil profile consists of bedrock?",
            a1: "D",
            a2: "B",
            a3: "O",
            a4: "R",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of these chemical compounds is NOT found in gastric acid?",
            a1: "Hydrochloric acid",
            a2: "Sulfuric acid",
            a3: "Sodium chloride",
            a4: "Potassium chloride",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which is not a type of neuron?",
            a1: "Motor Neuron",
            a2: "Perceptual Neuron",
            a3: "Sensory Neuron",
            a4: "Interneuron",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following is NOT a real element?",
            a1: "Praseodymium",
            a2: "Lutetium",
            a3: "Hassium",
            a4: "Vitrainium",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of these is a stop codon in DNA?",
            a1: "ACT",
            a2: "TAA",
            a3: "GTA",
            a4: "ACA",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What element on the periodic table has 92 electrons?",
            a1: "Uranium",
            a2: "Hydrogen",
            a3: "Iron",
            a4: "Sulfur",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "How long is a light-year?",
            a1: "1 AU",
            a2: "501.2 Million Miles",
            a3: "9.461 Trillion Kilometres",
            a4: "105.40 Earth-years",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is the scientific name for the extinct hominin known as 'Lucy'?",
            a1: "Australopithecus Architeuthis",
            a2: "Australopithecus Afarensis",
            a3: "Australopithecus Antaris",
            a4: "Australopithecus Africanus",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following is the term for 'surgical complications resulting from surgical sponges left inside the patient's body?",
            a1: "Gongoozler",
            a2: "Meupareunia",
            a3: "Gossypiboma",
            a4: "Jentacular",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "In the Scoville scale, what is the hottest chemical?",
            a1: "Resiniferatoxin",
            a2: "Capsaicin",
            a3: "Tinyatoxin",
            a4: "Dihydrocapsaicin",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is isobutylphenylpropanoic acid more commonly known as?",
            a1: "Aspirin",
            a2: "Morphine",
            a3: "Ibuprofen",
            a4: "Ketamine",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which moon is the only satellite in our solar system to possess a dense atmosphere?",
            a1: "Miranda",
            a2: "Titan",
            a3: "Europa",
            a4: "Callisto",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "The chemical element Lithium is named after the country of Lithuania?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "How much radiation does a banana emit?",
            a1: "0.5 Microsievert",
            a2: "0.7 Microsievert",
            a3: "0.3 Microsievert",
            a4: "0.1 Microsievert",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Autosomal-dominant Compelling Helio-Ophthalmic Outburst syndrome is the need to do what when seeing the Sun?",
            a1: "Sneeze",
            a2: "Hiccup",
            a3: "Yawn",
            a4: "Cough",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What was the first organic compound to be synthesized from inorganic compounds?",
            a1: "Formaldehyde",
            a2: "Propane",
            a3: "Ethanol",
            a4: "Urea",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is the name for the auditory illusion of a note that seems to be rising infinitely?",
            a1: "McGurck Effect",
            a2: "Fransen Effect",
            a3: "Glissandro Illusion",
            a4: "Shepard Tone",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is the most potent toxin known?",
            a1: "Botulinum toxin",
            a2: "Ricin",
            a3: "Cyanide",
            a4: "Asbestos",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following are cells of the adaptive immune system?",
            a1: "Natural killer cells",
            a2: "Dendritic cells",
            a3: "Cytotoxic T cells",
            a4: "White blood cells",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is 'Stenoma'?",
            a1: "A type of seasoning",
            a2: "A combat stimulant from WW2",
            a3: "A port city in the carribean",
            a4: "A genus of moths",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which scientific unit is named after an Italian nobleman?",
            a1: "Volt",
            a2: "Ohm",
            a3: "Pascal",
            a4: "Hertz",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Which major extinction event was caused by an asteroid collision and eliminated the majority of non-avian dinosaurs?",
            a1: "Cretaceous-Paleogene",
            a2: "Triassic–Jurassic",
            a3: "Ordovician–Silurian",
            a4: "Permian–Triassic",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "In physics, conservation of energy and conservation of momentum are both consequences of which of the following?",
            a1: "Wick's Theorem",
            a2: "Carnot's Theorem",
            a3: "Bell's Theorem",
            a4: "Noether's Theorem",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is considered the rarist form of color blindness?",
            a1: "Green",
            a2: "Blue",
            a3: "Red",
            a4: "Purple",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "The 'Islets of Langerhans' is found in which human organ?",
            a1: "Liver",
            a2: "Brain",
            a3: "Pancreas",
            a4: "Kidney",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "What is the same in Celsius and Fahrenheit?",
            a1: "32",
            a2: "-42",
            a3: "-39",
            a4: "-40",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SCI,
            d: c.DIFFICULTY_HARD,
            q: "Where is the Gluteus Maximus muscle located?",
            a1: "Head",
            a2: "Butt",
            a3: "Torso",
            a4: "Arm",
            a: 2,
            seen: false
        }
]
    