
const {c} = require('./consts');

export let entertainment_questions = [
     
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What breed of dog was Marley in the film 'Marley & Me' (2008)?",
            a1: "Labrador Retriever",
            a2: "Golden Retriever",
            a3: "Dalmatian",
            a4: "Shiba Inu",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which animated movie was first to feature a celebrity as a voice actor?",
            a1: "Toy Story",
            a2: "The Hunchback of Notre Dame",
            a3: "Aladdin",
            a4: "James and the Giant Peach",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "When was the movie 'Con Air' released?",
            a1: "1999",
            a2: "1985",
            a3: "1997",
            a4: "1990",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Han Solo's co-pilot and best friend, 'Chewbacca', is an Ewok?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "The movie 'The Nightmare before Christmas' was all done with physical objects?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Matt Damon played an astronaut stranded on an extraterrestrial planet in both of the movies Interstellar and The Martian?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What was the title of the first Bond movie, released in 1962?",
            a1: "From Russia with Love",
            a2: "Thunderball",
            a3: "Dr. No",
            a4: "Goldfinger",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "This movie contains the quote, 'Nobody puts Baby in a corner.'?",
            a1: "Ferris Bueller's Day Off",
            a2: "Three Men and a Baby",
            a3: "Pretty in Pink",
            a4: "Dirty Dancing",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is the oldest Disney film?",
            a1: "Dumbo",
            a2: "Pinocchio",
            a3: "Snow White and the Seven Dwarfs",
            a4: "Fantasia",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In Alfred Hitchcock's film 'Psycho' it is said he used chocolate syrup to simulate the blood in the famous shower scene from?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who in Pulp Fiction says 'No, they got the metric system there, they wouldn't know what the fuck a Quarter Pounder is.'?",
            a1: "Vincent Vega",
            a2: "Jimmie Dimmick",
            a3: "Jules Winnfield",
            a4: "Butch Coolidge",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Brandon Routh plays the titular character in the movie 'John Wick'?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "'Foodfight!' earned less than $80,000 at box office?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which of these Movies was NOT released in 1996?",
            a1: "Mission: Impossible",
            a2: "Independence Day",
            a3: "The Rock",
            a4: "Gladiator",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the fictional retro-mod band starring Austin Powers as the lead vocalist?",
            a1: "Ming Tea",
            a2: "Mister E",
            a3: "Cough Fi",
            a4: "Spear Mint",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who directed the Kill Bill movies?",
            a1: "Quentin Tarantino",
            a2: "Arnold Schwarzenegger",
            a3: "Stanley Kubrick",
            a4: "David Lean",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following actors has only been in a Quentin Tarantino directed film once?",
            a1: "Christoph Waltz",
            a2: "Samuel L. Jackson",
            a3: "John Travolta",
            a4: "Harvey Keitel",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What historical time period was the center of the Assassin’s Creed movie (2016)?",
            a1: "Victorian England",
            a2: "Spanish Inquisition",
            a3: "Colonial America",
            a4: "French Revolution",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which of these films is NOT set in Los Angeles?",
            a1: "Blade Runner",
            a2: "RoboCop",
            a3: "Predator 2",
            a4: "The Terminator",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following was not one of 'The Magnificent Seven'?",
            a1: "Robert Vaughn",
            a2: "Charles Bronson",
            a3: "Steve McQueen",
            a4: "Clint Eastwood",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which actress danced the twist with John Travolta in 'Pulp Fiction'?",
            a1: "Kathy Griffin",
            a2: "Uma Thurman",
            a3: "Pam Grier",
            a4: "Bridget Fonda",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who starred in the film 1973 movie 'Enter The Dragon'?",
            a1: "Bruce Lee",
            a2: "Jet Li",
            a3: "Yun-Fat Chow",
            a4: "Jackie Chan",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who directed the 2015 movie 'The Revenant'?",
            a1: "Alejandro G. Iñárritu",
            a2: "Christopher Nolan",
            a3: "David Fincher",
            a4: "Wes Anderson",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who plays Alice in the Resident Evil movies?",
            a1: "Kim Demp",
            a2: "Milla Jovovich",
            a3: "Madison Derpe",
            a4: "Milla Johnson",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Shaquille O'Neal appeared in the 1997 film 'Space Jam'?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which actor plays Obi-Wan Kenobi in Star Wars Episodes I-lll?",
            a1: "Ewan McGregor",
            a2: "Alec Guinness",
            a3: "Hayden Christensen",
            a4: "Liam Neeson",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the original Star Wars trilogy, David Prowse was the actor who physically portrayed Darth Vader?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the movie The Revenant, DiCaprio's character hunts down the killer of his son?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "When does 'Rogue One: A Star Wars Story' take place chronologically in the series?",
            a1: "Before Episode 1",
            a2: "Between Episode 4 and 5",
            a3: "Between Episode 3 and 4",
            a4: "After Episode 6",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the movie 'Blade Runner', what is the term used for human-like androids?",
            a1: "Replicants",
            a2: "Cylons",
            a3: "Synthetics",
            a4: "Skinjobs",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "The 2016 Disney animated film 'Moana' is based on which culture?",
            a1: "Nordic",
            a2: "Japanese",
            a3: "Polynesian",
            a4: "Native American",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the 1995 film 'Balto', who are Steele's accomplices?",
            a1: "Kaltag, Nikki, and Star",
            a2: "Dusty, Kirby, and Ralph",
            a3: "Nuk, Yak, and Sumac",
            a4: "Jenna, Sylvie, and Dixie",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the movie 'V for Vendetta,' what is the date that masked vigilante 'V' urges people to remember?",
            a1: "September 5th",
            a2: "November 4th",
            a3: "November 5th",
            a4: "November 6th",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "The 1996 film 'Fargo' is primarily set in which US state?",
            a1: "Minnesota",
            a2: "Wisconsin",
            a3: "North Dakota",
            a4: "South Dakota",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following movies was not based on a novel by Stephen King?",
            a1: "The Green Mile",
            a2: "Misery",
            a3: "Carrie",
            a4: "The Thing",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who directed 'E.T. the Extra-Terrestrial' (1982)?",
            a1: "James Cameron",
            a2: "Steven Spielberg",
            a3: "Stanley Kubrick",
            a4: "Tim Burton",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "The word 'Inception' came from the 2010 blockbuster hit 'Inception'?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Ewan McGregor did not know the name of the second prequel film of Star Wars during and after filming?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Samuel L. Jackson had the words, 'Bad Motherf*cker' in-scripted on his lightsaber during the filming of Star Wars?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the movie 'Cast Away' the main protagonist's best friend while on the island is named?",
            a1: "Carson",
            a2: "Wilson",
            a3: "Jackson",
            a4: "Willy",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who plays the character of Po in the Kung Fu Panda movies?",
            a1: "Mirana Jonnes",
            a2: "Jack Black",
            a3: "McConahey Ramses",
            a4: "Jim Petersson",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following is not the name of a 'Bond Girl'?",
            a1: "Wai Lin",
            a2: "Mary Goodnight",
            a3: "Vanessa Kensington",
            a4: "Pam Bouvier",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "The film '2001: A Space Odyssey' was released on December 31st, 2000?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who directed the movies 'Pulp Fiction', 'Reservoir Dogs' and 'Django Unchained'?",
            a1: "Quentin Tarantino",
            a2: "James Cameron",
            a3: "Martin Scorcese",
            a4: "Steven Spielberg",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "The Queen song `A Kind Of Magic` is featured in which 1986 film?",
            a1: "Flash Gordon",
            a2: "Labyrinth",
            a3: "Howard the Duck",
            a4: "Highlander",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "This movie contains the quote, 'Houston, we have a problem.'?",
            a1: "Marooned",
            a2: "The Right Stuff",
            a3: "Capricorn One",
            a4: "Apollo 13",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "The 2010 film 'The Social Network' is a biographical drama film about MySpace founder Tom Anderson?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the original script of 'The Matrix', the machines used humans as additional computing power instead of batteries?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which of these movies did Jeff Bridges not star in?",
            a1: "The Giver",
            a2: "The Hateful Eight",
            a3: "True Grit",
            a4: "Tron: Legacy",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the 2012 film, 'The Lorax', who is the antagonist?",
            a1: "Aloysius O'Hare",
            a2: "Ted Wiggins",
            a3: "Grammy Norma",
            a4: "The Once-Ler",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "At the end of the 2001 film 'Rat Race', whose concert do the contestants crash?",
            a1: "Smash Mouth",
            a2: "Bowling for Soup",
            a3: "Linkin Park",
            a4: "Sum 41",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Actor Tommy Chong served prison time?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "For the film 'Raiders of The Lost Ark', what was Harrison Ford sick with during the filming of the Cairo chase?",
            a1: "Dysentery",
            a2: "Constipation",
            a3: "Anemia",
            a4: "Acid Reflux",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What name did Tom Hanks give to his volleyball companion in the film `Cast Away`?",
            a1: "Billy",
            a2: "Wilson",
            a3: "Friday",
            a4: "Jones",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What was Dorothy's surname in 'The Wizard Of Oz'?",
            a1: "Gale",
            a2: "Day",
            a3: "Perkins",
            a4: "Parker",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the original Star Wars trilogy, Alec Guinness provided the voice for Darth Vader?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What was the first monster to appear alongside Godzilla?",
            a1: "Anguirus",
            a2: "King Kong",
            a3: "Mothra",
            a4: "King Ghidora",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the island that 'Jurassic Park' is built on?",
            a1: "Isla Sorna",
            a2: "Isla Nublar",
            a3: "Isla Pena",
            a4: "Isla Muerta",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the movie 'Spaceballs', what are the Spaceballs attempting to steal from Planet Druidia?",
            a1: "The Schwartz",
            a2: "Meatballs",
            a3: "Princess Lonestar",
            a4: "Air",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "George Lucas directed the entire original Star Wars trilogy?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In Big Hero 6, what fictional city is the Big Hero 6 from?",
            a1: "San Fransokyo",
            a2: "San Tokyo",
            a3: "Tokysisco",
            a4: "Sankyo",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Where does the original Friday The 13th movie take place?",
            a1: "Higgins Haven",
            a2: "Camp Forest Green",
            a3: "Packanack",
            a4: "Camp Crystal Lake",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In 'ALF', what was ALF's real name?",
            a1: "Gordon Milipp",
            a2: "Gordon Foster",
            a3: "Gordon von Gam",
            a4: "Gordon Shumway",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who played Deputy Marshal Samuel Gerard in the 1993 film 'The Fugitive'?",
            a1: "Tommy Lee Jones",
            a2: "Harvey Keitel",
            a3: "Martin Landau",
            a4: "Harrison Ford",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who played the female lead in the 1933 film 'King Kong'?",
            a1: "Vivien Leigh",
            a2: "Mae West",
            a3: "Fay Wray",
            a4: "Jean Harlow",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who is the main protagonist in, the 1985 film, Back to the Future?",
            a1: "Biff Tannen",
            a2: "George McFly",
            a3: "Emmett 'Doc' Brown",
            a4: "Marty McFly",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the movie Gremlins, after what time of day should you not feed Mogwai?",
            a1: "Midnight",
            a2: "Morning",
            a3: "Afternoon",
            a4: "Evening",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "'Minions' was released on the June 10th, 2015?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What was Bruce Campbell's iconic one-liner after getting a chainsaw hand in Evil Dead 2?",
            a1: "Groovy",
            a2: "Nice",
            a3: "Perfect",
            a4: "Gnarly",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who plays Jack Burton in the movie 'Big Trouble in Little China?'?",
            a1: "John Cusack",
            a2: "Harrison Ford",
            a3: "Kurt Russell",
            a4: "Patrick Swayze",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Daniel Radcliffe became a global star in the film industry due to his performance in which film franchise?",
            a1: "Ted",
            a2: "Harry Potter",
            a3: "Pirates of the Caribbean",
            a4: "Spy Kids",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which movie contains the quote, 'Say hello to my little friend!'?",
            a1: "Scarface",
            a2: "Goodfellas",
            a3: "Reservoir Dogs",
            a4: "Heat",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "'The first rule is: you don't talk about it' is a reference to which movie?",
            a1: "The Island",
            a2: "American Pie",
            a3: "Fight Club",
            a4: "Unthinkable",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In the 1992 film 'Army of Darkness', what name does Ash give to his double-barreled shotgun?",
            a1: "Blastbranch",
            a2: "Bloomstick",
            a3: "Boomstick",
            a4: "2-Pump Chump",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which movie released in 2016 features Superman and Batman fighting?",
            a1: "Batman v Superman: Superapocalypse",
            a2: "Batman v Superman: Dawn of Justice",
            a3: "Batman v Superman: Black of Knight",
            a4: "Batman v Superman: Knightfall",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who wrote and directed the 1986 film 'Platoon'?",
            a1: "Michael Cimino",
            a2: "Oliver Stone",
            a3: "Stanley Kubrick",
            a4: "Francis Ford Coppola",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Leonardo DiCaprio won an Oscar for Best Actor in 2004's 'The Aviator'?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In 'Jurassic World', what is the name of the dinosaur that is a genetic hybrid?",
            a1: "Mosasaurus",
            a2: "Tyrannosaurus Rex",
            a3: "Pteranodon",
            a4: "Indominus Rex",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "The 2002 film '28 Days Later' is mainly set in which European country?",
            a1: "France",
            a2: "Italy",
            a3: "Germany",
            a4: "United Kingdom",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What was the first feature-length computer-animated movie?",
            a1: "Tron",
            a2: "Toy Story",
            a3: "Lion king",
            a4: "101 Dalmatians",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "This movie contains the quote, 'I feel the need ... the need for speed!'?",
            a1: "Days of Thunder",
            a2: "Top Gun",
            a3: "The Color of Money",
            a4: "Cocktail",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is the orange and white bot's name in 'Star Wars: The Force Awakens'?",
            a1: "BB-3",
            a2: "AA-A",
            a3: "BB-8",
            a4: "R2-D2",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which movie includes a giant bunny-like spirit who has magic powers including growing trees?",
            a1: "My Neighbor Totoro",
            a2: "Rise of the Guardians",
            a3: "Hop",
            a4: "Alice in Wonderland",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In which film does Humphrey Bogart say the famous line, 'Here's looking at you, kid'?",
            a1: "The Treasure of the Sierra Madre",
            a2: "Casablanca",
            a3: "Citizen Kane",
            a4: "The Maltese Falcon",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which of these actors/actresses is NOT a part of the cast for the 2016 movie 'Suicide Squad'?",
            a1: "Margot Robbie",
            a2: "Scarlett Johansson",
            a3: "Jared Leto",
            a4: "Will Smith",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What was the first James Bond film?",
            a1: "Dr. No",
            a2: "Goldfinger",
            a3: "Thunderball",
            a4: "From Russia With Love",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What was the cause of Marilyn Monroes suicide?",
            a1: "Knife Attack",
            a2: "Drug Overdose",
            a3: "Gunshot",
            a4: "House Fire",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which cartoon character lives in a pineapple under the sea?",
            a1: "Popeye",
            a2: "Ariel",
            a3: "Snoopy",
            a4: "Spongebob Squarepants",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which singer's real name is Stefani Joanne Angelina Germanotta?",
            a1: "Shakira",
            a2: "Dua Lipa",
            a3: "Lady Gaga",
            a4: "Gwen Stafani",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "How many of Snow White's seven dwarfs have names ending in the letter Y?",
            a1: "Four",
            a2: "Five: Dopey, Grumpy, Sneezy, Sleepy, Happy",
            a3: "Three",
            a4: "Six",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which Disney film features the song 'When You Wish Upon a Star'?",
            a1: "Zootopia",
            a2: "Turning Red",
            a3: "Pinocchio",
            a4: "Encanto",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What did the Crocodile swallow in Peter Pan?",
            a1: "An Alarm Clock",
            a2: "A Bed",
            a3: "A Cat",
            a4: "A Boat",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What's the hardest rock?",
            a1: "Corundum",
            a2: "Quartz",
            a3: "Diamond",
            a4: "Topaz",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who did Forbes name the youngest self-made billionaire in 2021?",
            a1: "Jake Paul",
            a2: "Kylie Jenner",
            a3: "Austin Russell",
            a4: "Rihanna",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which Disney princess dresses up as a man to save her father?",
            a1: "Belle",
            a2: "Tiana",
            a3: "Mulan",
            a4: "Elsa",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In which film did father and son actors Will and Jaden Smith first appear together?",
            a1: "I Am Legend",
            a2: "King Richard",
            a3: "The Pursuit of Happiness",
            a4: "Alladin",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Robin Williams won a best supporting actor Oscar for what film?",
            a1: "Patch Adams",
            a2: "Mrs. Doubtfire",
            a3: "Jumanji",
            a4: "Good Will Hunting",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which Indiana Jones movie was released back in 1984?",
            a1: "The Last Crusade",
            a2: "Kingdom of the Crystal Skull",
            a3: "Temple of Doom",
            a4: "Raiders of the Lost Ark",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "How many Lord of the Rings films are there?",
            a1: "2",
            a2: "5",
            a3: "4",
            a4: "3",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What was the name of the actor who played Jack Dawson in Titanic?",
            a1: "Clint Eastwood",
            a2: "Christian Bale",
            a3: "Leondardo DiCaprio",
            a4: "Brad Pitt",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which actor appeared in films “Face Off” and “Ghost Rider”?",
            a1: "Al Pacino",
            a2: "Keanu Reaves",
            a3: "Nicholas Cage",
            a4: "Bruce Willis",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who played Wolverine?",
            a1: "Rober Downey Jr",
            a2: "Harrison Ford",
            a3: "Hugh Jackman",
            a4: "Chris Hemsworth",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which popular TV show featured house Targaryen and Stark?",
            a1: "Walking Dead",
            a2: "Lost",
            a3: "Game of Thrones",
            a4: "Breaking Bad",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is the longest-running Broadway show?",
            a1: "Matilda",
            a2: "The Phantom of the Opera",
            a3: "Les Miserables",
            a4: "Wicked",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which show is the highest-grossing production on Broadway ever?",
            a1: "Hamilton",
            a2: "The Lion King",
            a3: "Singin' in the Rain",
            a4: "Hairspray!",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In what year was Broadway established?",
            a1: "1750",
            a2: "1900",
            a3: "1800",
            a4: "1850",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who Was The First man To Set Foot On The Moon?",
            a1: "Neil Armstrong",
            a2: "Alan Bean",
            a3: "Buzz Aldrin",
            a4: "Yuri Gagarin",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is Rihanna's real name?",
            a1: "Alicia Swift",
            a2: "Robyn Fenty",
            a3: "Janet Cabello",
            a4: "Rachel Pine",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Taylor Swift made her acting debut in which movie?",
            a1: "Magic Mike",
            a2: "Valentine's Day",
            a3: "Inception",
            a4: "The Lost City",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which artist made history in 2020 as the youngest winner of the Grammys’ four main categories?",
            a1: "xxxxxxxxxxxxxxx",
            a2: "xxxxxxxxxxxxxxx",
            a3: "xxxxxxxxxxxxxxx",
            a4: "Billie Eilish",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What year did Keeping Up with the Kardashians first premier?",
            a1: "1997",
            a2: "2007",
            a3: "2002",
            a4: "1992",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Mariah Carey had her latest No. 1 song with which single?",
            a1: "All I Want For Christmas Is You",
            a2: "xxxxxxxxxxxxxxx",
            a3: "xxxxxxxxxxxxxxx",
            a4: "xxxxxxxxxxxxxxx",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which tech entrepreneur named his son X Æ A-12?",
            a1: "Elon Musk",
            a2: "Sergey Brin",
            a3: "Mark Zuckerberg",
            a4: "Jeff Bezoz",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Zendaya competed on what reality show?",
            a1: "xxxxxxxxxxxxxxx",
            a2: "xxxxxxxxxxxxxxx",
            a3: "xxxxxxxxxxxxxxx",
            a4: "Dancing With The Stars",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "15 Years after Friends ended, Jennifer Aniston made her return to TV on what show?",
            a1: "Prison Break",
            a2: "The Morning Show",
            a3: "Breaking Bad",
            a4: "Good Morning",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who was the highest-paid actress of 2019, according to Forbes?",
            a1: "Margot Robbie",
            a2: "Emily Blunt",
            a3: "Scarlett Johansson",
            a4: "Jennifer Aniston",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who did Forbes name the youngest 'self-made billionaire ever' in 2019?",
            a1: "Kylie Jenner",
            a2: "Zendaya",
            a3: "Scarlett Johansson",
            a4: "Ariel Gade",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Kim Kardashian and Kris Humphries were married for how many days?",
            a1: "24",
            a2: "184",
            a3: "72",
            a4: "13",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "George Clooney became a big star after starring in which ’90s TV drama?",
            a1: "xxxxxxxxxxxxxxx",
            a2: "xxxxxxxxxxxxxxx",
            a3: "ER",
            a4: "xxxxxxxxxxxxxxx",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What was the name of the first James Bond movie in which Daniel Craig appeared?",
            a1: "Spectre",
            a2: "Skyfall",
            a3: "Goldfinger",
            a4: "Casino Royale",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which iconic singer-songwriter was born Robert Allen Zimmerman in 1941?",
            a1: "Elvis Presley",
            a2: "Bob Dylan",
            a3: "Jimi Hendrix",
            a4: "Johnny Rivers",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Tom Hanks starred opposite Leonardo DiCaprio in which movie?",
            a1: "Inception",
            a2: "Catch Me If You Can",
            a3: "Wolf of Wall Street",
            a4: "Titanic",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Will Smith costarred with Kevin James and Eva Mendes in what movie?",
            a1: "Hitch",
            a2: "Men in Black",
            a3: "Suicide Squad",
            a4: "Aladdin",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of Michelle Obama’s 2018 memoir?",
            a1: "xxxxxxxxxxxxxxx",
            a2: "Becoming",
            a3: "xxxxxxxxxxxxxxx",
            a4: "xxxxxxxxxxxxxxx",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What name was celebrity talk show host Oprah Winfrey born with?",
            a1: "Opa",
            a2: "Orca",
            a3: "Ora",
            a4: "Orpah",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Where did Prince William and Kate Middleton first meet?",
            a1: "In Harrods",
            a2: "St. Andrews University",
            a3: "Down the pub",
            a4: "At Wimbledon",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What Hollywood power couple announced they were separating on January 7, 2005?",
            a1: "Brad Pitt and Jennifer Aniston",
            a2: "William and Kate",
            a3: "Kayne and Kim",
            a4: "Harry and Meghan",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is a nickname for the Academy Awards?",
            a1: "Grammys",
            a2: "Emmys",
            a3: "The Oscars",
            a4: "Golden Globes",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which James Bond movie was the first for Pierce Brosnan as 007?",
            a1: "Tomorrow Never Dies",
            a2: "Live and Let Die",
            a3: "The World Is Not Enough",
            a4: "GoldenEye",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who directed the movie Schindler's List?",
            a1: "Quentin Tarantino",
            a2: "Martin Scorsese",
            a3: "Stanley Kubrick",
            a4: "Steven Spielberg",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What popular Disney character makes an appearance as a stuffed animal in the film Frozen?",
            a1: "Goofy",
            a2: "Genie",
            a3: "Donald Duck",
            a4: "Mickey Mouse",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who was the only non-Jedi in the original Star Wars trilogy to use a lightsaber?",
            a1: "Qui-Gon Jinn",
            a2: "Obi-Wan Kenobi",
            a3: "Luke Skywalker",
            a4: "Han Solo",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Approximately how many languages can C-3PO speak in Star Wars?",
            a1: "600,000",
            a2: "6000",
            a3: "6,000,000",
            a4: "6",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who played the voice of Dory in the film Finding Dory?",
            a1: "Katy Perry",
            a2: "Ellen DeGeneres",
            a3: "Mariah Carey",
            a4: "Miley Cyrus",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In what year was Jim Carrey’s first film released?",
            a1: "1876",
            a2: "1984",
            a3: "1995",
            a4: "1972",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which movie is the first non-English language film to win an Oscar for Best Picture?",
            a1: "Spirited Away",
            a2: "The Lives of Others",
            a3: "Like Stars on Earth",
            a4: "Parasite",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is Stephen King’s top-grossing movie?",
            a1: "The Shining",
            a2: "xxxxxxxxxxxxxxx",
            a3: "xxxxxxxxxxxxxxx",
            a4: "xxxxxxxxxxxxxxx",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Where was the entire trilogy of Lord of the Rings filmed?",
            a1: "UK",
            a2: "Australia",
            a3: "USA",
            a4: "New Zealand",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is the first song you hear in Disney’s The Lion King?",
            a1: "xxxxxxxxxxxxxxx",
            a2: "xxxxxxxxxxxxxxx",
            a3: "The Circle of Life",
            a4: "xxxxxxxxxxxxxxx",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "During which war is Saving Private Ryan set?",
            a1: "WWI",
            a2: "Vietnam War",
            a3: "Cold War",
            a4: "WWII",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the school in Harry Potter?",
            a1: "Hogwarts",
            a2: "North Shore",
            a3: "Springfield Elementary",
            a4: "Tree Hill",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who directed the movie 'Schindler's List'?",
            a1: "David Fincher",
            a2: "Christopher Nolan",
            a3: "Steven Spielberg",
            a4: "Tim Burton",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In which country is 'Gone with the Wind' set?",
            a1: "Germany",
            a2: "Denmark",
            a3: "UK",
            a4: "USA",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "In which series of films can you see a character named Frodo Baggins?",
            a1: "Toy Story",
            a2: "Lord of the Rings",
            a3: "Frozen",
            a4: "Harry Potter",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which colour are the Na'vi in Avatar?",
            a1: "Red",
            a2: "Yellow",
            a3: "Green",
            a4: "Blue",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which actor plays James Bond in Skyfall and Casino Royale?",
            a1: "Sean Connery",
            a2: "Daniel Craig",
            a3: "Pierce Brosnan",
            a4: "Roger Moore",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the Princess in 'The Little Mermaid'?",
            a1: "Ariel",
            a2: "Jasmine",
            a3: "Elsa",
            a4: "Olaf",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Who directed the movie 'Pulp Fiction'?",
            a1: "Stanley Kubrick",
            a2: "Ridley Scott",
            a3: "Steven Spielberg",
            a4: "Quentin Tarantino",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "Which Marvel character has wrist-mounted web-shooters?",
            a1: "Hulk",
            a2: "Ironman",
            a3: "Captain America",
            a4: "Spiderman",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_EASY,
            q: "What type of creature is Shrek?",
            a1: "Gnome",
            a2: "Golem",
            a3: "Leprechaun",
            a4: "Ogre",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who starred as Bruce Wayne and Batman in Tim Burton's 1989 movie 'Batman'?",
            a1: "George Clooney",
            a2: "Val Kilmer",
            a3: "Michael Keaton",
            a4: "Adam West",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which actor played the main character in the 1990 film 'Edward Scissorhands'?",
            a1: "Ben Stiller",
            a2: "Leonardo DiCaprio",
            a3: "Johnny Depp",
            a4: "Clint Eastwood",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In which 1955 film does Frank Sinatra play Nathan Detroit?",
            a1: "High Society",
            a2: "Anchors Aweigh",
            a3: "From Here to Eternity",
            a4: "Guys and Dolls",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who played Batman in the 1997 film 'Batman and Robin'?",
            a1: "Christian Bale",
            a2: "George Clooney",
            a3: "Val Kilmer",
            a4: "Michael Keaton",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In which African country was the 2006 film 'Blood Diamond' mostly set in?",
            a1: "Burkina Faso",
            a2: "Liberia",
            a3: "Sierra Leone",
            a4: "Central African Republic",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In what year was the first Transformers movie released?",
            a1: "1986",
            a2: "1984",
            a3: "2007",
            a4: "2009",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of the first 'Star Wars' film by release order?",
            a1: "The Phantom Menace",
            a2: "A New Hope",
            a3: "The Force Awakens",
            a4: "Revenge of the Sith",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which iconic character is featured in movies such as 'The Enforcer', 'Sudden Impact', and 'The Dead Pool'?",
            a1: "James Bond",
            a2: "Indiana Jones",
            a3: "Harry Potter",
            a4: "Dirty Harry",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who played Baron Victor Frankestein in the 1957 Hammer horror film 'The Curse of Frankenstein'?",
            a1: "Peter Cushing",
            a2: "Vincent Price",
            a3: "Lon Chaney Jr",
            a4: "Boris Karloff",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "The 1939 movie 'The Wizard of Oz' contained a horse that changed color, what material did they use to achieve this effect?",
            a1: "Gelatin",
            a2: "CGI Effect",
            a3: "Dye",
            a4: "Paint",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the correct spelling of the protagonist of the book in The NeverEnding Story (1984)?",
            a1: "Atraiyu",
            a2: "Atrayu",
            a3: "Atreyu",
            a4: "Atraeyu",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which actor plays the character 'Tommy Jarvis' in 'Friday the 13th: The Final Chapter' (1984)?",
            a1: "Mark Hamill",
            a2: "Macaulay Culkin",
            a3: "Corey Feldman",
            a4: "Mel Gibson",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What character in the Winnie the Pooh films was added by Disney and does not appear in the original books?",
            a1: "Gopher",
            a2: "Heffalumps",
            a3: "Tigger",
            a4: "Rabbit",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of the queen's pet in A Bug's Life?",
            a1: "Dot",
            a2: "Flik",
            a3: "Hopper",
            a4: "Aphie",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which 90's comedy cult classic features cameos appearances from Meat Loaf, Alice Cooper and Chris Farley?",
            a1: "Bill & Ted's Excellent Adventure",
            a2: "Dumb and Dumber",
            a3: "Wayne's World",
            a4: "Austin Powers: International Man of Mystery",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who performed the opening theme song for the James Bond 007 movie 'Goldfinger'?",
            a1: "Sheena Easton",
            a2: "Tom Jones",
            a3: "John Barry",
            a4: "Shirley Basey",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Brendan Fraser starred in the following movies, except which one?",
            a1: "Monkeybone",
            a2: "Mrs. Winterbourne",
            a3: "Encino Man",
            a4: "Titanic",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In the film 'Requiem for a Dream', what drug does Jared Leto's character get addicted to?",
            a1: "Cocaine",
            a2: "Heroin",
            a3: "Oxycodone",
            a4: "Marijuana",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of the foley artist who designed the famous sounds of Star Wars, including Chewbacca's roar and R2-D2's beeps and whistles?",
            a1: "Ralph McQuarrie",
            a2: "Ken Burns",
            a3: "Miranda Keyes",
            a4: "Ben Burtt",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "The original ending of 'Little Shop Of Horrors' has the plants taking over the world?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the birth name of Michael Caine?",
            a1: "Morris Coleman",
            a2: "Martin Michaels",
            a3: "Carl Myers",
            a4: "Maurice Micklewhite",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What were the Chilled Monkey Brains made from during Indiana Jones and the Temple of Doom?",
            a1: "Custard and Raspberry Sauce",
            a2: "Strawberry Ice Cream",
            a3: "Raspberry Sorbet",
            a4: "Cherry Yogurt",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which retired American football quarterback played himself in 'Ace Ventura: Pet Detective' and 'Little Nicky'?",
            a1: "Tom Brady",
            a2: "John Elway",
            a3: "Joe Montana",
            a4: "Dan Marino",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of James Dean's character in the 1955 movie 'Rebel Without a Cause'?",
            a1: "Ned Stark",
            a2: "Jim Stark",
            a3: "Frank Stark",
            a4: "Jim Kane",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of the dog that played Toto in the 1939 film 'The Wizard of Oz'?",
            a1: "Toto",
            a2: "Teddy",
            a3: "Terry",
            a4: "Tommy",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of the Artificial Intelligence system in the 1983 film, 'WarGames'?",
            a1: "Self Evolving Thought Helix",
            a2: "West Campus Analog Computer",
            a3: "Master Control Program",
            a4: "War Operation Plan Response",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which movie sequel had improved box office results compared to its original film?",
            a1: "Son of the Mask",
            a2: "Toy Story 2",
            a3: "Speed 2: Cruise Control",
            a4: "Sin City: A Dame to Kill For",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "The Xenomorph from the science fiction film 'Alien' has acidic blood?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which movie of film director Stanley Kubrick is known to be an adaptation of a Stephen King novel?",
            a1: "2001: A Space Odyssey",
            a2: "Eyes Wide Shut",
            a3: "Dr. Strangelove",
            a4: "The Shining",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was another suggested name for, the 1985 film, Back to the Future?",
            a1: "The Lucky Man",
            a2: "Spaceman From Pluto",
            a3: "The Time Travelers",
            a4: "Hill Valley Time Travelers",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In what year was the movie 'Police Academy' released?",
            a1: "1986",
            a2: "1985",
            a3: "1983",
            a4: "1984",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In The Lord of the Rings: The Fellowship of the Ring, which one of the following characters from the book was left out of the film?",
            a1: "Barliman Butterbur",
            a2: "Tom Bombadil",
            a3: "Strider",
            a4: "Celeborn",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In Finding Nemo, what was the name of Nemo's mom?",
            a1: "Shelly",
            a2: "Sandy",
            a3: "Coral",
            a4: "Pearl",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In the Friday The 13th series, what is Jason's mother's first name?",
            a1: "Angeline",
            a2: "Pamela",
            a3: "Christine",
            a4: "Mary",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which Marvel superhero did Chris Evans play prior to his role as Captain America?",
            a1: "Human Torch",
            a2: "Cyclops",
            a3: "Iceman",
            a4: "Daredevil",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following actors does not play a role in the movie 'The Usual Suspects?'?",
            a1: "Kevin Spacey",
            a2: "Benicio Del Toro",
            a3: "Steve Buscemi",
            a4: "Gabriel Byrne",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "About how much money did it cost for Tommy Wiseau to make his masterpiece 'The Room' (2003)?",
            a1: "$6 Million",
            a2: "$20,000",
            a3: "$10 Million",
            a4: "$1 Million",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "The killer in the 1981 film 'My Bloody Valentine' wears what sort of outfit?",
            a1: "Mining gear",
            a2: "Clown costume",
            a3: "Police uniform",
            a4: "Santa suit",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which 1958 movie starred Kirk Douglas and Tony Curtis as half-brothers Einar and Eric?",
            a1: "The Long Ships",
            a2: "Spartacus",
            a3: "The Vikings",
            a4: "Prince Valiant",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was Marilyn Monroe`s character's first name in the film 'Some Like It Hot'?",
            a1: "Caramel",
            a2: "Honey",
            a3: "Candy",
            a4: "Sugar",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Velma Kelly and Roxie Hart are the protagonists of which Oscar winning movie?",
            a1: "Chicago",
            a2: "Cabaret",
            a3: "Dreamgirls",
            a4: "All That Jazz",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In which 1973 film does Yul Brynner play a robotic cowboy who malfunctions and goes on a killing	spree?",
            a1: "Westworld",
            a2: "The Terminators",
            a3: "Runaway",
            a4: "Android",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who voiced the character Draco in the 1996 movie 'DragonHeart'?",
            a1: "Brian Thompson",
            a2: "Sean Connery",
            a3: "Pete Postlethwaite",
            a4: "Dennis Quaid",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "In the 1984 movie 'The Terminator', what model number is the Terminator portrayed by Arnold Schwarzenegger?",
            a1: "T-888",
            a2: "T-800",
            a3: "T-1000",
            a4: "I-950",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "There aren't any live-action clones in 'Star Wars: Episode III - Revenge of the Sith'?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "This movie contains the quote, 'I love the smell of napalm in the morning!'?",
            a1: "Platoon",
            a2: "Full Metal Jacket",
            a3: "Apocalypse Now",
            a4: "The Deer Hunter",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who plays the Nemesis in the Resident Evil movies?",
            a1: "Jason Dip",
            a2: "Jimmy Matts",
            a3: "Eric Mabius",
            a4: "Matthew Taylor",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which one of these films are shot entirely in one-take?",
            a1: "Good Will Hunting",
            a2: "Birdman",
            a3: "Schindler's List",
            a4: "Russian Ark",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which one of these action movies are shot entirely in one take?",
            a1: "The Bourne Legacy",
            a2: "Ip Man 2",
            a3: "Léon: The Professional",
            a4: "Victoria",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who played Sgt. Gordon Elias in 'Platoon' (1986)?",
            a1: "Charlie Sheen",
            a2: "Matt Damon",
            a3: "Johnny Depp",
            a4: "Willem Dafoe",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What was the last Marx Brothers film to feature Zeppo?",
            a1: "A Night at the Opera",
            a2: "A Day at the Races",
            a3: "Duck Soup",
            a4: "Monkey Business",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In the 1964 film 'Zulu', what song does the British Army company sing before the final battle?",
            a1: "The British Grenadiers",
            a2: "Scotland the Brave",
            a3: "Colonel Bogey March",
            a4: "Men of Harlech",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "'Cube', 'Cube 2: Hypercube' and 'Cube Zero' were directed by the same person?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What was the name of the actor who played Leatherface in the 1974 horror film, The Texas Chainsaw Massacre?",
            a1: "John Dugan",
            a2: "Edwin Neal",
            a3: "Joe Bill Hogan",
            a4: "Gunnar Hansen",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which actors made up the trio in 'The Good, the Bad, and the Ugly'?",
            a1: "Yul Brynner, Steve McQueen, and Charles Bronson",
            a2: "Aldo Giuffrè, Mario Brega, and Luigi Pistilli",
            a3: "Sergio Leone, Ennio Morricone, and Tonino Delli Colli",
            a4: "Clint Eastwood, Eli Wallach, and Lee Van Cleef",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following films was directed by Ivan Reitman, written by Gary Ross, featured Kevin Kline, and was released in 1993?",
            a1: "Dave",
            a2: "John",
            a3: "Will",
            a4: "Carl",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In the Friday The 13th series, what year did Jason drown in?",
            a1: "1953",
            a2: "1959",
            a3: "1955",
            a4: "1957",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which 1994 film did Roger Ebert famously despise, saying 'I hated hated hated hated hated this movie'?",
            a1: "Richie Rich",
            a2: "3 Ninjas Kick Back",
            a3: "North",
            a4: "The Santa Clause",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which singer is portrayed by Bruce Campbell in the 2002 film 'Bubba Ho-Tep'?",
            a1: "Buddy Holly",
            a2: "Johnny Cash",
            a3: "Hank Williams, Sr",
            a4: "Elvis Presley",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What is the name of the assassin in the first 'Hellboy' movie?",
            a1: "Klaus Werner von Krupt",
            a2: "Karl Ruprecht Kroenen",
            a3: "Ilsa Haupstein",
            a4: "Grigori Efimovich Rasputin",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What was the name of the planet in 'Aliens'?",
            a1: "LV-426",
            a2: "Weyland Yutani Corporation Base",
            a3: "DI-621",
            a4: "FR-838",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In the movie 'Scream' who is Ghost Face?",
            a1: "Archie Prescott and Philip Marv",
            a2: "Sidney Prescott",
            a3: "Dewey Riley",
            a4: "Billy Loomis and Stu Macher",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In what year did Clint Eastwood star as Inspector Harry Callahan in the film 'Dirty Harry'?",
            a1: "1975",
            a2: "1971",
            a3: "1969",
            a4: "1983",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Before 'Jurassic Park III' was finalized and the Spinosaurus was chosen, which dinosaur was going to be the star?",
            a1: "Baryonyx",
            a2: "Suchomimus",
            a3: "Velociraptor",
            a4: "Tyrannosaurus Rex",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which actress portrayed Dr. Grace Augustine in the James Cameron movie 'Avatar'?",
            a1: "Jessica Chastain",
            a2: "Melissa Beckett",
            a3: "Alyssa Monroe",
            a4: "Sigourney Weaver",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which sci-fi cult films plot concerns aliens attempting to prevent humans from creating a doomsday weapon?",
            a1: "The Day The Earth Stood Still",
            a2: "The Man from Planet X",
            a3: "Plan 9 from Outer Space",
            a4: "It Came from Outer Space",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which boxer was famous for striking the gong in the introduction to J. Arthur Rank films?",
            a1: "Terry Spinks",
            a2: "Don Cockell",
            a3: "Bombardier Billy Wells",
            a4: "Freddie Mills",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What was Humphrey Bogart's middle name?",
            a1: "DeWinter",
            a2: "Steven",
            a3: "Bryce",
            a4: "DeForest",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "The film Mad Max: Fury Road features the Dies Irae  from which composer's requiem?",
            a1: "Mozart",
            a2: "Brahms",
            a3: "Verdi",
            a4: "Berlioz",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What was the wifi password given to Stephen Strange in Doctor Strange?",
            a1: "Peace",
            a2: "Chakra",
            a3: "Shambala",
            a4: "Ancient",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What did Alfred Hitchcock use as blood in the film 'Psycho'?",
            a1: "Red food coloring",
            a2: "Chocolate syrup",
            a3: "Maple syrup",
            a4: "Ketchup",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What three movies, in order from release date, make up the 'Dollars Trilogy'?",
            a1: "'For a Few Dollars More', 'The Good, the Bad, and the Ugly', 'A Fistful of Dollars'",
            a2: "'For a Few Dollars More', 'A Fistful of Dollars', 'The Good, the Bad, and the Ugly'",
            a3: "'A Fistful of Dollars', 'For a Few Dollars More', 'The Good, the Bad, and the Ugly'",
            a4: "'The Good, the Bad, and the Ugly', 'For A Few Dollars More', 'A Fistful of Dollars'",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In the film 'Harry Potter and the Order of The Phoenix', why was Harry Potter's scream, after Sirius Black died, muted?",
            a1: "Too Harsh",
            a2: "Too Agonizing",
            a3: "Too Loud",
            a4: "Too Violent",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In the film 'Interstellar', how long did they spend on Miller's planet?",
            a1: "10 months and 6 days",
            a2: "15 years, 2 months, and 15 days",
            a3: "26 years, 4 months, and 10 days",
            a4: "23 years, 4 months, and 8 days",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What did the first moving picture depict?",
            a1: "A crackling fire",
            a2: "A man walking",
            a3: "A woman in a dress",
            a4: "A galloping horse",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What was the first movie to ever use a Wilhelm Scream?",
            a1: "Distant Drums",
            a2: "Treasure of the Sierra Madre",
            a3: "Indiana Jones",
            a4: "The Charge at Feather River",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In Big Hero 6, what were Baymax's motions modeled after?",
            a1: "Baby bears",
            a2: "Baby ostriches",
            a3: "Baby monkeys",
            a4: "Baby penguins",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In 'Star Trek Nemesis', why was Praetor Shinzon created?",
            a1: "To destroy the Enterprise",
            a2: "To steal the Enterprise",
            a3: "To replace Picard as a Romulan Agent",
            a4: "To become Picard's friend",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "The weapon Clint Eastwood uses in 'Dirty Harry' was a .44 Automag?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which was the first of Alfred Hitchcock's movies to be filmed in colour?",
            a1: "Rebecca",
            a2: "Vertigo",
            a3: "Rope",
            a4: "Psycho",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In the 1976 film 'Taxi Driver', how many guns did Travis buy from the salesman?",
            a1: "4",
            a2: "6",
            a3: "2",
            a4: "1",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In order to cut costs, what were most of the extras of Mad Max (1979) paid with?",
            a1: "They weren't paid",
            a2: "Beer",
            a3: "Fast food",
            a4: "Food stamps",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "According to 'Star Wars' lore, which planet does Obi-Wan Kenobi come from?",
            a1: "Naboo",
            a2: "Tatooine",
            a3: "Stewjon",
            a4: "Alderaan",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In the 1974 Christmas flick 'The Year Without a Santa Claus,' what are the names of the two elves who help Mrs. Claus save Christmas?",
            a1: "Snowflake & Icicle",
            a2: "Jingle & Jangle",
            a3: "Holly & Jolly",
            a4: "Buddy & Bobby",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In the 'Jurassic Park' universe, what was the first dinosaur cloned by InGen in 1986?",
            a1: "Triceratops",
            a2: "Brachiosaurus",
            a3: "Velociraptor",
            a4: "Troodon",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In what Disney movie can you spot the character 'Pac-Man' in if you look closely enough in some scenes?",
            a1: "Monsters, Inc",
            a2: "Fantasia",
            a3: "Big Hero 6",
            a4: "Tron",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What is the species of the 'Predator' in the 1987 movie 'Predator'?",
            a1: "Xenomorph",
            a2: "Yautja",
            a3: "Phocrex",
            a4: "Praetorian",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "YouTube personality Jenna Marbles served as an executive producer of the film Maximum Ride (2016)?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In what year does Jurassic World open in the 'Jurassic Park' universe?",
            a1: "2020",
            a2: "2007",
            a3: "2015",
            a4: "2005",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In the 'Jurassic Park' universe, when did 'Jurassic Park: San Diego' begin construction?",
            a1: "1985",
            a2: "1988",
            a3: "1993",
            a4: "1986",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "In 2012, which movie won every category in the 32nd 'Golden Raspberry Awards'?",
            a1: "The King's Speech",
            a2: "The Girl with the Dragon Tattoo",
            a3: "Thor",
            a4: "Jack and Jill",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What is the name of the supercomputer located in the control room in 'Jurassic Park' (1993)?",
            a1: "Cray X-MP",
            a2: "Thinking Machines CM-5",
            a3: "IBM Blue Gene/Q",
            a4: "Cray XK7",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Named after a character he played in a 1969 film, what is the name of the ski resort in Utah that Robert Redford bought in 1968?",
            a1: "Booker",
            a2: "Sundance",
            a3: "Woodward",
            a4: "Turner",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which actress married Michael Douglas in 2000?",
            a1: "Sara Sugarman",
            a2: "Ruth Jones",
            a3: "Pam Ferris",
            a4: "Catherine Zeta-Jones",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What was the religion of famous singer 'Freddie Mercury'?",
            a1: "Ashurism",
            a2: "Judaism",
            a3: "Paganism",
            a4: "Zoroastrianism",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "What does film maker Dan Bell typically focus his films on?",
            a1: "Documentaries",
            a2: "Abandoned Buildings and Dead Malls",
            a3: "Historic Landmarks",
            a4: "Action Films",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "How tall is Tom Cruise?",
            a1: "5′ 5″",
            a2: "5′ 4″",
            a3: "5′ 9″",
            a4: "5′ 7″",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Marilyn Monroe was born on July 1, 1926?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Lady Gaga's real name is Stefani Joanne Angelina Germanotta?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_ENT,
            d: c.DIFFICULTY_HARD,
            q: "Which school in Surrey, England did Steve Backshall attend?",
            a1: "Tomlinscote School",
            a2: "Collingwood College",
            a3: "Lyndhurst School",
            a4: "Kings International College",
            a: 2,
            seen: false
        }
]
    