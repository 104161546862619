
const {c} = require('./consts');

export let geography_questions = [
     
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Greenland is almost as big as Africa?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Jamaica?",
            a1: "San Juan",
            a2: "Kingston",
            a3: "Bridgetown",
            a4: "Port-au-Prince",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Vietnam is the only country in the world that starts with V?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the Capital of the United States?",
            a1: "Los Angelas, CA",
            a2: "Washington, D.C",
            a3: "Houston, TX",
            a4: "New York City, NY",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Hungary is the only country in the world beginning with H?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which small country is located between the borders of France and Spain?",
            a1: "Lichtenstein",
            a2: "Andorra",
            a3: "Vatican City",
            a4: "San Marino",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is Laos?",
            a1: "River",
            a2: "City",
            a3: "Region",
            a4: "Country",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "The body of the Egyptian Sphinx was based on which animal?",
            a1: "Lion",
            a2: "Dog",
            a3: "Bull",
            a4: "Horse",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "How many countries are inside the United Kingdom?",
            a1: "One",
            a2: "Three",
            a3: "Two",
            a4: "Four",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "A group of islands is called an 'archipelago'?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What colour is the circle on the Japanese flag?",
            a1: "Yellow",
            a2: "White",
            a3: "Black",
            a4: "Red",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which nation claims ownership of Antarctica?",
            a1: "Australia",
            a2: "United Nations",
            a3: "United States of America",
            a4: "No one, but there are claims",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the official language of Costa Rica?",
            a1: "English",
            a2: "Portuguese",
            a3: "Creole",
            a4: "Spanish",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of South Korea?",
            a1: "Taegu",
            a2: "Seoul",
            a3: "Pyongyang",
            a4: "Kitakyushu",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Scotland?",
            a1: "Edinburgh",
            a2: "Glasgow",
            a3: "Dundee",
            a4: "London",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Rhode Island is actually located on the US mainland, despite its name?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "There is an island in Japan called kunoshima, A.K.A. 'Rabbit Island', so named because of it's huge population of rabbits?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What mountain range lines the border between Spain and France?",
            a1: "Carpathians",
            a2: "Pyrenees",
            a3: "Urals",
            a4: "Alps",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What country is the second largest in the world by area?",
            a1: "China",
            a2: "Russia",
            a3: "Canada",
            a4: "United States of America",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the 15th letter of the Greek alphabet?",
            a1: "Omicron (Ο)",
            a2: "Sigma (Σ)",
            a3: "Pi (Π)",
            a4: "Nu (Ν)",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "There are no deserts in Europe?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Where would you find the 'Spanish Steps'?",
            a1: "Berlin, Germany",
            a2: "Rome, Italy",
            a3: "Barcelona, Spain",
            a4: "London, England",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Denmark?",
            a1: "Copenhagen",
            a2: "Aarhus",
            a3: "Aalborg",
            a4: "Odense",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Toronto is the capital city of the North American country of Canada?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the peninsula containing Spain and Portugal?",
            a1: "Iberian Peninsula",
            a2: "Peloponnesian Peninsula",
            a3: "European Peninsula",
            a4: "Scandinavian Peninsula",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of the US State of New York?",
            a1: "Buffalo",
            a2: "Rochester",
            a3: "New York",
            a4: "Albany",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Spain?",
            a1: "Barcelona",
            a2: "Toledo",
            a3: "Madrid",
            a4: "Sevilla",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of India?",
            a1: "Montreal",
            a2: "Tithi",
            a3: "New Delhi",
            a4: "Bejing",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Tokyo is the capital of Japan?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the largest country in the world?",
            a1: "Russia",
            a2: "United States",
            a3: "Canada",
            a4: "China",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which ocean borders the west coast of the United States?",
            a1: "Indian",
            a2: "Atlantic",
            a3: "Arctic",
            a4: "Pacific",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of the American state of Arizona?",
            a1: "Raleigh",
            a2: "Phoenix",
            a3: "Tallahassee",
            a4: "Montgomery",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which country does Austria not border?",
            a1: "Switzerland",
            a2: "Slovenia",
            a3: "France",
            a4: "Slovakia",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which city is the capital of the United States of America?",
            a1: "Albany",
            a2: "Seattle",
            a3: "Washington D.C",
            a4: "Los Angeles",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "How many countries does the United States share a land border with?",
            a1: "1",
            a2: "4",
            a3: "2",
            a4: "3",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Seoul is the capital of North Korea?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "The longest shared border in the world can be found between which two nations?",
            a1: "Chile and Argentina",
            a2: "Canada and the United States",
            a3: "India and Pakistan",
            a4: "Russia and China",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Greenland is a part of which kingdom?",
            a1: "Sweden",
            a2: "United Kingdom",
            a3: "Denmark",
            a4: "Norway",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What state is the largest state of the United States of America?",
            a1: "Washington",
            a2: "California",
            a3: "Texas",
            a4: "Alaska",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Greenland is covered with grass and Iceland is covered with ice?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Vatican City is a country?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Alaska is the largest state in the United States?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "The Alps are a mountain range on which continent?",
            a1: "Asia",
            a2: "Africa",
            a3: "North America",
            a4: "Europe",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "If soccer is called football in England, what is American football called in England?",
            a1: "American football",
            a2: "Handball",
            a3: "Touchdown",
            a4: "Combball",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "San Marino is the only country completely surrounded by another country?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Ottawa is the capital of Canada?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        },
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What country covers an entire continent?",
            an: "Australia",
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What are the basic building blocks of rocks?",
            a1: "Minerals",
            a2: "Sand",
            a3: "Gravel",
            a4: "Stones",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of France?",
            a1: "Paris",
            a2: "Paris",
            a3: "Conakry",
            a4: "Budapest",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Where In Egypt Is The Tomb Of Tutankhamen?",
            a1: "Valley Of The Kings",
            a2: "Meidum",
            a3: "Giza",
            a4: "Dashur",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In which European city would you find Orly airport?",
            a1: "Paris",
            a2: "Prague",
            a3: "Madrid",
            a4: "Berlin",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Fissures, vents, and plugs are all associated with which geological feature?",
            a1: "Lakes",
            a2: "Mountains",
            a3: "Volcanos",
            a4: "Oceans",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which country consumes the most chocolate per capita?",
            a1: "France",
            a2: "UK",
            a3: "Sweden",
            a4: "Switzerland",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the world's biggest island?",
            a1: "Greenland",
            a2: "UK",
            a3: "Australia",
            a4: "Iceland",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In which city was Anne Frank's hiding place?",
            a1: "Warsaw",
            a2: "Krakok",
            a3: "Amsterdam",
            a4: "Budapest",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the Earth’s largest ocean?",
            a1: "The Pacific Ocean",
            a2: "Southern Ocean",
            a3: "Indian Ocean",
            a4: "Atlantic Ocean",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which country produces the most coffee in the world?",
            a1: "Colombia",
            a2: "Brazil",
            a3: "Ethiopia",
            a4: "Honduras",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which two countries share the longest international border?",
            a1: "Argentina and Chile",
            a2: "Russia and Kazakhstan",
            a3: "China and Mongolia",
            a4: "USA and Canada",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which US city is known as the City of Brotherly Love?",
            a1: "Memphis",
            a2: "Philadelphia",
            a3: "Chicago",
            a4: "New Orleans",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Europe is separated from Africa by which sea?",
            a1: "Norwegian Sea",
            a2: "Caribbean Sea",
            a3: "Mediterranean Sea",
            a4: "Arabian Sea",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Canberra is the capital city of which country?",
            a1: "Australia",
            a2: "India",
            a3: "Namibia",
            a4: "Uruguay",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Nepal is located on which continent?",
            a1: "Australia",
            a2: "Europe",
            a3: "Asia",
            a4: "Africa",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What does a seismologist study?",
            a1: "Earthquakes",
            a2: "Volcanos",
            a3: "Radiation",
            a4: "Waves",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which sea is located in Israel and Jordan?",
            a1: "Dead Sea",
            a2: "Arabian Sea",
            a3: "Ionian Sea",
            a4: "Caribbean Sea",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In what city did Princess Diana suffer her fatal car crash?",
            a1: "Paris",
            a2: "Bern",
            a3: "Lisbon",
            a4: "Seville",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What grows from an acorn?",
            a1: "Giant Sequia Tree",
            a2: "European Ash Tree",
            a3: "Ginkgo Tree",
            a4: "Oak Tree",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What currency is used in South Africa?",
            a1: "Lira",
            a2: "Dollars",
            a3: "Pounds",
            a4: "Rand",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What country is bordered by the Czech Republic, Slovakia, and Ukraine?",
            a1: "Iran",
            a2: "Romania",
            a3: "Germany",
            a4: "Bulgaria",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which natural disaster is measured with a Richter scale?",
            a1: "Floods",
            a2: "Volcano Eruptions",
            a3: "Hurricanes",
            a4: "Earthquakes",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In which US city is Broadway located?",
            a1: "New York City",
            a2: "Tucson",
            a3: "Las Vegas",
            a4: "Salk Lake City",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the thin and long country that spans more than half of the western coast of South America?",
            a1: "Chile",
            a2: "Brazil",
            a3: "Venezuela",
            a4: "Ecuador",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which American state is the largest by area?",
            a1: "Nevada",
            a2: "Alaska",
            a3: "Arizona",
            a4: "Texas",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which of the Seven Wonders is located in Egypt?",
            a1: "Temple of Artemis",
            a2: "The Pyramids of Giza",
            a3: "Hanging Gardens of Babylon",
            a4: "Statue of Zeus",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of New Zealand?",
            a1: "London",
            a2: "Jakarta",
            a3: "Wellington",
            a4: "Canberra",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which city in India would you find the Taj Mahal in?",
            a1: "Agra",
            a2: "Kolkata",
            a3: "Mumbai",
            a4: "Jaipur",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Mahatma Gandi's birthday is a national holiday in which country?",
            a1: "Bangladesh",
            a2: "India",
            a3: "Pakistan",
            a4: "Sri Lanka",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of China?",
            a1: "Yaounde",
            a2: "Kabul",
            a3: "Yamoussoukro",
            a4: "Beijing",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of England?",
            a1: "Tunis",
            a2: "Asmara",
            a3: "Tarawa",
            a4: "London",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Germany?",
            a1: "Berlin",
            a2: "Libreville",
            a3: "Copenhagen",
            a4: "Windhoek",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Iceland?",
            a1: "Reykjavik",
            a2: "Palikir",
            a3: "Tirana",
            a4: "Cardiff",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Ireland?",
            a1: "Cardiff",
            a2: "Palikir",
            a3: "Dublin",
            a4: "Kuala Lumpur",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Italy?",
            a1: "Libreville",
            a2: "Rome",
            a3: "Windhoek",
            a4: "Cairo",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Japan?",
            a1: "Porto Novo",
            a2: "Tokyo",
            a3: "Palikir",
            a4: "Monaco",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Netherlands?",
            a1: "Kuala Lumpur",
            a2: "Amsterdam",
            a3: "Bamako",
            a4: "Manila",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Russia?",
            a1: "Asuncion",
            a2: "Taipei",
            a3: "Moscow",
            a4: "Modadishu",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Spain?",
            a1: "Kingstown",
            a2: "Madrid",
            a3: "Funafuti",
            a4: "Islamabad",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of Thailand?",
            a1: "Doha",
            a2: "Hanoi",
            a3: "Monrovia",
            a4: "Bangkok",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of United Arab Emirates?",
            a1: "Abu Dhabi",
            a2: "Khartoum",
            a3: "Dodoma",
            a4: "Caracas",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of United Kingdom?",
            a1: "London",
            a2: "Asuncion",
            a3: "Dodoma",
            a4: "Male",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the capital of United States?",
            a1: "Ankara",
            a2: "Washington D.C",
            a3: "Doha",
            a4: "New Delhi",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the smallest country in the world?",
            a1: "Monaco",
            a2: "Vatican City",
            a3: "Nauru",
            a4: "Tuvalu",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In which country would you find the Leaning Tower of Pisa?",
            a1: "Italy",
            a2: "Croatia",
            a3: "Russia",
            a4: "Greece",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is colder: The North Pole or the South Pole?",
            a1: "The North Pole",
            a2: "The South Pole",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "The United States consists of how many states?",
            a1: "52",
            a2: "54",
            a3: "48",
            a4: "50",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the largest waterfall in the world?",
            a1: "Yosemite Falls",
            a2: "Niagara Falls",
            a3: "Victoria Falls",
            a4: "Iguazu Falls",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the hottest continent on Earth?",
            a1: "North America",
            a2: "Europe",
            a3: "Africa",
            a4: "Asia",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the highest mountain in the world?",
            a1: "Mount Everest",
            a2: "K2",
            a3: "Kilimanjaro",
            a4: "Makalu",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the coldest place on Earth?",
            a1: "Argentina",
            a2: "Antarctica",
            a3: "Chile",
            a4: "Arctic Circle",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What do you call a chain of mountains?",
            a1: "Range",
            a2: "Province",
            a3: "Span",
            a4: "Array",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the largest lake in north America?",
            a1: "Lake Huron",
            a2: "Lake Michigan",
            a3: "Lake Superior",
            a4: "Lake Saint Claire",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What do you call when two water streams join?",
            a1: "Conflux",
            a2: "Confluence",
            a3: "Meeting",
            a4: "Junction",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which river flows through the rainforest in brazil?",
            a1: "Thames",
            a2: "Ganges",
            a3: "Amazon",
            a4: "Nile",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which continent does the river Ganges flow through?",
            a1: "Africa",
            a2: "South America",
            a3: "North America",
            a4: "Asia",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What do you call land with water on 3 sides?",
            a1: "Archipelago",
            a2: "Peninsula",
            a3: "Isthmus",
            a4: "Island",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Where are the Andes mountains located?",
            a1: "Africa",
            a2: "North America",
            a3: "Europe",
            a4: "South America",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which hemisphere does Australia lie on?",
            a1: "Eastern",
            a2: "Western",
            a3: "Southern",
            a4: "Northern",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "From whom did the USA purchase Alaska from?",
            a1: "Russia",
            a2: "UK",
            a3: "China",
            a4: "Canada",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the supercontinent that existed 200 million years ago?",
            a1: "Pangea",
            a2: "Ultima",
            a3: "Dinosauric",
            a4: "Pacific",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Tallest mountain in Great Britain?",
            a1: "Ben Nevis",
            a2: "Snowden",
            a3: "Scafell Pike",
            a4: "Beenkeragh",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which countries have pyramids older than egypt?",
            a1: "Angola",
            a2: "Sudan",
            a3: "South Africa",
            a4: "Botswana",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which river forms part of the boundary between Mexico and the United States?",
            a1: "River Grande",
            a2: "Red River",
            a3: "Arkansas River",
            a4: "Colorado River",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In which American city is the Golden Gate Bridge located?",
            a1: "New York City",
            a2: "San Francisco",
            a3: "Chicago",
            a4: "Texas",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In which country would you find Mount Kilimanjaro?",
            a1: "Tanzania",
            a2: "Australia",
            a3: "USA",
            a4: "Brazil",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the official language of Brazil?",
            a1: "Portuguese",
            a2: "English",
            a3: "Spanish",
            a4: "Russian",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the only country with a coastline on both the Red Sea and the Persian Gulf?",
            a1: "Afghanistan",
            a2: "Mongolia",
            a3: "Qatar",
            a4: "Saudi Arabia",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the smallest country in Asia by population?",
            a1: "Qatar",
            a2: "Armenia",
            a3: "Bhutan",
            a4: "Maldives",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which two continents does Russia belong to?",
            a1: "Europe and North America",
            a2: "Europe and Africa",
            a3: "Asia and Africa",
            a4: "Europe and Asia",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the currency used in Britain?",
            a1: "Pound Sterling",
            a2: "Bitcoin",
            a3: "Euro",
            a4: "US Dollar",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the currency used in USA?",
            a1: "Rand",
            a2: "Pound Sterling",
            a3: "US Dollar",
            a4: "Euro",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which country is called the Land of Rising Sun?",
            a1: "Japan",
            a2: "Iraq",
            a3: "Iran",
            a4: "China",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which European country shares its border with the most neighbors?",
            a1: "Austria",
            a2: "Germany",
            a3: "France",
            a4: "Netherlands",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which country is Taj Mahal in?",
            a1: "China",
            a2: "Bangladesh",
            a3: "India",
            a4: "Pakistan",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Where does the Colorado river flow through?",
            a1: "Arcadia Park",
            a2: "Yellowstone Park",
            a3: "Grand Canyon",
            a4: "Great Smokey Mountains Park",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which country lies directlyvabove mexico?",
            a1: "Brazil",
            a2: "Ecuador",
            a3: "Canada",
            a4: "USA",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What kind of boat goes through canals?",
            a1: "Deck Boats",
            a2: "Fishing Boats",
            a3: "Barges",
            a4: "Catamarans",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Where is K2 mountain located?",
            a1: "Between Pakistan and India",
            a2: "Between China and Pakistan",
            a3: "between Thailand and Laos",
            a4: "Between Iran and Iraq",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the world's biggest port in 2020?",
            a1: "Shanghai",
            a2: "Dubai",
            a3: "Antwerp",
            a4: "Singapore",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which country is the leading egg producer in the world in 2020?",
            a1: "Netherlands",
            a2: "China",
            a3: "USA",
            a4: "India",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which country was once known as Rhodesia?",
            a1: "Belgium",
            a2: "Indonesia",
            a3: "Zimbabwe",
            a4: "Malaysia",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the official language of Australia?",
            a1: "Greek",
            a2: "Russian",
            a3: "English",
            a4: "German",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which city is also known as 'The Eternal City'?",
            a1: "Las Vegas",
            a2: "Seattle",
            a3: "Amsterdam",
            a4: "Rome",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which South American country has land borders with ten other countries?",
            a1: "Chile",
            a2: "Brazil",
            a3: "Paraguay",
            a4: "Ecuador",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which ocean lies between Europe and America?",
            a1: "Indian Ocean",
            a2: "Atlantic Ocean",
            a3: "Pacific Ocean",
            a4: "Arctic Ocean",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the currency used in Japan?",
            a1: "Rand",
            a2: "Pounds",
            a3: "Dollars",
            a4: "Yen",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Where is the biggest railway station in the world?",
            a1: "Moscow",
            a2: "New York City",
            a3: "Berlin",
            a4: "London",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which European country is closest to the continent of Africa?",
            a1: "Cyprus",
            a2: "Greece",
            a3: "France",
            a4: "Spain",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Mount Everest lies in which mountain range?",
            a1: "Ural",
            a2: "Andes",
            a3: "Himalayas",
            a4: "Alps",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the biggest country in Africa continent?",
            a1: "Eqypt",
            a2: "South Africa",
            a3: "Sudan",
            a4: "Algeria",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Where is Mount Ararat located?",
            a1: "Brazil",
            a2: "USA",
            a3: "China",
            a4: "Turkey",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "How many time zones does India have?",
            a1: "3",
            a2: "4",
            a3: "2",
            a4: "5",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Where is the Rotterdam port located?",
            a1: "South America",
            a2: "Europe",
            a3: "Africa",
            a4: "Asia",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "WHich City has the highest annual rainfall recorded until 2019?",
            a1: "Moscow, Russia",
            a2: "London, UK",
            a3: "Mawsynram, India",
            a4: "Seattle, USA",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which is the largest city in ireland?",
            a1: "Dublin",
            a2: "Galway",
            a3: "Cork",
            a4: "Limerick",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which Italian city is famous for its canals?",
            a1: "Milan",
            a2: "Pisa",
            a3: "Rome",
            a4: "Venice",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In which U.S. city would you find Manhattan, Brooklyn and the Bronx?",
            a1: "Los Angeles",
            a2: "Miami",
            a3: "Dallas",
            a4: "New York City",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In what country would you find the Eiffel Tower?",
            a1: "Belgium",
            a2: "UK",
            a3: "Italy",
            a4: "France",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "In what country would you find the Great Pyramids of Giza?",
            a1: "Algeria",
            a2: "Mexico",
            a3: "Eqypt",
            a4: "China",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Which large river flows through London?",
            a1: "Nile",
            a2: "Danube",
            a3: "Colorado",
            a4: "Thames",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "What is the largest continent?",
            a1: "Europe",
            a2: "Asia",
            a3: "South America",
            a4: "North America",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_EASY,
            q: "Madagascar is surrounded by which ocean?",
            a1: "Indian",
            a2: "Pacific",
            a3: "Arctic",
            a4: "Atlantic",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which city is the capital of Switzerland?",
            a1: "Bern",
            a2: "Zürich",
            a3: "Wien",
            a4: "Frankfurt",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What are the four corner states of the US?",
            a1: "Utah, Colorado, Arizona, New Mexico",
            a2: "Oregon, Idaho, Nevada, Utah",
            a3: "South Dakota, Minnesota, Nebraska, Iowa",
            a4: "Kansas, Oklahoma, Arkansas, Louisiana",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Israel is 7 hours ahead of New York?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following language families is the most controversial amongst modern linguists?",
            a1: "Altaic",
            a2: "Indo-European",
            a3: "Dravidian",
            a4: "Sino-Tibetan",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following countries banned the use of personal genetic ancestry tests?",
            a1: "Austria",
            a2: "Germany",
            a3: "Sweden",
            a4: "Canada",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "All of the following countries have official claims to territory in Antartica EXCEPT:?",
            a1: "United States",
            a2: "Norway",
            a3: "Chile",
            a4: "Australia",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "The World Health Organization headquarters is located in which European country?",
            a1: "France",
            a2: "Switzerland",
            a3: "Belgium",
            a4: "United Kingdom",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of these is the name of the largest city in the US state Tennessee?",
            a1: "Memphis",
            a2: "Thebes",
            a3: "Luxor",
            a4: "Alexandria",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Where are the Nazca Lines located?",
            a1: "Colombia",
            a2: "Brazil",
            a3: "Peru",
            a4: "Ecuador",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the capital of Senegal?",
            a1: "Monrovia",
            a2: "Nouakchott",
            a3: "Dakar",
            a4: "Conakry",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which country has three capital cities?",
            a1: "South Africa",
            a2: "Somalia",
            a3: "China",
            a4: "United Kingdom",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "The towns of Brugelette, Arlon and Ath are located in which country?",
            a1: "Andorra",
            a2: "Luxembourg",
            a3: "France",
            a4: "Belgium",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "The Space Needle is located in which city?",
            a1: "Seattle",
            a2: "Los Angles",
            a3: "Toronto",
            a4: "Vancouver",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the smallest country in South America by area?",
            a1: "Uruguay",
            a2: "Brazil",
            a3: "Suriname",
            a4: "Chile",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the largest Muslim country in the world?",
            a1: "Pakistan",
            a2: "Saudi Arabia",
            a3: "Indonesia",
            a4: "Iran",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the busiest port in Europe?",
            a1: "Port of Amsterdam",
            a2: "Port of Rotterdam",
            a3: "Port of Antwerp",
            a4: "Port of Hamburg",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which Canadian province has Charlottetown as its capital?",
            a1: "Ontario",
            a2: "Saskachewan",
            a3: "Prince Edward Island",
            a4: "Northwest Terrirories",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the most populous city in the Americas in 2015?",
            a1: "Mexico City",
            a2: "New York",
            a3: "São Paulo",
            a4: "Los Angeles",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Broome is a town in which state of Australia?",
            a1: "South Australia",
            a2: "Northern Territory",
            a3: "Western Australia",
            a4: "Tasmania",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the capital city of New Zealand?",
            a1: "Christchurch",
            a2: "Wellington",
            a3: "Auckland",
            a4: "Melbourne",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "How many independent countries are there within the continent of South America?",
            a1: "8",
            a2: "9",
            a3: "10",
            a4: "12",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which is the world's longest river?",
            a1: "Yangtze",
            a2: "Amazon",
            a3: "Missouri",
            a4: "Nile",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Bridgetown is the capital of which island country in the Carribean?",
            a1: "Jamaica‎",
            a2: "Cuba",
            a3: "Dominica",
            a4: "Barbados",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of the only remaining Grand Duchy in the world?",
            a1: "Liechtenstein",
            a2: "Andorra",
            a3: "Montenegro",
            a4: "Luxembourg",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "How many time zones are in Russia?",
            a1: "2",
            a2: "8",
            a3: "11",
            a4: "5",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following is not a megadiverse country - one that harbors a high number of the earth's endemic species?",
            a1: "Thailand",
            a2: "South Africa",
            a3: "Mexico",
            a4: "Peru",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the African nation of Zimbabwe formerly known as?",
            a1: "Bulawayo",
            a2: "Mozambique",
            a3: "Zambia",
            a4: "Rhodesia",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What European country is not a part of the EU?",
            a1: "Czechia",
            a2: "Norway",
            a3: "Ireland",
            a4: "Lithuania",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "On a London Underground map, what colour is the Circle Line?",
            a1: "Yellow",
            a2: "Red",
            a3: "Green",
            a4: "Blue",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "The surface area of Russia is slightly larger than that of the dwarf planet Pluto?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "There is a city called Rome in every continent on Earth?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "The longest place named in the United States is Lake Chargoggagoggmanchauggagoggchaubunagungamaugg, located near Webster, MA?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What African country has Portuguese as its official language?",
            a1: "Botswana",
            a2: "Gabon",
            a3: "Mozambique",
            a4: "Togo",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What name was historically used for the Turkish city currently known as Istanbul?",
            a1: "Soot",
            a2: "Adrianople",
            a3: "Constantinople",
            a4: "Hüdavendigar",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "'Mongolia' was a part of the now non-existent U.S.S.R?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the official language of Bhutan?",
            a1: "Groma",
            a2: "Karen",
            a3: "Dzongkha",
            a4: "Ladakhi",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is Canada's smallest province?",
            a1: "New Brunswick",
            a2: "Nova Scotia",
            a3: "Prince Edward Island",
            a4: "Yukon",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What's the first National Park designated in the United States?",
            a1: "Rocky Mountain",
            a2: "Sequoia",
            a3: "Yellowstone",
            a4: "Yosemite",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the capital of Estonia?",
            a1: "Helsinki",
            a2: "Tartu",
            a3: "Riga",
            a4: "Tallinn",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of these country's capitals starts with the letter B?",
            a1: "Lebanon",
            a2: "Qatar",
            a3: "Kuwait",
            a4: "Jordan",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "How many countries share a land border with Luxembourg?",
            a1: "3",
            a2: "5",
            a3: "4",
            a4: "2",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which English county will you find the University of East Anglia?",
            a1: "Suffolk",
            a2: "Norfolk",
            a3: "Cambridgeshire",
            a4: "Essex",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "The title of the 1969 film 'Krakatoa, East_of Java' is incorrect, as Krakatoa is in fact west of Java?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Frankenmuth, a US city nicknamed 'Little Bavaria', is located in what state?",
            a1: "Michigan",
            a2: "Virginia",
            a3: "Kentucky",
            a4: "Pennsylvania",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "The Pyrenees mountains are located on the border of which two countries?",
            a1: "Russia and Ukraine",
            a2: "Norway and Sweden",
            a3: "Italy and Switzerland",
            a4: "France and Spain",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which country claims ownership of the disputed state Kosovo?",
            a1: "Croatia",
            a2: "Albania",
            a3: "Macedonia",
            a4: "Serbia",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which two modern-day countries used to be known as the region of Rhodesia between the 1890s and 1980?",
            a1: "Togo & Benin",
            a2: "Lesotho & Swaziland",
            a3: "Zambia & Zimbabwe",
            a4: "Rwanda & Burundi",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "The Principality of Sealand is an unrecognized micronation off the coast of what country?",
            a1: "Argentina",
            a2: "Austrailia",
            a3: "Japan",
            a4: "The United Kingdom",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "How many sovereign states are members of the United Nations?",
            a1: "178",
            a2: "195",
            a3: "201",
            a4: "153",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which is the largest city in Morocco?",
            a1: "Sale",
            a2: "Casablanca",
            a3: "Rabat",
            a4: "Fes",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which of these island countries is located in the Caribbean?",
            a1: "Maldives",
            a2: "Barbados",
            a3: "Seychelles",
            a4: "Fiji",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which of these is NOT a city in India?",
            a1: "Ghaziabad",
            a2: "Hyderabad",
            a3: "Islamabad",
            a4: "Ahmedabad",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which country is completely landlocked by South Africa?",
            a1: "Botswana",
            a2: "Lesotho",
            a3: "Swaziland",
            a4: "Zimbabwe",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which of these is NOT a province in China?",
            a1: "Yangtze",
            a2: "Guangdong",
            a3: "Fujian",
            a4: "Sichuan",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following European languages is classified as a 'language isolate?'?",
            a1: "Galician",
            a2: "Basque",
            a3: "Maltese",
            a4: "Hungarian",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "The derisive acronym 'PIIGS' refers to which of the following European countries and their economic statuses?",
            a1: "Portugal, Iceland, Ireland, Greece, Serbia",
            a2: "Poland, Iceland, Italy, Greece, Serbia",
            a3: "Poland, Iceland, Italy, Greenland, Spain",
            a4: "Portugal, Ireland, Italy, Greece, Spain",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following Arab countries does NOT have a flag containing only Pan-Arab colours?",
            a1: "Jordan",
            a2: "United Arab Emirates",
            a3: "Qatar",
            a4: "Kuwait",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Where is the fast food chain 'Panda Express' headquartered?",
            a1: "San Diego, California",
            a2: "Sacramento, California",
            a3: "Rosemead, California",
            a4: "Fresno, California",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "St. Louis is the capital of the US State Missouri?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Switzerland has four national languages, English being one of them?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the name of the formerly rich fishing grounds off the island of Newfoundland, Canada?",
            a1: "Grand Banks",
            a2: "Hudson Bay",
            a3: "Great Barrier Reef",
            a4: "Mariana Trench",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which of these cities has a 4° East longitude?",
            a1: "Rio de Janero",
            a2: "Amsterdam",
            a3: "Toronto",
            a4: "Hong Kong",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "The emblem on the flag of the Republic of Tajikistan features a sunrise over mountains below what symbol?",
            a1: "Crown",
            a2: "Sickle",
            a3: "Bird",
            a4: "Tree",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "The Maluku islands (informally known as the Spice Islands) belong to which country?",
            a1: "Chile",
            a2: "New Zealand",
            a3: "Indonesia",
            a4: "Fiji",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the capital of Wisconsin, USA?",
            a1: "Milwaukee",
            a2: "Wisconsin Dells",
            a3: "Madison",
            a4: "Green Bay",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which of these is NOT a real tectonic plate?",
            a1: "North American Plate",
            a2: "Nazca Plate",
            a3: "Atlantic Plate",
            a4: "Eurasian Plate",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What country is not a part of Scandinavia?",
            a1: "Finland",
            a2: "Denmark",
            a3: "Norway",
            a4: "Sweden",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the largest city and commercial capital of Sri Lanka?",
            a1: "Moratuwa",
            a2: "Kandy",
            a3: "Negombo",
            a4: "Colombo",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "The mountainous Khyber Pass connects which of the two following countries?",
            a1: "Afghanistan and Pakistan",
            a2: "Pakistan and India",
            a3: "India and Nepal",
            a4: "Tajikistan and Kyrgyzstan",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What year is on the flag of the US state Wisconsin?",
            a1: "1634",
            a2: "1901",
            a3: "1783",
            a4: "1848",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which is the largest freshwater lake in the world?",
            a1: "Lake Michigan",
            a2: "Lake Superior",
            a3: "Lake Huron",
            a4: "Caspian Sea",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which country was NOT formerly part of Yugoslavia?",
            a1: "Albania",
            a2: "Croatia",
            a3: "Serbia",
            a4: "Macedonia",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the official German name of the Swiss Federal Railways?",
            a1: "Schweizerische Nationalbahnen",
            a2: "Schweizerische Bundesbahnen",
            a3: "Schweizerische Staatsbahnen",
            a4: "Bundesbahnen der Schweiz",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What North American tourist attraction is served by the 'Maid of the Mist' tour company?",
            a1: "Whistler, British Columbia",
            a2: "Yosemite National Park",
            a3: "Niagara Falls",
            a4: "Disney World",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the Polish city known to Germans as Danzig?",
            a1: "Warsaw",
            a2: "Pozna",
            a3: "Zakopane",
            a4: "Gdansk",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the second-largest city in Lithuania?",
            a1: "Panevezys",
            a2: "Kaunas",
            a3: "Vilnius",
            a4: "Klaipeda",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "The Andaman and Nicobar Islands in South East Asia are controlled by which country?",
            a1: "India",
            a2: "Thailand",
            a3: "Vietnam",
            a4: "Indonesia",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "How many stations does the Central Line have on the London Underground?",
            a1: "49",
            a2: "43",
            a3: "47",
            a4: "51",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "The two largest ethnic groups of Belgium are Flemish and Walloon?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "How many federal states does Germany have?",
            a1: "32",
            a2: "25",
            a3: "16",
            a4: "13",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "All of the following are classified as Finno-Ugric languages EXCEPT:?",
            a1: "Hungarian",
            a2: "Finnish",
            a3: "Samoyedic",
            a4: "Estonian",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "In 2012 the German-speaking microstate 'Liechtenstein' in Central Europe had a population of how many inhabitants?",
            a1: "2,400",
            a2: "90,000",
            a3: "323,400",
            a4: "36,600",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What city is known as the Rose Capital of the World?",
            a1: "Anaheim, California",
            a2: "San Diego, California",
            a3: "Tyler, Texas",
            a4: "Miami, Florida",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the tallest mountain in Canada?",
            a1: "Blue Mountain",
            a2: "Whistler Mountain",
            a3: "Mont Tremblant",
            a4: "Mount Logan",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Into which basin does the Jordan River flow into?",
            a1: "Caspian Sea",
            a2: "Salton Sea",
            a3: "Dead Sea",
            a4: "Aral Sea",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the largest freshwater lake by volume?",
            a1: "Lake Huron",
            a2: "Lake Superior",
            a3: "Lake Baikal",
            a4: "Lake Michigan",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the name of one of the Neo-Aramaic languages spoken by the Jewish population from Northwestern Iraq?",
            a1: "Lishana Deni",
            a2: "Lishan Didan",
            a3: "Chaldean Neo-Aramaic",
            a4: "Hulaulá",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "How many countries does Mexico border?",
            a1: "3",
            a2: "2",
            a3: "1",
            a4: "4",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Bir Tawil, an uninhabited track of land claimed by no country, is located along the border of which two countries?",
            a1: "Iraq and Saudi Arabia",
            a2: "Chad and Libya",
            a3: "Israel and Jordan",
            a4: "Egypt and Sudan",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Llanfair­pwllgwyngyll­gogery­chwyrn­drobwll­llan­tysilio­gogo­goch is located on which Welsh island?",
            a1: "Bardsey",
            a2: "Anglesey",
            a3: "Caldey",
            a4: "Barry",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Fucking is a village in which country?",
            a1: "Switzerland",
            a2: "Czech Republic",
            a3: "Germany",
            a4: "Austria",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the land connecting North America and South America?",
            a1: "Isthmus of Suez",
            a2: "Isthmus of Panama",
            a3: "Australasia",
            a4: "Urals",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the most common climbing route for the second highest mountain in the world, K2?",
            a1: "Magic Line",
            a2: "Abruzzi Spur",
            a3: "Cesen Route",
            a4: "Polish Line",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which of these cities is NOT in England?",
            a1: "Southampton",
            a2: "Edinburgh",
            a3: "Oxford",
            a4: "Manchester",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Only one country in the world starts with the letter Q?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the only state in the United States that does not have a flag in a shape with 4 edges?",
            a1: "Florida",
            a2: "New Mexico",
            a3: "Idaho",
            a4: "Ohio",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Harvard University is located in which city?",
            a1: "Providence",
            a2: "Washington D.C",
            a3: "New York",
            a4: "Cambridge",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which country is the home of the largest Japanese population outside of Japan?",
            a1: "Brazil",
            a2: "The United States",
            a3: "China",
            a4: "Russia",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "What is the Finnish word for 'Finland'?",
            a1: "Magyarország",
            a2: "Eesti",
            a3: "Suomi",
            a4: "Sverige",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_GEOG,
            d: c.DIFFICULTY_HARD,
            q: "Which country is the Taedong River in?",
            a1: "North Korea",
            a2: "China",
            a3: "Japan",
            a4: "South Korea",
            a: 1,
            seen: false
        }
]
    