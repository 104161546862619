
const {c} = require('./consts');

export let sport_questions = [
     
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "How many soccer players should be on the field at the same time?",
            a1: "24",
            a2: "20",
            a3: "22",
            a4: "26",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of Manchester United's home stadium?",
            a1: "Old Trafford",
            a2: "City of Manchester Stadium",
            a3: "St James Park",
            a4: "Anfield",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Formula 1 driver was the first to win 7 world championships?",
            a1: "Jim Clark",
            a2: "Ayrton Senna",
            a3: "Fernando Alonso",
            a4: "Michael Schumacher",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In Baseball, how many times does the ball have to be pitched outside of the strike zone before the batter is walked?",
            a1: "2",
            a2: "3",
            a3: "1",
            a4: "4",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In golf, what name is given to a hole score of two under par?",
            a1: "Eagle",
            a2: "Birdie",
            a3: "Bogey",
            a4: "Albatross",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In what sport is a 'shuttlecock' used?",
            a1: "Badminton",
            a2: "Cricket",
            a3: "Rugby",
            a4: "Table Tennis",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which country hosted the 2020 Summer Olympics?",
            a1: "Japan",
            a2: "China",
            a3: "Australia",
            a4: "Germany",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which country has hosted the 2018 FIFA World Cup?",
            a1: "United States",
            a2: "Saudi Arabia",
            a3: "Russia",
            a4: "Germany",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Who won the 2015 Formula 1 World Championship?",
            a1: "Lewis Hamilton",
            a2: "Nico Rosberg",
            a3: "Sebastian Vettel",
            a4: "Jenson Button",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Roger Federer is a famous soccer player?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What team did England beat to win in the 1966 World Cup final?",
            a1: "Brazil",
            a2: "Portugal",
            a3: "Soviet Union",
            a4: "West Germany",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Who did Steven Gerrard win the Champions League with?",
            a1: "Chelsea",
            a2: "Real Madrid",
            a3: "Man City",
            a4: "Liverpool",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following sports is not part of the triathlon?",
            a1: "Running",
            a2: "Horse-Riding",
            a3: "Swimming",
            a4: "Cycling",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In association football, or soccer, a corner kick is when the game restarts after someone scores a goal?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which country hosted the 2022 FIFA World Cup?",
            a1: "USA",
            a2: "Japan",
            a3: "Qatar",
            a4: "Switzerland",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which boxer was banned for taking a bite out of Evander Holyfield's ear in 1997?",
            a1: "Mike Tyson",
            a2: "Lennox Lewis",
            a3: "Evander Holyfield",
            a4: "Roy Jones Jr",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In Bowling What Is The Term For Knocking Down All Ten Pins With 2 Consecutive Balls?",
            a1: "Strike",
            a2: "Ace",
            a3: "Flush",
            a4: "Spare",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Country Won The World Cup First Germany Or Brazil?",
            a1: "Brazil",
            a2: "Germany",
            
            
            a: 2,
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Sport Is Played With The Heaviest Ball?",
            an: "Ten Pin Bowling",
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In Snooker, Which Colour Ball Is Not Placed Back On The Table If It Is Illegally Potted?",
            a1: "Red",
            a2: "Pink",
            a3: "Black",
            a4: "Blue",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "This sport is called the 'American pastime'?",
            a1: "Baseball",
            a2: "Basketball",
            a3: "Soccer",
            a4: "Hockey",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Where did The Game Of Polo Originate?",
            a1: "Persia",
            a2: "Argentina",
            a3: "India",
            a4: "UK",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "How Many Points Is The Yellow Ball Worth In Snooker?",
            a1: "One",
            a2: "Four",
            a3: "Seven",
            a4: "Two",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In sport, what do the initials PGA stand for?",
            a1: "Professional Grass Academy",
            a2: "Premier Grass Academy",
            a3: "Professional Golfer's Association",
            a4: "Paid Golfer's  Alliance",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What Is The Name Of The (War Dance) The All Blacks Perform Before A Match?",
            a1: "The Saka",
            a2: "The Haka",
            a3: "The Jacka",
            a4: "The Macka",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In 1986 Which Boxer Became The Youngest Ever World Heavyweight Champion?",
            a1: "Lennox Lewis",
            a2: "Joe Frazier",
            a3: "Dillian Whyte",
            a4: "Mike Tyson",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Sport Has The Davis Cup?",
            a1: "Rugby",
            a2: "Football",
            a3: "Tennis",
            a4: "Hockey",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Football Team Play Their Home Games At Stamford Bridge?",
            a1: "Aston Villa",
            a2: "Crystal Palace",
            a3: "Chelsea",
            a4: "Fulham",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What Country Was The First To Win The World Cup?",
            a1: "Uruguay",
            a2: "Argentina",
            a3: "Chile",
            a4: "Brazil",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Golfer Won Over $9 Million Prize Money In 2000?",
            a1: "Tiger Woods",
            a2: "Rory McIlroy",
            a3: "Ben Hogan",
            a4: "Gary Player",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Sportswear Company Is The Brand With The Three Stripes?",
            a1: "Adidas",
            a2: "Reebok",
            a3: "Puma",
            a4: "Nike",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What Colour Is The 8 Ball In A Game Of Pool?",
            a1: "White",
            a2: "Red",
            a3: "Black",
            a4: "Yellow",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In which sport is a 'hole in one' possible?",
            a1: "Golf",
            a2: "Archery",
            a3: "Tennis",
            a4: "Squash",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "How Many Numbers Are There On A Roulette Wheel?",
            a1: "28",
            a2: "31",
            a3: "37",
            a4: "42",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Sport Was Featured In The Movie 'When We Were Kings'?",
            a1: "Boxing",
            a2: "Weightlifting",
            a3: "Powerlifting",
            a4: "MMA",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In Which City Does The Cycle Race The Tour De France Finish?",
            a1: "Amsterdam",
            a2: "Milan",
            a3: "London",
            a4: "Paris",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What type of racing has only two cars competing on the track at the same time?",
            a1: "NASCAR",
            a2: "Rally Driving",
            a3: "Formula 1",
            a4: "Drag Racing",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which weight division in boxing lies between flyweight and featherweight?",
            a1: "Heavy Lightweight",
            a2: "Batamweight",
            a3: "Heavyweight",
            a4: "Lightweight",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In Which Sport Do Players Sweep Ice?",
            a1: "Bowling",
            a2: "Ice Polo",
            a3: "Curling",
            a4: "Ice Hockey",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "If A Cricket Umpire Raises His Arms Above His Head How Many Runs Is He Signalling?",
            a1: "8",
            a2: "6",
            a3: "4",
            a4: "10",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which football team are nicknamed the Swans?",
            a1: "Brentford",
            a2: "Swansea",
            a3: "Nottingham Forest",
            a4: "Crystal Palace",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What do runners pass to each other in a relay race?",
            a1: "Carton",
            a2: "Baton",
            a3: "Wand",
            a4: "Mace",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Japanese Martial Art Uses Bamboo Swords?",
            a1: "Aikido",
            a2: "Judo",
            a3: "Jiu-Jitsu",
            a4: "Kendo",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What numbers are by the side of the number 16 on a dart board?",
            a1: "10 and 13",
            a2: "1 and 5",
            a3: "8 and 7",
            a4: "14 and 12",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In which sport is the term 'love' used?",
            a1: "Tennis",
            a2: "Rugby",
            a3: "Skiing",
            a4: "Football",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which is the only position in soccer allowed to handle the ball?",
            a1: "Defender",
            a2: "Winger",
            a3: "Goalkeeper",
            a4: "Forward",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Sport Uses The Lightest Ball?",
            a1: "Squash",
            a2: "Badminton",
            a3: "Table Tennis",
            a4: "Racket Ball",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What is the international governing board of football (soccer)?",
            a1: "FIPA",
            a2: "FIDA",
            a3: "FILA",
            a4: "FIFA",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "With which sport is Pele associated?",
            a1: "Soccer",
            a2: "Swimming",
            a3: "Basketball",
            a4: "American Football",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What vehicles are involved in the 'Tour de France'?",
            a1: "Go Karts",
            a2: "Bicycles",
            a3: "Motorcyles",
            a4: "Cars",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "How many pockets on a standard snooker table?",
            a1: "10",
            a2: "6",
            a3: "8",
            a4: "4",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In Bingo, For What Is The Call 2 Fat Ladies?",
            a1: "11",
            a2: "33",
            a3: "88",
            a4: "44",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "With which sport is Muhammad Ali associated?",
            a1: "MMA",
            a2: "Boxing",
            a3: "Kung Fu",
            a4: "Baseball",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "How Many Points Are Awarded For A Touch Down In American Football?",
            a1: "4",
            a2: "6",
            a3: "3",
            a4: "5",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Is Longer A Baseball Bat Or A Tennis Racket?",
            a1: "A Baseball Bat",
            a2: "Tennis Racket",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In Tennis What Have You Achieved When You Score A Point Directly From A Serve?",
            a1: "Spare",
            a2: "Strike",
            a3: "Flush",
            a4: "Ace",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What is the misshapen ear that boxers often have called?",
            a1: "Cauliflower ear",
            a2: "Carrot Ear",
            a3: "Potato Ear",
            a4: "Brocoli Ear",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "England play their home rugby union matches at which venue?",
            a1: "Wembley",
            a2: "Principality",
            a3: "Murrayfield",
            a4: "Twickenham",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "The Colours of the All England Lawn Tennis Club are green and what else?",
            a1: "White",
            a2: "Yellow",
            a3: "Blue",
            a4: "Purple",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which sport is played by the Minnesota Twins?",
            a1: "Basketball",
            a2: "Baseball",
            a3: "Archery",
            a4: "Equestrian",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "How many games must you win to win a normal set in tennis?",
            a1: "Four",
            a2: "Six",
            a3: "Eight",
            a4: "Two",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Tennis star Serena Williams won which major tournament while pregnant with her first child?",
            a1: "The French Open",
            a2: "The US Open",
            a3: "Wimbledon",
            a4: "The Australian Open",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "When Michael Jordan played for the Chicago Bulls, how many NBA Championships did he win?",
            a1: "7",
            a2: "5",
            a3: "6",
            a4: "8",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What country won the very first FIFA World Cup in 1930?",
            a1: "Brazil",
            a2: "Chile",
            a3: "Argentina",
            a4: "Uruguay",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which NFL team holds the record for the most Super Bowl appearances as of 2022?",
            a1: "Dallas Cowboys",
            a2: "New England Patriots",
            a3: "Green Bay Packers",
            a4: "San Francisco 49ers",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Who was said to “float like a butterfly and sting like a bee”?",
            a1: "George Foreman",
            a2: "Mike Tyson",
            a3: "Floyd Mayweather Jr",
            a4: "Muhammed Ali",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Cirque du Soleil started in what country?",
            a1: "Rwanda",
            a2: "Canada",
            a3: "Mexico",
            a4: "Burundi",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What is an ice hockey puck made from?",
            a1: "Graphene",
            a2: "Kevlar",
            a3: "Clay",
            a4: "Rubber",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "How many sides does the home plate in baseball have?",
            a1: "5",
            a2: "6",
            a3: "4",
            a4: "7",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In which year did Usain Bolt set his 100m in 9.58 seconds world record?",
            a1: "2007",
            a2: "2009",
            a3: "2008",
            a4: "2006",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Williams sister has won more Grand Slam titles?",
            a1: "Serena",
            a2: "Venus",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which racer holds the record for the most Grand Prix wins in 2021?",
            a1: "Fernando Alonso",
            a2: "Michael Schumacher",
            a3: "Ayrton Senna",
            a4: "Lewis Hamilton",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Jamaican runner is an 11-time world champion and holds the record in the 100 and 200-meter race?",
            a1: "Usain Bolt",
            a2: "Brosdfwn",
            a3: "Brosdfwn",
            a4: "Brosdfwn",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which boxer was known as 'The Greatest' and 'The People's Champion'?",
            a1: "Tyson Gay",
            a2: "Yohan Blake",
            a3: "Muhammad Ali",
            a4: "Asafa Powell",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What does WWE stand for?",
            a1: "Wrestling World Entertainment",
            a2: "Wrestling Wide Entertainment",
            a3: "World Wide Entertainment",
            a4: "World Wrestling Entertainment",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What is Usain Bolt's blistering 100m world record time?",
            a1: "9.68 seconds",
            a2: "9.88 seconds",
            a3: "9.58 seconds",
            a4: "9.78 seconds",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In American Football, how many points do you score for a touchdown?",
            a1: "6",
            a2: "3",
            a3: "4",
            a4: "5",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "In which outdoor sport would you need a stick, a puck and a mouthguard?",
            a1: "Hockey",
            a2: "Rugby",
            a3: "Football",
            a4: "Football",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What is Muhammad Ali's real name?",
            a1: "Cassius Clay",
            a2: "Cassius Ray",
            a3: "Cassius Fray",
            a4: "Cassius Gray",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "How many balls are in total are there on the table at the start of a game of snooker?",
            a1: "22",
            a2: "21",
            a3: "20",
            a4: "23",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "Which Sport does Roger Federer play?",
            a1: "Volleyball",
            a2: "Badminton",
            a3: "Table Tennis",
            a4: "Tennis",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_EASY,
            q: "What do the 5 Rings in the Olympic Flag represent?",
            a1: "5 Sports Of The World",
            a2: "5 Countries Of The World",
            a3: "5 Cities Of The World",
            a4: "5 Continents Of The World",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of these teams isn't a member of the NHL's 'Original Six' era?",
            a1: "New York Rangers",
            a2: "Boston Bruins",
            a3: "Philadelphia Flyers",
            a4: "Toronto Maple Leafs",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the nickname of Northampton town's rugby union club?",
            a1: "Harlequins",
            a2: "Saints",
            a3: "Saracens",
            a4: "Wasps",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "A stimpmeter measures the speed of a ball over what surface?",
            a1: "Cricket Outfield",
            a2: "Golf Putting Green",
            a3: "Football Pitch",
            a4: "Pinball Table",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "The F1 season of 1994 is remembered for what tragic event?",
            a1: "The Showdown (Australia)",
            a2: "Death of Ayrton Senna (San Marino)",
            a3: "Schumacher's Ban (Britain)",
            a4: "Verstappen on Fire (Germany)",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Skateboarding will be included in the 2020 Summer Olympics in Tokyo?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the final score of the Germany vs. Brazil 2014 FIFA World Cup match?",
            a1: "3 - 4",
            a2: "7 - 1",
            a3: "0 - 1",
            a4: "16 - 0",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "In what sport does Fanny Chmelar compete for Germany?",
            a1: "Swimming",
            a2: "Gymnastics",
            a3: "Skiing",
            a4: "Showjumping",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who won the UEFA Champions League in 2017?",
            a1: "AS Monaco FC",
            a2: "Juventus F.C",
            a3: "Real Madrid C.F",
            a4: "Atletico Madrid",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "With which team did Michael Schumacher make his Formula One debut at the 1991 Belgian Grand Prix?",
            a1: "Jordan",
            a2: "Ferrari",
            a3: "Mercedes",
            a4: "Benetton",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "In 2016, who won the Formula 1 World Constructor's Championship for the third time in a row?",
            a1: "Scuderia Ferrari",
            a2: "McLaren Honda",
            a3: "Red Bull Racing Renault",
            a4: "Mercedes-AMG Petronas",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "In Formula 1, the Virtual Safety Car was introduced following the fatal crash of which driver?",
            a1: "Gilles Villeneuve",
            a2: "Ronald Ratzenberger",
            a3: "Ayrton Senna",
            a4: "Jules Bianchi",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who is often called 'the Maestro' in the men's tennis circuit?",
            a1: "Roger Federer",
            a2: "Boris Becker",
            a3: "Pete Sampras",
            a4: "Bill Tilden",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "How many premier league trophies did Sir Alex Ferguson win during his time at Manchester United?",
            a1: "11",
            a2: "22",
            a3: "13",
            a4: "20",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "In a game of snooker, what colour ball is worth 3 points?",
            a1: "Blue",
            a2: "Brown",
            a3: "Green",
            a4: "Yellow",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Tennis was once known as Racquetball?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "The Olympics tennis court is a giant green screen?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "In bowling, what is the term used for getting three consecutive strikes?",
            a1: "Birdie",
            a2: "Eagle",
            a3: "Flamingo",
            a4: "Turkey",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "ATP tennis hosted several tournaments on carpet court before being replaced to reduce injuries?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the name of the AHL affiliate of the Toronto Maple Leafs?",
            a1: "Toronto Rock",
            a2: "Toronto Marlies",
            a3: "Toronto Argonauts",
            a4: "Toronto Wolfpack",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who won the 2018 Monaco Grand Prix?",
            a1: "Sebastian Vettel",
            a2: "Daniel Ricciardo",
            a3: "Lewis Hamilton",
            a4: "Kimi Raikkonen",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "How many scoring zones are there on a conventional dart board?",
            a1: "62",
            a2: "82",
            a3: "102",
            a4: "42",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the most common type of pitch thrown by pitchers in baseball?",
            a1: "Palmball",
            a2: "Slowball",
            a3: "Screwball",
            a4: "Fastball",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "There were a total of 20 races in Formula One 2016 season?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "At which bridge does the annual Oxford and Cambridge boat race start?",
            a1: "Vauxhall",
            a2: "Battersea",
            a3: "Putney",
            a4: "Hammersmith",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who was the British professional wrestler Shirley Crabtree better known as?",
            a1: "Kendo Nagasaki",
            a2: "Giant Haystacks",
            a3: "Masambula",
            a4: "Big Daddy",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which country is hosting the 2022 FIFA World Cup?",
            a1: "Uganda",
            a2: "Vietnam",
            a3: "Bolivia",
            a4: "Qatar",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the oldest team in Major League Baseball?",
            a1: "Chicago Cubs",
            a2: "Atlanta Braves",
            a3: "St. Louis Cardinals",
            a4: "Cincinnati Reds",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Wilt Chamberlain scored his infamous 100-point-game against the New York Knicks in 1962?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Why was The Green Monster at Fenway Park was originally built?",
            a1: "To display advertisements",
            a2: "To provide extra seating",
            a3: "To make getting home runs harder",
            a4: "To prevent viewing games from outside the park",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which car manufacturer won the 2017 24 Hours of Le Mans?",
            a1: "Chevrolet",
            a2: "Toyota",
            a3: "Audi",
            a4: "Porsche",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who is Manchester United's top premier league goal scorer?",
            a1: "David Beckham",
            a2: "Sir Bobby Charlton",
            a3: "Wayne Rooney",
            a4: "Ryan Giggs",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following player scored a hat-trick during their Manchester United debut?",
            a1: "Robin Van Persie",
            a2: "Wayne Rooney",
            a3: "Cristiano Ronaldo",
            a4: "David Beckham",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who was the topscorer for England national football team?",
            a1: "Michael Owen",
            a2: "Steven Gerrard",
            a3: "David Beckham",
            a4: "Wayne Rooney",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is Tiger Woods' all-time best career golf-score?",
            a1: "67",
            a2: "65",
            a3: "63",
            a4: "61",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who won the 2011 Stanley Cup?",
            a1: "Boston Bruins",
            a2: "New York Rangers",
            a3: "Toronto Maple Leafs",
            a4: "Montreal Canadiens",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which NBA player has the most games played over the course of their career?",
            a1: "Robert Parish",
            a2: "Kareem Abdul-Jabbar",
            a3: "Kobe Bryant",
            a4: "Kevin Garnett",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which German sportswear company's logo is the 'Formstripe'?",
            a1: "Nike",
            a2: "Reebok",
            a3: "Adidas",
            a4: "Puma",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "What cricketing term denotes a batsman being dismissed with a score of zero?",
            a1: "Beamer",
            a2: "Bye",
            a3: "Duck",
            a4: "Carry",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which Formula One driver was nicknamed 'The Professor'?",
            a1: "Emerson Fittipaldi",
            a2: "Niki Lauda",
            a3: "Ayrton Senna",
            a4: "Alain Prost",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which nation hosted the FIFA World Cup in 2006?",
            a1: "Germany",
            a2: "South Africa",
            a3: "United Kingdom",
            a4: "Brazil",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which team was the 2015-2016 NBA Champions?",
            a1: "Golden State Warriors",
            a2: "Oklahoma City Thunders",
            a3: "Toronto Raptors",
            a4: "Cleveland Cavaliers",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Formula E is an auto racing series that uses hybrid electric race cars?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "What is the oldest team in the NFL?",
            a1: "New York Giants",
            a2: "Chicago Bears",
            a3: "Arizona Cardinals",
            a4: "Green Bay Packers",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following Grand Slam tennis tournaments occurs LAST?",
            a1: "Wimbledon",
            a2: "French Open",
            a3: "US Open",
            a4: "Australian Open",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "What year did the New Orleans Saints win the Super Bowl?",
            a1: "2008",
            a2: "2010",
            a3: "2011",
            a4: "2009",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which portuguese island is soccer player Cristiano Ronaldo from?",
            a1: "Santa Maria",
            a2: "Porto Santo",
            a3: "Madeira",
            a4: "Terceira",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which NBA player won Most Valuable Player for the 1999-2000 season?",
            a1: "Kobe Bryant",
            a2: "Allen Iverson",
            a3: "Paul Pierce",
            a4: "Shaquille O'Neal",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "In 2008, Usain Bolt set the world record for the 100 meters with one shoelace untied?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which professional wrestler fell from the rafters to his death during a live Pay-Per-View event in 1999?",
            a1: "Lex Luger",
            a2: "Owen Hart",
            a3: "Chris Benoit",
            a4: "Al Snow",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who won the UEFA Champions League in 2016?",
            a1: "Manchester City F.C",
            a2: "FC Bayern Munich",
            a3: "Real Madrid C.F",
            a4: "Atletico Madrid",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "What team did England beat in the semi-final stage to win in the 1966 World Cup final?",
            a1: "Soviet Union",
            a2: "West Germany",
            a3: "Brazil",
            a4: "Portugal",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which African American is in part responsible for integrating  Major League baseball?",
            a1: "Jackie Robinson",
            a2: "Roy Campanella",
            a3: "Curt Flood",
            a4: "Satchell Paige",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "What team won the 2016 MLS Cup?",
            a1: "Montreal Impact",
            a2: "Toronto FC",
            a3: "Seattle Sounders",
            a4: "Colorado Rapids",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "What is the full name of the footballer 'Cristiano Ronaldo'?",
            a1: "Cristiano Ronaldo los Santos Diego",
            a2: "Cristiano Armando Diego Ronaldo",
            a3: "Cristiano Luis Armando Ronaldo",
            a4: "Cristiano Ronaldo dos Santos Aveiro",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which two teams played in Super Bowl XLII?",
            a1: "The Green Bay Packers & The Pittsburgh Steelers",
            a2: "The New York Giants & The New England Patriots",
            a3: "The Philadelphia Eagles & The New England Patriots",
            a4: "The Seattle Seahawks & The Denver Broncos",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which Italian footballer told Neuer where he's putting his shot and dragging it wide, during the match Italy-Germany, UEFA EURO 2016?",
            a1: "Pelle",
            a2: "Giaccherini",
            a3: "Insigne",
            a4: "Barzagli",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which player 'kung-fu kicked' a Crystal Palace fan in January 1995?",
            a1: "Mark Hughes",
            a2: "David Seamen",
            a3: "Eric Cantona",
            a4: "Ashley Cole",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "With which doubles partner did John McEnroe have most success?",
            a1: "Michael Stich",
            a2: "Mark Woodforde",
            a3: "Peter Fleming",
            a4: "Mary Carillo",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which year was the third Super Bowl held?",
            a1: "1968",
            a2: "1969",
            a3: "1971",
            a4: "1970",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "What tool lends it's name to a last-stone advantage in an end in Curling?",
            a1: "Drill",
            a2: "Wrench",
            a3: "Screwdriver",
            a4: "Hammer",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which car company is the only Japanese company which won the 24 Hours of Le Mans?",
            a1: "Nissan",
            a2: "Subaru",
            a3: "Mazda",
            a4: "Toyota",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which male player won the gold medal of table tennis singles in 2016 Olympics Games?",
            a1: "Ma Long (China)",
            a2: "Jun Mizutani (Japan)",
            a3: "Zhang Jike (China)",
            a4: "Vladimir Samsonov (Belarus)",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "How many points did LeBron James score in his first NBA game?",
            a1: "25",
            a2: "69",
            a3: "41",
            a4: "19",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Who won the 2016 Formula 1 World Driver's Championship?",
            a1: "Lewis Hamilton",
            a2: "Kimi Raikkonen",
            a3: "Nico Rosberg",
            a4: "Max Verstappen",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which city did the former NHL team 'The Nordiques' originiate from?",
            a1: "Montreal",
            a2: "Quebec City",
            a3: "Houston",
            a4: "New York",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "This Canadian television sportscaster is known for his 'Hockey Night in Canada' role, a commentary show during hockey games?",
            a1: "Don Cherry",
            a2: "Don McKellar",
            a3: "Donald Sutherland",
            a4: "Don Taylor",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which team has won the most Stanley Cups in the NHL?",
            a1: "Toronto Maple Leafs",
            a2: "Montreal Canadians",
            a3: "Detroit Red Wings",
            a4: "Chicago Blackhawks",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "In Rugby League, performing a '40-20' is punished by a free kick for the opposing team?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "When was the FC Schalke 04 founded?",
            a1: "2008",
            a2: "1904",
            a3: "1909",
            a4: "1999",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "'Stadium of Light' is the home stadium for which soccer team?",
            a1: "Manchester United",
            a2: "Barcelona FC",
            a3: "Paris Saints-Germain",
            a4: "Sunderland FC",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "What is “The Sport of Kings”?",
            a1: "Horse Racing",
            a2: "Fencing",
            a3: "Jousting",
            a4: "Chess",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which of these Russian cities did NOT contain a stadium that was used in the 2018 FIFA World Cup?",
            a1: "Yekaterinburg",
            a2: "Vladivostok",
            a3: "Kaliningrad",
            a4: "Rostov-on-Don",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Peyton Manning retired after winning Super Bowl XLIX?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "The Rio 2016 Summer Olympics held it's closing ceremony on what date?",
            a1: "August 19",
            a2: "August 21",
            a3: "August 23",
            a4: "August 17",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Where was the Games of the XXII Olympiad held?",
            a1: "Los Angeles",
            a2: "Tokyo",
            a3: "Moscow",
            a4: "Barcelona",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which female player won the gold medal of table tennis singles in 2016 Olympics Games?",
            a1: "Ai FUKUHARA (Japan)",
            a2: "DING Ning (China)",
            a3: "LI Xiaoxia (China)",
            a4: "Song KIM (North Korea)",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which city features all of their professional sports teams' jersey's with the same color scheme?",
            a1: "Seattle",
            a2: "Tampa Bay",
            a3: "Pittsburgh",
            a4: "New York",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "The Los Angeles Dodgers were originally from what U.S. city?",
            a1: "Boston",
            a2: "Las Vegas",
            a3: "Brooklyn",
            a4: "Seattle",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "In Canadian football, scoring a rouge is worth how many points?",
            a1: "1",
            a2: "2",
            a3: "3",
            a4: "4",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "How many times did Martina Navratilova win the Wimbledon Singles Championship?",
            a1: "Seven",
            a2: "Ten",
            a3: "Eight",
            a4: "Nine",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "In baseball, how many fouls are an out?",
            a1: "0",
            a2: "3",
            a3: "5",
            a4: "2",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Who is Manchester United's leading appearance maker?",
            a1: "Ryan Giggs",
            a2: "David Beckham",
            a3: "Eric Cantona",
            a4: "Wayne Rooney",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which English football team is nicknamed 'The Tigers'?",
            a1: "Cardiff City",
            a2: "Hull City",
            a3: "Bristol City",
            a4: "Manchester City",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "The AHL affiliate team of the Boston Bruins is named what?",
            a1: "Providence Bruins",
            a2: "New Haven Bruins",
            a3: "Cambridge Bruins",
            a4: "Hartford Bruins",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which player holds the NHL record of 2,857 points?",
            a1: "Sidney Crosby",
            a2: "Wayne Gretzky",
            a3: "Mario Lemieux",
            a4: "Gordie Howe",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "The Mazda 787B won the 24 Hours of Le Mans in what year?",
            a1: "1990",
            a2: "1991",
            a3: "1987",
            a4: "2000",
            a: 2,
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "The last Nascar driver to serve jail time for running moonshine was who?",
            an: "Buddy arrington",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which snooker player ended Stephen Hendry's five-year unbeaten run at The Crucible?",
            an: "Ken Doherty",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "In Which Athletics Event Are Competitors Known As Either 'Spinners Or Shifters''?",
            an: "Shot Putt",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Where were the 1988 Olympics held?",
            an: "Seoul, South Korea",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "What Do The Initials TT Stand For In Regard With The Isle Of Man Motorcyle Race?",
            an: "Tourist Trophy",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Tony Drago is a snooker professional from which country?",
            an: "Malta",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "What Events Are Involved In A Biathlon?",
            an: "Cross Country Skiing & Shooting",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "What is the last event in a decathlon?",
            an: "1500 Metres",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Which English club was formed in 1887 as Ardwick FC?",
            an: "Manchester United",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "The German Athlete Jurgen Hingsen was always runner-up to which British Athlete in major competitions?",
            an: "Daley Thompson",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "From Which Country Do The Soccer Team Anderlecht Hail?",
            an: "Belgium",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "A Statue Of Billy Bremner Stands Outside The Ground Of Which Football Ground?",
            an: "Leeds United",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "In Athletics Track Races What Does The Ringing Of A Bell Signify?",
            an: "The Last Lap",
            seen: false
        },
        {
            c: c.CAT_SPO,
            d: c.DIFFICULTY_HARD,
            q: "Where were the 1896 Olympics held?",
            an: "Athens, Greece",
            seen: false
        }
]
    