import Dexie from 'dexie';

export let CAT_GEOG = "G"
export let CAT_ENT = "E"
export let CAT_HIST = "H"
export let CAT_ART = "A"
export let CAT_SCI = "S"
export let CAT_SPO = "SP"

export let DIFFICULTY_EASY = "E"
export let DIFFICULTY_MEDIUM = "M"
export let DIFFICULTY_HARD = "H"

import { art_questions } from "./db/custom/art.js";
import { entertainment_questions } from "./db/custom/entertainment.js";
import { geography_questions } from "./db/custom/geography.js";
import { history_questions } from "./db/custom/history.js";
import { science_questions } from "./db/custom/science.js";
import { sport_questions } from "./db/custom/sport.js";

import { Storage } from "@capacitor/storage";

let all_questions = art_questions.concat(entertainment_questions, geography_questions, history_questions, science_questions, sport_questions)

export const db = new Dexie('trivia');

let VERSION_RELOAD_QUESTIONS = "1"

db.version(1).stores({
  questions: '++id, c, q, a1, a2, a3, a4, a, seen',
});

Storage.get({ key: "version" }).then((version) => {
  db.questions.count((count) => {
    console.log("reloading questions check", count, version.value, VERSION_RELOAD_QUESTIONS);

    if (count == 0 || version.value == null || version.value != VERSION_RELOAD_QUESTIONS) {
      console.log("reloading questions", count, version.value, VERSION_RELOAD_QUESTIONS);

      db.questions.clear()
      db.questions.bulkAdd(all_questions)

      Storage.set({
        key: "version",
        value: VERSION_RELOAD_QUESTIONS,
      });
    }
  });
});
