import { defineStore } from 'pinia'

export const userStore = defineStore('user', {
    state: () => {
        return {
            // User UUID
            id: null,
            // Google UUID
            gid: null,
            google_email: null,
            username: null,
        }
    },
})
