
const {c} = require('./consts');

export let history_questions = [
     
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "During what war did the 'Cuban Missile Crisis' occur?",
            a1: "World War I",
            a2: "Cold War",
            a3: "World War II",
            a4: "Revolutionary War",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What Russian automatic gas-operated assault rifle was developed in the Soviet Union in 1947, and is still popularly used today?",
            a1: "MG 42",
            a2: "AK-47",
            a3: "M16",
            a4: "RPK",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The idea of Socialism was articulated and advanced by whom?",
            a1: "Vladimir Lenin",
            a2: "Karl Marx",
            a3: "Vladimir Putin",
            a4: "Joseph Stalin",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "On what day did Germany invade Poland?",
            a1: "July 7, 1937",
            a2: "June 22, 1941",
            a3: "September 1, 1939",
            a4: "December 7, 1941",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the Prime Minister of the United Kingdom for most of World War II?",
            a1: "Edward Heath",
            a2: "Neville Chamberlain",
            a3: "Harold Macmillan",
            a4: "Winston Churchill",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The collapse of the Soviet Union took place in which year?",
            a1: "1891",
            a2: "1990",
            a3: "1991",
            a4: "1992",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which Apollo mission was the last one in NASA's Apollo program?",
            a1: "Apollo 15",
            a2: "Apollo 13",
            a3: "Apollo 17",
            a4: "Apollo 11",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The French Kingdom helped the United States gain their independence over Great Britain during the Revolutionary War?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The Ottoman Empire was dissolved after their loss in which war?",
            a1: "World War I",
            a2: "Serbian Revolution",
            a3: "Second Balkan War",
            a4: "Crimean War",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "King Henry VIII was the second monarch of which European royal house?",
            a1: "Stuart",
            a2: "York",
            a3: "Lancaster",
            a4: "Tudor",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following was Brazil was a former colony under?",
            a1: "The Netherlands",
            a2: "Spain",
            a3: "Portugal",
            a4: "France",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "On what street did the 1666 Great Fire of London start?",
            a1: "Baker Street",
            a2: "Pudding Lane",
            a3: "St Paul's Cathedral",
            a4: "Houses of Parliament",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Adolf Hitler was a german soldier in World War I?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which historical conflict killed the most people?",
            a1: "Taiping Rebellion",
            a2: "Mongol conquests",
            a3: "World War II",
            a4: "Three Kingdoms War",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the first president of the United States?",
            a1: "James Madison",
            a2: "James K. Polk",
            a3: "Thomas Jefferson",
            a4: "George Washington",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Adolf Hitler was tried at the Nuremberg trials?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year did World War I begin?",
            a1: "1914",
            a2: "1905",
            a3: "1925",
            a4: "1919",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "How many manned moon landings have there been?",
            a1: "7",
            a2: "3",
            a3: "6",
            a4: "1",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The Tiananmen Square protests of 1989 were held in Hong Kong?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "United States President John F. Kennedy was assassinated during his presidential motorcade in Atlanta, Georgia on November 22nd, 1963?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In what year did the Wall Street Crash take place?",
            a1: "1925",
            a2: "1929",
            a3: "1932",
            a4: "1930",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which of these countries remained neutral during World War II?",
            a1: "Switzerland",
            a2: "Italy",
            a3: "United Kingdom",
            a4: "France",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following countries was not an axis power during World War II?",
            a1: "Italy",
            a2: "Japan",
            a3: "Germany",
            a4: "Soviet Union",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In what year was the M1911 pistol designed?",
            a1: "1911",
            a2: "1899",
            a3: "1907",
            a4: "1917",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In 1939, Britain and France declared war on Germany after it invaded which country?",
            a1: "Czechoslovakia",
            a2: "Poland",
            a3: "Hungary",
            a4: "Austria",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What was the name commonly given to the ancient trade routes that connected the East and West of Eurasia?",
            a1: "Salt Road",
            a2: "Silk Road",
            a3: "Clay Road",
            a4: "Spice Road",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The Cold War ended with Joseph Stalin's death?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Whose 2016 presidential campaign slogan was 'Make America Great Again'?",
            a1: "Marco Rubio",
            a2: "Ted Cruz",
            a3: "Donald Trump",
            a4: "Bernie Sanders",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which of the following Pacific Islander countries is ruled by a constitutional monarchy?",
            a1: "Kiribati",
            a2: "Palau",
            a3: "Fiji",
            a4: "Tonga",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "How many people are in the U.S. House of Representatives?",
            a1: "415",
            a2: "50",
            a3: "260",
            a4: "435",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Denmark has a monarch?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Despite being seperated into multiple countries, The Scandinavian countries are unified by all having the same monarch?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The 2016 United States Presidential Election is the first time Hillary Clinton has run for President?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Russia passed a law in 2013 which outlaws telling children that homosexuals exist?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "According to the United States Constitution, how old must a person be to be elected President of the United States?",
            a1: "45",
            a2: "35",
            a3: "40",
            a4: "30",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "One of Barack Obama's United States presidential campaign slogan's was 'Yes We Can'?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the 45th President of the United States?",
            a1: "Bill Clinton",
            a2: "Barack Obama",
            a3: "George Bush",
            a4: "Donald Trump",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Donald Trump won the popular vote in the 2016 United States presidential election?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Former United States President Bill Clinton famously played which instrument?",
            a1: "Violin",
            a2: "Baritone horn",
            a3: "Saxophone",
            a4: "Piano",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Former president Theodore Roosevelt (1900-1908)  ran for another term under the Progressive Party in 1912?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which former US president was nicknamed 'Teddy' after he refused to shoot a defenseless black bear?",
            a1: "Andrew Jackson",
            a2: "James F. Fielder",
            a3: "Woodrow Wilson",
            a4: "Theodore Roosevelt",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The S in Harry S. Truman stands for 'Samuel'?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "There was a satirical candidate named 'Deez Nuts' running in the 2016 US presidential elections?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The largest consumer market in 2015 was..?",
            a1: "Japan",
            a2: "United Kingdom",
            a3: "The United States of America",
            a4: "Germany",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In 2016, the United Kingdom voted to stay in the EU?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        },
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who Became premier of Cuba in 1959?",
            an: "Fidel Castro",
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The Statue of Liberty was given to the US by which country?",
            a1: "China",
            a2: "Spain",
            a3: "France",
            a4: "UK",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which kind of bulbs were once exchanged as a form of currency?",
            a1: "Allium",
            a2: "Begonia",
            a3: "Lily",
            a4: "Tulips",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In ancient Greece, throwing an apple at someone was a declaration of what?",
            a1: "War",
            a2: "Love",
            a3: "Marriage",
            a4: "Peace",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "By what name were the Egyptian kings/rulers known?",
            a1: "Gangsters",
            a2: "Dictators",
            a3: "Monarchs",
            a4: "Pharoahs",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "How many Pyramids of Giza were made?",
            a1: "4",
            a2: "6",
            a3: "3",
            a4: "7",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In which year did World War I begin?",
            a1: "1914",
            a2: "1916",
            a3: "1918",
            a4: "1912",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In which year World War II begin?",
            a1: "1939",
            a2: "1944",
            a3: "1938",
            a4: "1945",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In which country Adolph Hitler was born?",
            a1: "Austria",
            a2: "Romania",
            a3: "Germany",
            a4: "Poland",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "John F. Kennedy was assassinated in which city?",
            a1: "Houston",
            a2: "Austin",
            a3: "Dallas",
            a4: "Memphis",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "On Sunday 18th June 1815, which famous battle took place?",
            a1: "The Battle of Waterloo",
            a2: "The Battle of Blenheim",
            a3: "The Battle of Somme",
            a4: "The Battle of Hastings",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What is the lowest army rank of a US soldier?",
            a1: "First Sergeant",
            a2: "Corporal",
            a3: "Staff Sergeant",
            a4: "Private",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Tanks were used in battle for the first time in which conflict?",
            a1: "Vietnam War",
            a2: "Korean War",
            a3: "World War II",
            a4: "World War I",
            a: 4,
            seen: false
        },
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which Italian fascist leader was known as 'Il Duce'?",
            an: "Mussolini",
            seen: false
        },
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What were the two sides in World War II known as?",
            an: "Allies and Axis",
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "World War I began with the death of Archduke Franz Ferdinand, of which country?",
            a1: "Austria",
            a2: "Poland",
            a3: "Germany",
            a4: "Romania",
            a: 1,
            seen: false
        },
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Donald Trump and which North Korean leader are known to trade threats over the internet?",
            an: "Kim Jong Un",
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What was the predecessor to the United Nations?",
            a1: "League of Nations",
            a2: "G20",
            a3: "G7",
            a4: "NATO",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What U.S. President coined the phrase 'Good to the last drop' referring to coffee?",
            a1: "Theodore Roosevelt",
            a2: "Barack Obama",
            a3: "Ronald Reagan",
            a4: "Dwight D. Eisenhower",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which American president was involved in the Watergate scandal?",
            a1: "Barack Obama",
            a2: "Herbert Hoover",
            a3: "Woodrow Wilson",
            a4: "Richard Nixon",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who has been the longest-serving monarch of the United Kingdom?",
            a1: "Queen Victoria",
            a2: "Queen Elizabeth I",
            a3: "Queen Elizabeth II",
            a4: "King George IiI",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Leader and political icon Nelson Mandela was from which African country?",
            a1: "Angola",
            a2: "Madagascar",
            a3: "South Africa",
            a4: "Tanzania",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Alphabetically Which Is The Last Of Santa's Reindeers?",
            a1: "Dasher",
            a2: "Cupid",
            a3: "Vixen",
            a4: "Prancer",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "How many years did World War I Last?",
            a1: "4",
            a2: "2",
            a3: "5",
            a4: "3",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year was the battle of hastings?",
            a1: "1266",
            a2: "1466",
            a3: "1066",
            a4: "1666",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "How many wifes did Henry VIII have?",
            a1: "7",
            a2: "8",
            a3: "5",
            a4: "6",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Where was the Titanic headed to before it sank?",
            a1: "China",
            a2: "Japan",
            a3: "Arctic Circle",
            a4: "USA",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who did Henry VIII marry first?",
            a1: "Meghan Markle",
            a2: "Catherine of Aragon",
            a3: "Anne of Cleves",
            a4: "Kate Middleton",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was caught red-handed trying to blow up parliament in 1605?",
            a1: "Guy Fawkes",
            a2: "Che Guevara",
            a3: "Henry VIII",
            a4: "Adolf Hitler",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What is the London home of the prime minister?",
            a1: "2 Downing Street",
            a2: "1 Downing Street",
            a3: "10 Downing Street",
            a4: "20 Downing Street",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What did Spain send to attack Britain in 1588?",
            a1: "The Spanish Empire",
            a2: "The Roman Armada",
            a3: "The Spanish Armada",
            a4: "The Roman Empire",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which era marked a switch from agricultural practices to industrial practices?",
            a1: "The Industrial Revolution",
            a2: "The Agricultural Revolution",
            a3: "The Iron Age",
            a4: "The Bronze Age",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Fill in the blank: The 19th Amendment guarantees ____ the right to vote?",
            a1: "Adolescents",
            a2: "Hispanic People",
            a3: "Women",
            
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which of these presidents are not on Mount Rushmore?",
            a1: "Abraham Lincoln",
            a2: "Barack Obama",
            a3: "George Washington",
            a4: "Thomas Jefferson",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which year was George H.W. Bush elected president?",
            a1: "1980",
            a2: "1994",
            a3: "1976",
            a4: "1988",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What do the stripes on the American flag represent?",
            a1: "13 amendments",
            a2: "13 original colonies",
            a3: "13 cities",
            a4: "13 languages",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which was the first US state to legalize same-sex marriage?",
            a1: "Massachusetts",
            a2: "North Dakota",
            a3: "Illinois",
            a4: "New York",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was US president during the Cuban Missile Crisis?",
            a1: "Gerald Ford",
            a2: "Woodrow Wilson",
            a3: "John F. Kennedy",
            a4: "Richard Nixon",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "When did the construction of the Great Wall of China begin?",
            a1: "221 B.C",
            a2: "21 B.C",
            a3: "621 B.C",
            a4: "421 B.C",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What is considered the largest empire in history?",
            a1: "Qing dynasty",
            a2: "Spanish Empire",
            a3: "The British Empire",
            a4: "The Mongol Empire",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Where did Albert Einstein live before moving to the United States?",
            a1: "Belgium",
            a2: "France",
            a3: "Germany",
            a4: "UK",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "During which war was a Christmas Truce called?",
            a1: "World War I",
            a2: "Vietnam War",
            a3: "Korean War",
            a4: "World War II",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "How old was Queen Elizabeth II when she was crowned the Queen of England?",
            a1: "27",
            a2: "47",
            a3: "37",
            a4: "17",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the first ruler of the Mongol Empire?",
            a1: "Tolui Khan",
            a2: "Oghul Qaimish",
            a3: "Gegeen Khan",
            a4: "Genghis Khan",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What was the name of the Ukrainian nuclear power plant that was the site of a nuclear disaster in April 1986?",
            a1: "Chernobyl",
            a2: "Hiroshima",
            a3: "Fukushima",
            a4: "Navada",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What was the name of the international group formed to maintain world peace after World War I?",
            a1: "G7",
            a2: "The League of Nations",
            a3: "NATO",
            a4: "United Nations",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the first Emperor of Rome?",
            a1: "Valerian",
            a2: "Augustus",
            a3: "Hadrian",
            a4: "Titus",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What is the world's oldest recorded civilization?",
            a1: "Mesopotamia",
            a2: "Ancient India",
            a3: "Ancient Egypt",
            a4: "Ancient China",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year did the Iraq war start?",
            a1: "2007",
            a2: "2003",
            a3: "2005",
            a4: "2000",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year did the war in Afghanistan start?",
            a1: "2003",
            a2: "2001",
            a3: "1999",
            a4: "2005",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which British doctor introduced the smallpox vaccine – the world’s first vaccine?",
            a1: "Edward Jenner",
            a2: "Joseph Lister",
            a3: "James Watt",
            a4: "Alexander Fleming",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What is the name of the building where American presidents live?",
            a1: "The Black House",
            a2: "The White House",
            a3: "The Brown House",
            a4: "The Yellow House",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which country would you expect mummification to be carried out?",
            a1: "Sudan",
            a2: "Ethiopia",
            a3: "Eqypt",
            a4: "Algeria",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year was the iPhone released?",
            a1: "2007",
            a2: "2005",
            a3: "2003",
            a4: "2001",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "_______ invented the lightbulb?",
            a1: "Nikola Tesla",
            a2: "Henry Ford",
            a3: "Thomas Edison",
            a4: "Benjamin Franklin",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Bill _____ started the company Microsoft?",
            a1: "Bezoz",
            a2: "Musk",
            a3: "Gates",
            a4: "Zuckerberg",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Mark _____ started the company Facebook?",
            a1: "Arnault",
            a2: "Ellison",
            a3: "Zuckerberg",
            a4: "Buffett",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Jeff _____ started the company Amazon?",
            a1: "Bezoz",
            a2: "Koch",
            a3: "Adani",
            a4: "Page",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Larry Page and _____ started the company Google?",
            a1: "Jim C. Walton",
            a2: "Steve Ballmer",
            a3: "Sergey Brin",
            a4: "Larry Ellison",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "______ and Steve Wozniak started the company Apple?",
            a1: "Mike Bloomberg",
            a2: "Steve Jobs",
            a3: "Carlos Slim",
            a4: "Alice Walton",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "______ started the company Virgin?",
            a1: "Ritesh Agarwal",
            a2: "Steve Jobs",
            a3: "Richard Branson",
            a4: "Elon Musk",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "______ started the company Tesla?",
            a1: "Sergey Brin",
            a2: "Jeff Bezos",
            a3: "Elon Musk",
            a4: "Alice Walton",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "______ started the company Twitter?",
            a1: "Jack Dorsey",
            a2: "Mike Bloomberg",
            a3: "Elon Musk",
            a4: "Jeff Bezoz",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year did World War II end?",
            a1: "1947",
            a2: "1945",
            a3: "1949",
            a4: "1943",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year did the Cold War start?",
            a1: "1951",
            a2: "1961",
            a3: "1956",
            a4: "1946",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year did the Korean War start?",
            a1: "1965",
            a2: "1950",
            a3: "1960",
            a4: "1955",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year did the Vietnam War start?",
            a1: "1965",
            a2: "1985",
            a3: "1955",
            a4: "1975",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which communication device was invented by Alexander Graham Bell?",
            a1: "Mobile",
            a2: "Pager",
            a3: "Satellite",
            a4: "Telephone",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the first Black President of the United States?",
            a1: "Jimmy Carter",
            a2: "Barack Obama",
            a3: "Gerald Ford",
            a4: "Richard Nixon",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "How many years did it take to build the Taj Mahal?",
            a1: "22",
            a2: "12",
            a3: "32",
            a4: "2",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What is the man-mad circle of stones landmark called in England?",
            a1: "The Shambles",
            a2: "Jurassic Coast",
            a3: "Stonehenge",
            a4: "Covent Garden",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Amelia Earheart was the first woman to do what across the Atlantic Ocean?",
            a1: "Fly Solo",
            a2: "Sail",
            a3: "Powerboat",
            a4: "Wind Surf",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which US president was known as Honest Ab?",
            a1: "Ronald Reagan",
            a2: "Theodore Roosevelt",
            a3: "Abraham Lincoln",
            a4: "Jimmy Carter",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Christopher Columbus discovered Asia?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What caused the Titanic to sink?",
            a1: "Whale attack",
            a2: "Another ship",
            a3: "Bomb",
            a4: "Iceberg",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "What year was the great fire of London?",
            a1: "1866",
            a2: "1666",
            a3: "1766",
            a4: "1966",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The plague was spread by which rodent?",
            a1: "Rats",
            a2: "Degu",
            a3: "Marmot",
            a4: "Capybara",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "The kinghts of the round table served which king?",
            a1: "King Arthur",
            a2: "King Henry",
            a3: "King Lear",
            a4: "King Charles",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Gravity was discovered with the help of which fruit?",
            a1: "Grape",
            a2: "Banana",
            a3: "Pear",
            a4: "Apple",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which animals did the ancient Eqyptians worship?",
            a1: "Horses",
            a2: "Cats",
            a3: "Camels",
            a4: "Dogs",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which animal was the first in space?",
            a1: "Dog",
            a2: "Chicken",
            a3: "Monkey",
            a4: "Cat",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the sixth and final wife of Henry VIII?",
            a1: "Catherine Parr",
            a2: "Anne Boleyn",
            a3: "Jane Seymour",
            a4: "Catherine Howard",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the longest serving US President?",
            a1: "Bill Clinton",
            a2: "Ronald Reagan",
            a3: "Lyndon B. Johnson",
            a4: "Franklin D Roosevelt",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which US President was assassinated by Lee Harvey Oswald?",
            a1: "Barack Obama",
            a2: "Bill Clinton",
            a3: "Lyndon B. Johnson",
            a4: "John F Kennedy",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which wife of Henry VIII was the first to be beheaded?",
            a1: "Catherine of Aragon",
            a2: "Anne of Cleves",
            a3: "Catherine Howard",
            a4: "Anne Boleyn",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was Prime Minister of Great Britain for most of the Second World War?",
            a1: "James Callaghan",
            a2: "Winston Churchill",
            a3: "Harold Wilson",
            a4: "Stanley Baldwin",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the first Lord Protector of England after the Civil War?",
            a1: "Oliver Cromwell",
            a2: "John Major",
            a3: "Edward Heath",
            a4: "Ramsay MacDonald",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who led the Chinese revolution, becoming chairman of the Communist Party in 1949?",
            a1: "Mao Zedong",
            a2: "Yang Shangkun",
            a3: "Xi Jinping",
            a4: "Yan Huiqing",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which President was brought down by the Watergate Scandal?",
            a1: "Harry S. Truman",
            a2: "Theodore Roosevelt",
            a3: "Richard Nixon",
            a4: "Barack Obama",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Yuan, Qing and Ming were Imperial Dynasties in the history of which country?",
            a1: "Japan",
            a2: "Thailand",
            a3: "Mongolia",
            a4: "China",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which country was unified by Giuseppe Garibaldi in 1851?",
            a1: "Italy",
            a2: "Netherlands",
            a3: "Denmark",
            a4: "Greece",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was President of the USA throughout World War One?",
            a1: "Barack Obama",
            a2: "Woodrow Wilson",
            a3: "Gerald Ford",
            a4: "Herbert Hoover",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was the Emperor of Japan during World War Two?",
            a1: "Hanzei",
            a2: "Tenji",
            a3: "Jimmu",
            a4: "Hirohito",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which US President ordered the deployment of two atomic bombs in Japan?",
            a1: "Herbert Hoover",
            a2: "Harry S. Truman",
            a3: "George H. W. Bush",
            a4: "Dwight D. Eisenhower",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Atomic bombs were dropped on Hiroshima and which other Japanese city?",
            a1: "Yokohama",
            a2: "Kyoto",
            a3: "Nagasaki",
            a4: "Tokyo",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which nation fought against the Allies until 1943 but declared war on Japan in July 1945?",
            a1: "USA",
            a2: "USSR",
            a3: "Italy",
            a4: "Germany",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In which state of the USA is the Pearl Harbour base, attacked by Japan in 1941?",
            a1: "New York",
            a2: "Hawaii",
            a3: "Alaska",
            a4: "Florida",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which Scandinavian country was a co-belligerent of the Axis powers after signing the anti-Comintern pact of 1941?",
            a1: "Norway",
            a2: "Sweden",
            a3: "Denmark",
            a4: "Finland",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Troops from which nation liberated Auschwitz?",
            a1: "USSR",
            a2: "USA",
            a3: "France",
            a4: "UK",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Florence Nightingale became famous for her work as a nurse during which conflict?",
            a1: "Crimean War",
            a2: "Basque conflict",
            a3: "Bosnian War",
            a4: "Gulf War",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Which Queen gave birth to her son Caesarion in 47 BC?",
            a1: "Cleopatra",
            a2: "Catherine the Great",
            a3: "Marie-Antoinette",
            a4: "Nefertiti",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "In which country was the Battle of Ypres fought in World War I?",
            a1: "Belgium",
            a2: "Sweden",
            a3: "Russia",
            a4: "Bulgaria",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who overthrew their husband to rule Russia for more than thirty years in the eighteenth century?",
            a1: "Catherine the Great",
            a2: "Nefertiti",
            a3: "Maria Theresa",
            a4: "Marie-Antoinette",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who married both Louis XII of France and Henry II of England?",
            a1: "Zenobia",
            a2: "Catherine the Great",
            a3: "Eleanor of Aquitaine",
            a4: "Hatshepsut",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_EASY,
            q: "Who was, albeit not undisputedly, the first Queen to rule England in the 1140s?",
            a1: "Victoria",
            a2: "Matilda",
            a3: "Elizabeth",
            a4: "Anne",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which king was killed at the Battle of Bosworth Field in 1485?",
            a1: "Richard III",
            a2: "James I",
            a3: "Edward V",
            a4: "Henry VII",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "In World War II, Hawker Typhoons served in the Pacific theater?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following battles is often considered as marking the beginning of the fall of the Western Roman Empire?",
            a1: "Battle of Pollentia",
            a2: "Battle of Thessalonica",
            a3: "Battle of Adrianople",
            a4: "Battle of Constantinople",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "One of the deadliest pandemics, the 'Spanish Flu', killed off what percentage of the human world population at the time?",
            a1: "3 to 6 percent",
            a2: "less than 1 percent",
            a3: "1 to 3 percent",
            a4: "6 to 10 percent",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "In what year was the last natural case of smallpox documented?",
            a1: "1990",
            a2: "1980",
            a3: "1982",
            a4: "1977",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "According to scholarly estimates, what percentage of the world population at the time died due to Tamerlane's conquests?",
            a1: "1%",
            a2: "5%",
            a3: "3%",
            a4: "<1%",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following Assyrian kings did NOT rule during the Neo-Assyrian Empire?",
            a1: "Esharhaddon",
            a2: "Shamshi-Adad III",
            a3: "Ashur-nasir-pal II",
            a4: "Shalmaneser V",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "'I disapprove of what you say, but I will defend to the death your right to say it' is a quote from French philosopher Voltaire?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Brezhnev was the 5th leader of the USSR?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the first sport to have been played on the moon?",
            a1: "Tennis",
            a2: "Football",
            a3: "Soccer",
            a4: "Golf",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was Genghis Khan's real name?",
            a1: "Temüjin",
            a2: "Ögedei",
            a3: "Möngke",
            a4: "Temür",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "When Christopher Columbus sailed to America, what was the first region he arrived in?",
            a1: "The Bahamas Archipelago",
            a2: "Nicaragua",
            a3: "Florida",
            a4: "Isthmus of Panama",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the unofficial name for Germany between 1919 and 1933?",
            a1: "Weimar Republic",
            a2: "Oesterreich",
            a3: "Federal Republic of Germany",
            a4: "German Democratic Republic",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who was the leader of the Communist Party of Yugoslavia?",
            a1: "Aleskandar Petrovic",
            a2: "Milos Obilic",
            a3: "Josip Broz Tito",
            a4: "Karadjordje Petrovic",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Adolf Hitler was accepted into the Vienna Academy of Fine Arts?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the name of one of the surviving palaces of Henry VIII located near Richmond, London?",
            a1: "Coughton Court",
            a2: "Buckingham Palace",
            a3: "St James's Palace",
            a4: "Hampton Court",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "What year did Australia become a federation?",
            a1: "1901",
            a2: "1910",
            a3: "1911",
            a4: "1899",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "In which year was Constantinople conquered by the Turks?",
            a1: "1440",
            a2: "1454",
            a3: "1435",
            a4: "1453",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "In what year did the Berlin Wall fall?",
            a1: "1989",
            a2: "1991",
            a3: "1993",
            a4: "1987",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "On which aircraft carrier did the Doolitte Raid launch from on April 18, 1942 during World War II?",
            a1: "USS Lexington",
            a2: "USS Enterprise",
            a3: "USS Saratoga",
            a4: "USS Hornet",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "When did construction of the Suez Canal finish?",
            a1: "1860",
            a2: "1850",
            a3: "1859",
            a4: "1869",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following Presidents of the United States was assassinated?",
            a1: "William McKinley",
            a2: "Chester Arthur",
            a3: "Franklin Roosevelt",
            a4: "Lyndon Johnson",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "The Korean War started in what year?",
            a1: "1945",
            a2: "1912",
            a3: "1960",
            a4: "1950",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "When did O, Canada officially become the national anthem?",
            a1: "1950",
            a2: "1920",
            a3: "1980",
            a4: "1880",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who assassinated Archduke Franz Ferdinand?",
            a1: "Nedeljko Cabrinovic",
            a2: "Ferdinand Cohen-Blind",
            a3: "Gavrilo Princip",
            a4: "Oskar Potiorek",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which Nazi General was known as the 'Desert Fox'?",
            a1: "Erwin Rommel",
            a2: "Wilhelm Keitel",
            a3: "Gerd von Rundstadt",
            a4: "Heinz Guderian",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "John Moses Browning, the designer of the M1918 BAR (Browning Automatic Rifle) was a part of which religion?",
            a1: "Atheist",
            a2: "Mormon",
            a3: "Jewish",
            a4: "Catholic",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who was the first American in space?",
            a1: "Alan Shephard",
            a2: "John Glenn",
            a3: "Neil Armstrong",
            a4: "Jim Lovell",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "What event marked the start of World War II?",
            a1: "Invasion of Normandy (1944)",
            a2: "Invasion of Poland (1939)",
            a3: "Invasion of Russia (1942)",
            a4: "Battle of Britain (1940)",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was William Frederick Cody better known as?",
            a1: "Pawnee Bill",
            a2: "Billy the Kid",
            a3: "Wild Bill Hickok",
            a4: "Buffalo Bill",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "America's Strategic Defense System during the Cold War was nicknamed after this famous movie?",
            a1: "Blade Runner",
            a2: "Jaws",
            a3: "Star Wars",
            a4: "Alien",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "The first televised presidential debate was between Jimmy Carter and Gerald Ford?",
            a1: "True",
            a2: "False",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "In what year was the famous 45 foot tall Hollywood sign first erected?",
            a1: "1903",
            a2: "1923",
            a3: "1913",
            a4: "1933",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "The crown of the Empire State Building was originally built for what purpose?",
            a1: "Lightning Rod",
            a2: "Antennae",
            a3: "Flag Pole",
            a4: "Airship Dock",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "The creator of the Enigma Cypher and Machine was of what nationality?",
            a1: "British",
            a2: "German",
            a3: "Polish",
            a4: "American",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "When was the United States National Security Agency established?",
            a1: "November 4, 1952",
            a2: "November 25, 2002",
            a3: "July 26, 1908",
            a4: "July 1, 1973",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Where and when was the first cardboard box made for industrial use?",
            a1: "England, 1817",
            a2: "United States, 1817",
            a3: "United States, 1917",
            a4: "England, 1917",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "In 1967, a magazine published a story about extracting hallucinogenic chemicals from bananas to raise moral questions about banning drugs?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Joseph Stalin had a criminal past doing what?",
            a1: "Identity Fraud",
            a2: "Murder for Hire",
            a3: "Robbing Trains",
            a4: "Tax Evasion",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "The United States of America declared their independence from the British Empire on July 4th, 1776?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which WWII tank ace is credited with having destroyed the most tanks?",
            a1: "Kurt Knispel",
            a2: "Walter Kniep",
            a3: "Michael Wittmann",
            a4: "Otto Carius",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "What was the transfer of disease, crops, and people across the Atlantic shortly after the discovery of the Americas called?",
            a1: "Transatlantic Slave Trade",
            a2: "Triangle Trade",
            a3: "The Columbian Exchange",
            a4: "The Silk Road",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which of the following snipers has the highest amount of confirmed kills?",
            a1: "Vasily Zaytsev",
            a2: "Simo Häyhä",
            a3: "Chris Kyle",
            a4: "Craig Harrison",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Who is attributed credit for recording the epic poem The Odyssey?",
            a1: "Aristotle",
            a2: "Odysseus",
            a3: "Socrates",
            a4: "Homer",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which Las Vegas casino was originally constructed and operated by mobster Bugsy Siegel?",
            a1: "The Flamingo",
            a2: "The Sands",
            a3: "The Sahara",
            a4: "The MGM Grand",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "When was the People's Republic of China founded?",
            a1: "October 1, 1949",
            a2: "April 3, 1947",
            a3: "May 7, 1945",
            a4: "December 6, 1950",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "The minigun was designed in 1960 by which manufacturer?",
            a1: "Colt Firearms",
            a2: "General Electric",
            a3: "Sig Sauer",
            a4: "Heckler & Koch",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which country gifted the Statue of Liberty to the United States of America?",
            a1: "Germany",
            a2: "Spain",
            a3: "England",
            a4: "France",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "What year did Skoal, a smokeless Tobacco company, release their first line of Pouches, known as 'Skoal Bandits'?",
            a1: "1984",
            a2: "1983",
            a3: "1934",
            a4: "1822",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_MEDIUM,
            q: "Which modern country is known as 'The Graveyard of Empires'?",
            a1: "China",
            a2: "Afghanistan",
            a3: "Russia",
            a4: "Iraq",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Japan was part of the Allied Powers during World War I?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What was Manfred von Richthofen's nickname?",
            a1: "The Blue Serpent",
            a2: "The Red Baron",
            a3: "The Germany Gunner",
            a4: "The High Flying Ace",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "On which day did ARPANET suffer a 4 hour long network crash?",
            a1: "December 9, 1991",
            a2: "October 27, 1980",
            a3: "November 21, 1969",
            a4: "October 29, 1969",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "In 1720, England was in massive debt, and became in involved in the South Sea Bubble. Who was the main mastermind behind it?",
            a1: "John Blunt",
            a2: "Robert Harley",
            a3: "John Churchill",
            a4: "Daniel Defoe",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "During the Roman Triumvirate of 42 BCE, what region of the Roman Republic was given to Lepidus?",
            a1: "Hispania",
            a2: "Italia",
            a3: "Gallia",
            a4: "Asia",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "When did Lithuania declare independence from the Soviet Union?",
            a1: "December 5th, 1991",
            a2: "April 20th, 1989",
            a3: "December 25th, 1991",
            a4: "March 11th, 1990",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "During the Spanish Civil War (1936), Francisco Franco fought for which political faction?",
            a1: "Popular Front",
            a2: "Republican Spain",
            a3: "Nationalist Spain",
            a4: "Papal State",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "The Battle of Hastings was fought in which year?",
            a1: "1204",
            a2: "1066",
            a3: "1420",
            a4: "911",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What was the aim of the 'Umbrella Revolution' in Hong Kong in 2014?",
            a1: "Genuine universal suffrage",
            a2: "Gaining Independence",
            a3: "Lower taxes",
            a4: "Go back under British Rule",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "During the Winter War, the amount of Soviet Union soliders that died or went missing was five times more than Finland's?",
            a1: "True",
            a2: "False",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "When was the 'Siege of Leningrad' lifted during World War II?",
            a1: "September 1943",
            a2: "January 1944",
            a3: "March 1944",
            a4: "November 1943",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What did the abbreviation 'RMS' stand for in the RMS Titanic in 1912?",
            a1: "Regulated Maelstrom Sensor",
            a2: "Royal Mail Ship",
            a3: "Royal Majesty Service",
            a4: "Regular Maritime Schedule",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "After the 1516 Battle of Marj Dabiq, the Ottoman Empire took control of Jerusalem from which sultanate?",
            a1: "Seljuq",
            a2: "Ayyubid",
            a3: "Mamluk",
            a4: "Ummayyad",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Which of the following African countries was most successful in resisting colonization?",
            a1: "Congo",
            a2: "Namibia",
            a3: "Ethiopia",
            a4: "Côte d’Ivoire",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Bohdan Khmelnytsky was which of the following?",
            a1: "Prince of Wallachia",
            a2: "Grand Prince of Novgorod",
            a3: "General Secretary of the Communist Party of the USSR",
            a4: "Leader of the Ukrainian Cossacks",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "The Second Boer War in 1899 was fought where?",
            a1: "Argentina",
            a2: "South Africa",
            a3: "Bulgaria",
            a4: "Nepal",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Who invented the 'Flying Shuttle' in 1738; one of the key developments in the industrialization of weaving?",
            a1: "John Kay",
            a2: "John Deere",
            a3: "Richard Arkwright",
            a4: "James Hargreaves",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What was Napoleon Bonaparte's name before he changed it?",
            a1: "Napoleona de Buenoparte",
            a2: "Naapolion van Bonijpaart",
            a3: "Napoleone di Buonaparte",
            a4: "Napoleão do Boaparte",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Who was the first prime minister of Canada?",
            a1: "Alexander Mackenzie",
            a2: "Robert Borden",
            a3: "John Abbott",
            a4: "John Macdonald",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What was the code name for the Allied invasion of Southern France on August 15th, 1944?",
            a1: "Operation Market Garden",
            a2: "Operation Dragoon",
            a3: "Operation Torch",
            a4: "Operation Overlord",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "The USS Missouri (BB-63) last served in the Korean War?",
            a1: "False",
            a2: "True",
            
            
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "These two countries held a commonwealth from the 16th to 18th century?",
            a1: "Bangladesh and Bhutan",
            a2: "Poland and Lithuania",
            a3: "North Korea and South Korea",
            a4: "Hutu and Rwanda",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "In addition to his career as an astrologer and 'prophet', Nostradamus published a 1555 treatise that included a section on what?",
            a1: "Digging graves",
            a2: "Teaching parrots to talk",
            a3: "Cheating at card games",
            a4: "Making jams and jellies",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Who rode on horseback to warn the Minutemen that the British were coming during the U.S. Revolutionary War?",
            a1: "Nathan Hale",
            a2: "Thomas Paine",
            a3: "Paul Revere",
            a4: "Henry Longfellow",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Which of these positions did the astronomer and physicist Isaac Newton not hold?",
            a1: "Professor of Mathematics",
            a2: "Member of Parliament",
            a3: "Warden of the Royal Mint",
            a4: "Surveyor to the City of London",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Which country did the Eureka Rebellion, an 1856 battle against colonial rule, take place in?",
            a1: "Brazil",
            a2: "India",
            a3: "Canada",
            a4: "Australia",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Who is the creator of the soft drink, Dr. Pepper?",
            a1: "James Wellington",
            a2: "Boris Heviltik",
            a3: "Charles Alderton",
            a4: "Johnson Hinsin",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "With which Greek philosopher would you associate the phrase, 'I know that I know nothing'?",
            a1: "Pythagoras",
            a2: "Aristotle",
            a3: "Plato",
            a4: "Socrates",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Spain was formed in 1469 with the marriage of Isabella I of Castile and Ferdinand II of what other Iberian kingdom?",
            a1: "León",
            a2: "Navarre",
            a3: "Galicia",
            a4: "Aragon",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What was the name of the spy ring that helped the United States win the Revolutionary War?",
            a1: "Unnamed",
            a2: "New York Spy Ring",
            a3: "Culper Ring",
            a4: "Washington's Spies",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What year was Canada founded in?",
            a1: "1867",
            a2: "1668",
            a3: "1859",
            a4: "1798",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What did the first vending machines in the early 1880's dispense?",
            a1: "Post cards",
            a2: "Sodas",
            a3: "Alcohol",
            a4: "Cigarettes",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Which modern day country is the region that was known as Phrygia in ancient times?",
            a1: "Turkey",
            a2: "Greece",
            a3: "Egypt",
            a4: "Syria",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What year was the United States Declaration of Independence signed?",
            a1: "1777",
            a2: "1775",
            a3: "1776",
            a4: "1774",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Who was the President of the United States during the signing of the Gadsden Purchase?",
            a1: "Franklin Pierce",
            a2: "James Polk",
            a3: "Abraham Lincoln",
            a4: "Andrew Johnson",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Which day did World War I begin?",
            a1: "June 28",
            a2: "July 28",
            a3: "April 28",
            a4: "January 28",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Which English king was married to Eleanor of Aquitaine?",
            a1: "Henry V",
            a2: "Richard I",
            a3: "Henry I",
            a4: "Henry II",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "The man that shot Alexander Hamilton was named Aaron Burr?",
            a1: "False",
            a2: "True",
            
            
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Which U.S. president was said to have been too honest to lie to his father about chopping down a cherry tree?",
            a1: "Thomas Jefferson",
            a2: "James Monroe",
            a3: "George Washington",
            a4: "Abraham Lincoln",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "The coat of arms of the King of Spain contains the arms from the monarchs of Castille, Leon, Aragon and which other former Iberian kingdom?",
            a1: "Catalonia",
            a2: "Navarre",
            a3: "Granada",
            a4: "Galicia",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "What is the name of the Boeing B-29 that dropped the 'Little Boy' atomic bomb on Hiroshima?",
            a1: "The Great Artiste",
            a2: "Enola Gay",
            a3: "Necessary Evil",
            a4: "Full House",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "List the following Iranic empires in chronological order:?",
            a1: "Median, Achaemenid, Parthian, Sassanid",
            a2: "Achaemenid, Median, Parthian, Sassanid",
            a3: "Median, Achaemenid, Sassanid, Parthian",
            a4: "Achaemenid, Median, Sassanid, Parthian",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Which one of these rulers did not belong to the Habsburg dynasty?",
            a1: "Philip V",
            a2: "Francis Joseph",
            a3: "Charles V",
            a4: "Philip II",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "In 1453, which important city fell?",
            a1: "Hamburg",
            a2: "Rome",
            a3: "Constantinople",
            a4: "Athens",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "In which year did the Invasion of Kuwait by Iraq occur?",
            a1: "1992",
            a2: "1986",
            a3: "1988",
            a4: "1990",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "In which year did the Tokyo Subway Sarin Attack occur?",
            a1: "1995",
            a2: "2001",
            a3: "1991",
            a4: "2011",
            a: 1,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "Who was among those killed in the 2010 Smolensk, Russia plane crash tragedy?",
            a1: "Pope John Paul II",
            a2: "The Polish President",
            a3: "Bang-Ding Ow",
            a4: "Albert Putin",
            a: 2,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "The ancient city of Chichén Itzá was built by which civilization?",
            a1: "Incas",
            a2: "Aztecs",
            a3: "Mayans",
            a4: "Toltecs",
            a: 3,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "The son of which pope supposedly held a lecherous fête involving 50 courtesans in the papal palace?",
            a1: "Urban II",
            a2: "Innocent V",
            a3: "Pius III",
            a4: "Alexander VI",
            a: 4,
            seen: false
        }, 
        {
            c: c.CAT_HIST,
            d: c.DIFFICULTY_HARD,
            q: "The ontological argument for the proof of God's existence is first attributed to whom?",
            a1: "René Descartes",
            a2: "Aristotle",
            a3: "Immanuel Kant",
            a4: "Anselm of Canterbury",
            a: 4,
            seen: false
        }
]
    