import { defineStore } from 'pinia'

export const mpStore = defineStore('mp', {
    state: () => {
        return {
            categories: ['A', 'E', 'G', 'H', 'S', 'SP'],
            gameStarted: false,
            scores: {},
            playersTurn: 1,
            totalPlayers: 3,
            correctAnswersToWin: 6,
            seconds: 60,
            multiChoiceFreq: "Sometimes",
            answersRequired: 0,
            initCat: "A"
        }
    },
    actions: {
        nextPlayer(set = false) {
            let player;

            if (this.playersTurn == this.totalPlayers) {
                player = 1
            } else {
                player = this.playersTurn + 1
            }

            if (set) {
                this.playersTurn = player
            }

            return player
        },
        recordScore(cat) {
            if (!(this.playersTurn in this.scores)) {
                this.scores[this.playersTurn] = {}
            }
            if (!(cat in this.scores[this.playersTurn])) {
                this.scores[this.playersTurn][cat] = 0
            }

            this.scores[this.playersTurn][cat] += 1
        },
        hasGotEnoughTotalScoreToWin(player) {
            let score = 0

            if (!(player in this.scores)) {
                return 0
            }

            this.categories.forEach(cat => {
                if (cat in this.scores[player]) {
                    score += this.scores[player][cat]
                }
            });


            return score >= this.correctAnswersToWin
        },
        hasGotEnoughAnswersPerCategoryToWin(player) {
            let won = true

            this.categories.forEach(cat => {
                if (!(player in this.scores)) {
                    this.scores[player] = {}
                }

                if (!(cat in this.scores[player])) {
                    this.scores[player][cat] = 0
                    won = false
                }

                if (this.scores[player][cat] < this.correctAnswersToWin) {
                    won = false
                }
            });

            return won
        }
    },
})
