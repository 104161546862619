
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { App } from "@capacitor/app";
import { defineComponent } from "vue";
import { Storage } from "@capacitor/storage";
import { awardsStore } from "@/stores/awards";
import { Capacitor } from "@capacitor/core";
import { answersStore } from "@/stores/answers";
import { settingsStore } from "@/stores/settings";
import { mpStore } from "@/stores/mp";
import { userStore } from "@/stores/user";
import { sendRecentPointsToServer, apiGet } from "@/mixin.js";
import { alertController } from "@ionic/vue";
import { NativeAudio } from "@capacitor-community/native-audio";
import { db } from "./db.js";

import "../public/assets/styles/out.scss";

// window.screen.orientation.lock("portrait");

export default defineComponent({
  name: "PursuitApp",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  data() {
    return {
      bgplay: false,
      userStore: userStore(),
      showingVersionModal: false,
    };
  },
  mounted() {
    // Ensure the db dependency isn't removed from the min-ed files. So questions are loaded/reloaded
    console.log("DB", db);

    if (Capacitor.getPlatform() === "ios") {
      NativeAudio.preload({
        assetId: "bg",
        assetPath: "bg.wav",
        audioChannelNum: 1,
        isUrl: false,
      });

      NativeAudio.loop({
        assetId: "bg",
      });
    } else {
      var audio = new Audio("../bg.wav");
      audio.loop = true;
    }

    App.addListener("appStateChange", (state) => {
      if (Capacitor.getPlatform() === "ios") {
        if (!state.isActive) {
          NativeAudio.stop({
            assetId: "bg",
          });
        } else if (state.isActive && store.bg) {
          NativeAudio.loop({
            assetId: "bg",
          });
        }
      } else {
        if (!state.isActive) {
          audio.pause();
        } else if (state.isActive && store.bg) {
          audio.play();
        }
      }
    });

    let store = settingsStore();

    // If there is an update available, remind the user every 6 hours
    let updateTimer = setInterval(() => {
      App.getInfo()
        .then((res) => {
          apiGet("version/latest").then((json) => {
            if (json.latest != res.version) {
              this.openVersionAlert();
            }
          });
        })
        .catch(() => {
          clearInterval(updateTimer);
        });
    }, 1000 * 60 * 60 * 6);

    // Stop and start background music based on the current setting
    setInterval(() => {
      if (store.bg && !this.bgplay) {
        this.bgplay = true;
        if (Capacitor.getPlatform() === "ios") {
          NativeAudio.loop({
            assetId: "bg",
          });
        } else {
          audio.play();
        }
      } else if (!store.bg && this.bgplay) {
        this.bgplay = false;
        if (Capacitor.getPlatform() === "ios") {
          NativeAudio.stop({
            assetId: "bg",
          });
        } else {
          audio.pause();
        }
      }
    }, 100);

    // Every 5 mins send recent points to the server
    setInterval(() => {
      sendRecentPointsToServer(this.userStore);
    }, 300000);

    this.loadFromState();
  },
  methods: {
    async openVersionAlert() {
      // const { isActive } = await App.getState();

      // if (!this.showingVersionModal && isActive) {
      if (!this.showingVersionModal) {
        this.showingVersionModal = true;

        const alertBox = await alertController.create({
          header: "There is a new version available. Would you like to update?",
          buttons: [
            {
              text: "No",
              role: "cancel",
              handler: () => {
                this.showingVersionModal = false;
              },
            },
            {
              text: "Yes",
              role: "confirm",
              handler: () => {
                if (Capacitor.getPlatform() === "ios") {
                  window.open(
                    "https://apps.apple.com/gb/app/the-pursuit-of-trivia/id6444321546",
                    "_system"
                  );
                } else if (Capacitor.getPlatform() === "android") {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.jakowicz.trivia",
                    "_system"
                  );
                }
                this.showingVersionModal = false;
              },
            },
          ],
        });

        await alertBox.present();
      }
    },
    loadFromState() {
      Storage.get({ key: "user" }).then((userObj) => {
        if (userObj.value) {
          let user = JSON.parse(userObj.value);
          this.userStore.id = user.id;
          this.userStore.username = user.username;
          this.userStore.gid = user.gid;
          this.userStore.google_email = user.google_email;
        }
      });

      Storage.get({ key: "answers" }).then((an) => {
        let store = answersStore();

        if (an.value) {
          let answers = JSON.parse(an.value);
          store.$patch(answers);
        }
      });

      Storage.get({ key: "awards" }).then((aw) => {
        let store = awardsStore();

        if (aw.value) {
          let awards = JSON.parse(aw.value);
          store.awards = awards;
        }
      });

      Storage.get({ key: "settings" }).then((settings_serialised) => {
        let store = settingsStore();

        let settings =
          settings_serialised.value == null
            ? {
                bg: true,
                sounds: true,
              }
            : JSON.parse(settings_serialised.value);

        store.$patch(settings);
      });

      Storage.get({ key: "mp" }).then((mp_serialised) => {
        let store = mpStore();

        let mp =
          mp_serialised.value == null ? {} : JSON.parse(mp_serialised.value);

        if (Object.keys(mp).length !== 0) {
          store.gameStarted = true;
          store.scores = mp.scores;
          store.initCat = mp.initCat;
          store.playersTurn = mp.playersTurn;
          store.totalPlayers = mp.totalPlayers;
          store.correctAnswersToWin = mp.correctAnswersToWin;
          store.seconds = mp.seconds;
          store.multiChoiceFreq = mp.multiChoiceFreq;
          store.answersRequired = mp.answersRequired;
        }
      });
    },
  },
});
