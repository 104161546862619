import { defineStore } from 'pinia'

export const answersStore = defineStore('answers', {
    state: () => {
        return {
            answered: 0,
            answered_correctly: 0,
            G_answered: 0,
            G_answered_correctly: 0,
            H_answered: 0,
            H_answered_correctly: 0,
            E_answered: 0,
            E_answered_correctly: 0,
            A_answered: 0,
            A_answered_correctly: 0,
            S_answered: 0,
            S_answered_correctly: 0,
            SP_answered: 0,
            SP_answered_correctly: 0,
            answered_correctly_streak: 0,
            total_games_completed: 0,
            points: 0
        }
    },
    actions: {
        update(cat, correct) {
            this.answered += 1
            this[cat + '_answered'] += 1

            if (correct) {
                this.answered_correctly += 1
                this[cat + '_answered_correctly'] += 1
                this['answered_correctly_streak'] += 1
            } else {
                this['answered_correctly_streak'] = 0
            }

        },
        awardPoints(num) {
            this.points += num
        },
        getWrongAnswers() {
            return this.answered - this.answered_correctly
        },
        correctPercent(cat) {
            if (cat == 'ALL') {
                if (this.answered == 0) {
                    return 100
                }

                return Math.round(this.answered_correctly / this.answered * 100)
            }

            if (this[cat + '_answered'] == 0) {
                return 100
            }


            return Math.round(this[cat + '_answered_correctly'] / this[cat + '_answered'] * 100)
        }
    },
})
