import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'home',
    component: () => import ('../views/Home.vue')
  },
  {
    path: '/sp',
    name: 'sp',
    component: () => import ('../views/SP.vue')
  },
  {
    path: '/mp',
    name: 'mp',
    component: () => import ('../views/MP.vue')
  },
  {
    path: '/multi_cat',
    name: 'multi_cat',
    component: () => import ('../views/Category.vue')
  },
  {
    path: '/single_cat',
    name: 'single_cat',
    component: () => import ('../views/Category.vue')
  },
  {
    path: '/question/:mode/:cat',
    name: 'question',
    component: () => import ('../views/Question.vue')
  },
  {
    path: '/mpgame',
    name: 'mpgame',
    component: () => import ('../views/Question.vue')
  },
  {
    path: '/random',
    name: 'random',
    component: () => import ('../views/Question.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import ('../views/Settings.vue')
  },
  {
    path: '/attributions',
    name: 'attributions',
    component: () => import ('../views/Attributions.vue')
  },
  {
    path: '/how',
    name: 'how',
    component: () => import ('../views/HowToPlay.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import ('../views/User.vue')
  },
  {
    path: '/awards',
    name: 'awards',
    component: () => import ('../views/Awards.vue')
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import ('../views/Leaderboard.vue')
  },
  {
    path: '/stats',
    name: 'statistics',
    component: () => import ('../views/Statistics.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
