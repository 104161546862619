import { rewards } from "@/rewards/rewards";
import { Capacitor } from '@capacitor/core';
import { NativeAudio } from '@capacitor-community/native-audio'
import { Storage } from "@capacitor/storage";

if (Capacitor.getPlatform() === 'ios') {
  NativeAudio.preload({
    assetId: "click",
    assetPath: "click.mp3",
    audioChannelNum: 1,
    isUrl: false,
  });

  NativeAudio.preload({
    assetId: "award",
    assetPath: "award.wav",
    audioChannelNum: 2,
    isUrl: false,
    type: 'native'
  });

  NativeAudio.preload({
    assetId: "awardBig",
    assetPath: "award-big.wav",
    audioChannelNum: 2,
    isUrl: false,
    type: 'native'
  });

  NativeAudio.preload({
    assetId: "back",
    assetPath: "back.wav",
    audioChannelNum: 3,
    isUrl: false,
    type: 'native'
  });

  NativeAudio.preload({
    assetId: "countdown",
    assetPath: "countdown.wav",
    audioChannelNum: 3,
    isUrl: false,
    type: 'native'
  });

  NativeAudio.preload({
    assetId: "wrongAnswer",
    assetPath: "wrong-answer.wav",
    audioChannelNum: 4,
    isUrl: false,
    type: 'native'
  });

  NativeAudio.preload({
    assetId: "correctAnswer",
    assetPath: "correct-answer.wav",
    audioChannelNum: 4,
    isUrl: false,
    type: 'native'
  });
}

/**
 * Format a number with commas
 * If the number provided is null, this could be because the value comes from vuex and hasn't been set yet
 * When the num is undefined or null return null instead of formatting
 *
 * @param {Number} num
 * @return {String}
 */
export function numberFormat(num) {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return num
}

export function getAwardDescription(type, index, have = false) {
  let mustAnswer = have ? "have answered" : "must answer"
  let mustComplete = have ? "have completed" : "must complete"

  switch (type) {
    case "points":
      mustAnswer = have ? "have received" : "require"

      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " points"
      );
    case "answered":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " questions"
      );
    case "answered_correctly":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " questions correctly"
      );
    case "A_answered":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Art & Literature questions"
      );
    case "A_answered_correctly":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Art & Literature questions correctly"
      );
    case "E_answered":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Entertainment questions"
      );
    case "E_answered_correctly":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Entertainment questions correctly"
      );
    case "G_answered":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Geography questions"
      );
    case "G_answered_correctly":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Geography questions correctly"
      );
    case "H_answered":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " History questions correctly"
      );
    case "H_answered_correctly":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " History questions correctly"
      );
    case "S_answered":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Science questions correctly"
      );
    case "S_answered_correctly":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Science questions correctly"
      );
    case "SP_answered":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Sport questions correctly"
      );
    case "SP_answered_correctly":
      return (
        "You " + mustAnswer + " " +
        numberFormat(
          rewards.sp[type].values[index]
        ) +
        " Sport questions correctly"
      );
    case "total_games_completed":
      return (
        "You " + mustComplete + " " +
        numberFormat(
          rewards.mp[type].values[index]
        ) +
        " multiplayer games"
      );
  }
}


export function generalClickSound(soundEffectsOn) {
  if (soundEffectsOn) {
    if (Capacitor.getPlatform() === 'ios') {
      NativeAudio.play({
        assetId: 'click',
      });
    } else {
      var audio = new Audio("../assets/sounds/click.wav");
      audio.play();
    }
  }
}

export function awardSound(soundEffectsOn) {
  if (soundEffectsOn) {
    if (Capacitor.getPlatform() === 'ios') {
      NativeAudio.play({
        assetId: 'award',
      });
    } else {
      var audio = new Audio("../assets/sounds/award.wav");
      audio.play();
    }
  }
}

export function awardBigSound(soundEffectsOn) {
  if (soundEffectsOn) {
    if (Capacitor.getPlatform() === 'ios') {
      NativeAudio.play({
        assetId: 'awardBig',
      });
    } else {
      var audio = new Audio("../assets/sounds/award-big.wav");
      audio.play();
    }
  }
}

export function backSound(soundEffectsOn) {
  if (soundEffectsOn) {
    if (Capacitor.getPlatform() === 'ios') {
      NativeAudio.play({
        assetId: 'back',
      });
    } else {
      var audio = new Audio("../assets/sounds/back.wav");
      audio.play();
    }
  }
}

export function countdownSound(soundEffectsOn) {
  if (soundEffectsOn) {
    if (Capacitor.getPlatform() === 'ios') {
      NativeAudio.play({
        assetId: 'countdown',
      })
    } else {
      var audio = new Audio("../assets/sounds/countdown.wav");
      audio.play();
    }
  }
}

export function wrongAnswerSound(soundEffectsOn) {
  if (soundEffectsOn) {
    if (Capacitor.getPlatform() === 'ios') {
      NativeAudio.play({
        assetId: 'wrongAnswer',
      });
    } else {
      var audio = new Audio("../assets/sounds/wrong-answer.wav");
      audio.play();
    }
  }
}

export function correctAnswerSound(soundEffectsOn) {
  if (soundEffectsOn) {
    if (Capacitor.getPlatform() === 'ios') {
      NativeAudio.play({
        assetId: 'correctAnswer',
      });
    } else {
      var audio = new Audio("../assets/sounds/correct-answer.wav");
      audio.play();
    }
  }
}

export function apiGet(endpoint, params = {}) {
  let headers = {
    "Content-Type": "application/json",
  }

  return fetch(process.env.VUE_APP_API + endpoint + '?' + new URLSearchParams(params), {
    headers: headers,
  }).then((response) => {
    return response.json()
  })
}

export function apiPost(endpoint, data) {
  let headers = {
    "Content-Type": "application/json",
  }

  return fetch(process.env.VUE_APP_API + endpoint, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  }).then((response) => {
    return response
  })
}

export function randomUsername() {
  let part1 = [
    "Spring",
    "Test",
    "Add",
    "Lead",
    "Spot",
    "Permit",
    "Tour",
    "Expose",
    "Embrace",
    "Found",
    "Spin",
    "Vanish",
    "Carry",
    "Target",
    "Match",
    "Feel",
    "Push",
    "Scan",
    "Injure",
    "Tear",
    "Absorb",
    "Miss",
    "Differ",
    "Work",
    "Offer",
    "Ease",
    "Fly",
    "Note",
    "Gain",
    "Dance",
    "Close",
    "Widen",
    "Guide",
    "Dry",
  ];
  let part2 = [
    "Region",
    "Month",
    "Oven",
    "Snub",
    "Battery",
    "Host",
    "Boat",
    "Jam",
    "Manager",
    "Video",
    "Tension",
    "Nature",
    "Thought",
    "Charity",
    "Art",
    "Loss",
    "History",
    "Chest",
    "Story",
    "Cheek",
    "Depth",
    "Device",
    "Country",
    "Software",
    "Basis",
    "Analyst",
    "Thing",
    "Monkey",
    "Ivory",
    "Inch",
    "Book",
    "Toy",
    "Flame",
    "Bottle"
  ];
  let part3 = [
    "Random",
    "Joint",
    "Fever",
    "Kettle",
    "Avenue",
    "Lake",
    "Beat",
    "Herb",
    "Grand",
    "Soul",
    "Cage",
    "Paper",
    "Rare",
    "Plant",
    "Powder",
    "Grace",
    "Summer",
    "Site",
    "Fund",
    "Sodium",
    "Rainbow",
    "Cycle",
    "Fool",
    "Wagon",
    "Pony",
    "Gorilla",
    "Fist",
    "Pipe",
    "Rake",
    "Machine",
    "Spell",
    "Pencil",
    "Wheel"
  ];

  return part1[Math.floor(Math.random() * part1.length)] + part2[Math.floor(Math.random() * part2.length)] + part3[Math.floor(Math.random() * part3.length)];
}

export function sendRecentPointsToServer(userStore) {
  Storage.get({ key: "answersShortTerm" }).then((answersSerialised) => {
    let answers = answersSerialised.value == null ? {} : JSON.parse(answersSerialised.value)

    if (Object.keys(answers).length > 0) {
      answers['id'] = userStore.id,

        apiPost("user/add_points", answers).then((response) => {
          response.json().then(() => {
            if (response.status == 200) {
              Storage.set({
                key: "answersShortTerm",
                value: JSON.stringify({}),
              });
            }
          });
        });
    }
  });
}